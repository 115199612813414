import { Status } from "@bookie/components";
import { PaymentState } from "@bookie/glossary";
import React from "react";

const LABELS = {
  "due": "Due",
  "overdue": "Overdue",
  "paid_in_full": "Paid",
  "paid_in_part": "Paid in Part",
  "bad_debt": "Bad Debt"
};

export const TransactionStatusPayment: React.FC<ITransactionStatusPayment> = (props) => {
  return (
    <Status
      label={ props.state && props.label ? LABELS[props.state] : undefined }
      isApproved={ props.state === "paid_in_full" }
      isRejected={ props.state === "bad_debt" || props.state === "overdue" }
      isActive={ props.state === "due" || props.state === "paid_in_part" }
      isInactive={ !props.state } />
  );
} 

export interface ITransactionStatusPayment {
  label?: string
  state?: PaymentState
}