import { TransactionExpanded, TransactionFilterPeriods, uuid } from "@bookie/glossary";
import { filterByPeriod } from "./filter-by-period";
import { filterByEntity } from "./filter-by-entity";

export const filterTransactions = (
  transactions: TransactionExpanded[],
  filterPeriod: TransactionFilterPeriods,
  filterEntityId: uuid
): TransactionExpanded[] => {

  let _t: TransactionExpanded[] = transactions;

  if (filterPeriod) {
    _t = filterByPeriod(transactions, filterPeriod);
  }

  if (filterEntityId) {
    _t = filterByEntity(_t, filterEntityId);
  }

  return _t

}