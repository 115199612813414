import { OfferingTaxCode, TaxCode, TaxSetPeriod, TransactionExpanded, TransactionOffering, uuid } from "@bookie/glossary";
import { getTaxFromCodes } from "./get-tax-from-codes";
import { getTaxCode } from "./get-tax-code";

export interface PeriodSegment {
  periodId: string;
  taxCodeId: string;
  transactions: {
    id: uuid,
    offering: TransactionOffering,
    summary: {
      tax: number,
      total: number,
      subtotal: number
    }
  }[];
  subtotal: number;
  tax: number;
  total: number;
}

export type ISummariseTransactionOffering = (
  grossSale: number,
  quantity: number,
  taxPercent: number,
  taxType: "inclusive" | "additive",
  exchangeRate?: number
) => { subTotal: number, tax: number, total: number }

export const segmentByPeriod = (
  transactions: TransactionExpanded[],
  periods: TaxSetPeriod[],
  codes: TaxCode[],
  summariseTransactionOffering: ISummariseTransactionOffering,
  getTaxCodes: (taxCodes: OfferingTaxCode[]) => TaxCode[] | undefined
): PeriodSegment[] => {

  const results: PeriodSegment[] = [];

  for (const transaction of transactions) {

    if (!transaction.invoiceDate || !transaction.offerings) continue;

    const transactionDate = new Date(transaction.invoiceDate);
    const period = periods.find(
      (p) => transactionDate >= new Date(p.from) && transactionDate <= new Date(p.to)
    );

    if (!period) continue;

    for (const offering of transaction.offerings) {

      if (!offering.taxCodes || offering.taxCodes.length === 0) continue;

      const _codes = getTaxCodes(offering.taxCodes) || [];
      const _combinedTax = getTaxFromCodes(_codes);

      for (const taxCode of offering.taxCodes) {
        let segmentedEntry = results.find(
          (entry) =>
            entry.periodId === period.id && entry.taxCodeId === taxCode.taxCodeId
        );

        if (!segmentedEntry) {
          segmentedEntry = {
            periodId: period.id,
            taxCodeId: taxCode.taxCodeId,
            transactions: [],
            subtotal: 0,
            tax: 0,
            total: 0,
          };
          results.push(segmentedEntry);
        }

        const _taxCode = getTaxCode(segmentedEntry.taxCodeId, codes);
        const _tax = _taxCode && getTaxFromCodes([ _taxCode ]);

        const exchangeRate = transaction.invoiceExchangeRate || transaction.estimateExchangeRate;

        const taxType = (
          _codes.length > 1 && _combinedTax === 0
            ? "additive"
            : offering.taxType || "additive"
        );

        const _summary = summariseTransactionOffering(
          offering.grossSale || 0,
          offering.quantity || 1,
          Math.abs(_tax || 0),
          taxType,
          exchangeRate
        );

        segmentedEntry.transactions.push({
          id: transaction.id,
          offering: offering,
          summary: {
            tax: _summary.tax,
            total: _summary.total,
            subtotal: _summary.subTotal
          }
        });

        segmentedEntry.subtotal += _summary.subTotal;
        segmentedEntry.tax += _summary.tax;
        segmentedEntry.total += _summary.total;
      
      }
    }
  }

  return results;
};
