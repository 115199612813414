import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const Stat: React.FC<IStat> = (props) => {

  if (props.dontRender) {
    return null;
  }

  return (
    <div 
      className={ cx(
        css.stat,
        "y",
        props.className,
        css[`stat_${ props.size || "medium" }`],
        { "align-x-start": props.align === "left" || !props.align },
        { "align-x-end": props.align === "right" }
      ) }>

      {
        props.label && 
          <span
            className={ css.stat_label }>
            { props.label }
          </span>
      }

      <div
        className={ css.stat_value }>
        { props.value }
      </div>

    </div>
  );
}

export interface IStat {
  label?: string
  value: React.ReactNode
  className?: string
  size?: "tiny" | "small" | "medium"
  align?: "left" | "right"
  dontRender?: boolean
}