import { IconDomestic, IconInternational, IconSuccessTick } from "@bookie/components";
import { TaxCode as TaxCodeModel } from "@bookie/glossary";
import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const TaxCode: React.FC<ITaxCode> = (props) => {

  const isOutOfScope = props.code.taxFunction === "out-of-scope";

  return (
    <div
      className={ cx(
        "apply-bg apply-fg border radius space-inlinez-frame space-lines-children x align-x-between",
        { "surface": !props.isSelected },
        { "surface-primary": props.isSelected },
        { "hvr-pulse" : props.onSelect },
        { [css.taxCode_isClickable]: props.onSelect }
      ) }
      onClick={ () => props.onSelect?.(props.isSelected) }>

      <div 
        className={ "y space-inline-children" }>
        
        <h3>{ props.code.id }</h3>
        
        <div 
          className={ "x space-inline-children align-y-center" }>
          
          {
            props.code.scope === "domestic" && !props.isSelected && 
              <IconDomestic size={ 20 } />
          }

          {
            props.code.scope === "international" && !props.isSelected && 
              <IconInternational size={ 20 } />
          }

          {
            props.isSelected && 
              <IconSuccessTick weight={ "fill" } />
          }

          <p><b>{ props.code.name }</b></p>
        
        </div>
        
        <p 
          style={{ fontSize: "11px" }}>
          <i>{ props.code.officialName }</i>
        </p>

        <p
          className={ "space-inline-frame-t" }>
          { props.code.description }
        </p>

      </div>

      {
        !isOutOfScope && 
          <div
            style={{ minWidth: "60px" }} 
            className={ "x align-x-end" }>
            <h2>{`${ props.code.taxFunction === "input" ? "-" : "+" } ${ props.code.tax }% `}</h2>
          </div>
      }

    </div>
  );
}

export interface ITaxCode {
  code: TaxCodeModel
  isSelected?: boolean
  onSelect?: (isSelected?: boolean) => void 
}