import React from "react";
import css from "./index.module.css";
import cx from "classnames";
import { Tooltip } from "../tooltip";

export const Tabs: React.FC<ITabs> = (props) => {
  return (
    <div
      className={ cx(
        css.tabs,
        "surface apply-bg border x align-y-center"
      ) }>
      
      {
        props.options.map(option => {

          if (typeof option.condition === "boolean" && !option.condition) {
            return null;
          }

          const isSelected = props.selectedTab === option.id;
          const isDisabled = typeof option.canNavigate === "boolean" && !option.canNavigate;

          return (
            <Tooltip
              base={
                <div 
                  id={ option.id }
                  className={ cx(
                    css.tabs_option,
                    "x align-y-center",
                    { [`${ css.tabs_option_selected } surface-primary apply-bg apply-fg`]: isSelected },
                    { [css.tabs_option_disabled]: isDisabled }
                  ) }
                  onClick={ () => {
                    !isDisabled && props.onSelect?.(option);
                  } }>
                  <span>{ option.label }</span>
                </div>
              }
              message={ 
                isDisabled 
                  ? option.cantNavigateMessage
                  : undefined 
              } />
          );
        })
      }

    </div>
  );
}

export interface ITabs {
  selectedTab?: string
  options: ITabOption[]
  onSelect?: (o: ITabOption) => void
}

export interface ITabOption {
  id: string 
  label: string
  condition?: boolean
  canNavigate?: boolean
  cantNavigateMessage?: string
}