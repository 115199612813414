import { OfferingOrphan, ObjectFieldValidation, ObjectValidation } from "@bookie/glossary";

export const validateOffering = 
  (
    offering: OfferingOrphan
  ): ObjectValidation => {
  
  let errors: ObjectFieldValidation = {};
  
  if (
    typeof offering.name === "undefined"
  ) {
    errors = {
      ...errors,
      name: "What is the name of this product or service?"
    };
  }

  if (
    typeof offering.currencyCode === "undefined"
  ) {
    errors = {
      ...errors,
      countryCode: "In what currency do you want this product or service sold?"
    };
  }

  if (
    typeof offering.type === "undefined"
  ) {
    errors = {
      ...errors,
      type: "What type of product or service is this?"
    };
  }

  if (
    typeof offering.taxCodes === "undefined"
  ) {
    errors = {
      ...errors,
      tax: "What is the tax rate for this product or service?"
    };
  }

  return {
    isValid: Object.keys(errors).length > 0 ? false : true,
    errors
  };

}