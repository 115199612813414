import React from "react";
import { IInputSelect, InputSelect } from "@bookie/components";
import { InterfaceAsUndefined } from "@bookie/glossary";

const roles = [
  {
    id: "customer",
    label: "Customer"
  },
  {
    id: "business",
    label: "Business"
  }
];

export const SelectEntityRole: React.FC<InterfaceAsUndefined<IInputSelect>> = (props) => {
  return (
    <InputSelect 
      { ...props }
      label={ "Role" }
      formatLabel={ e => e.label }
      matchKey={ "id" }
      options={ roles }
      onSelect={ props.onSelect || (() => {}) }
      value={ roles.find(x => x.id === props.value) } />
  );
}