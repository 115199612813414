import { Button, IconRightAccordian } from "@bookie/components";
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import React from "react";

export const TransactionEditorActionBar: React.FC<ITransactionEditorActionBar> = (props) => {
  return (
    <div
      style={{ height: "55px" }}
      className={ "x surface border-b space-lines-frame-lr space-inlinez-frame-tb align-x-between align-y-center" }>
      
      <div 
        className={ "x space-lines-children align-y-center" }>

        <Button
          size={ "slim" }
          className={ "hvr-pulse hvr-brighten" }
          label={ "Save" }
          onClick={ async () => {
            const result = await props.editor.commit();
            result && result?.id && props.onFinish?.();
          } }
          optionsIconStyle={ "chevron" } />
          {/* options={(
            <TransactionRepeat
              onRepeat={ (repeatConfig: TransactionRepeatConfig) => {
                (async () => {
                  const result = await editor.commit(repeatConfig);
                  result && props.onFinish?.();
                })();
              } } />
          )} /> */}
        <Button 
          size={ "slim" }
          variant={ "inline" }
          className={ "hvr-icon-forward" }
          icon={ <IconRightAccordian /> }
          onClick={ props.onFinish } />

      </div>
      
      {
        props.editor.data.id &&
          <Button
            size={ "slim" }
            variant={ "dangerous" }
            surface={ "surface-negative" }
            label={ "Delete" }
            isDangerous={ true }
            onClick={ async () => {
              const result = await props.editor.delete();
              result && props.onFinish?.();
            } } /> 
      }

    </div>
  );
}

export interface ITransactionEditorActionBar {
  editor: ITransactionEditorAPI
  onFinish?: () => void
}