import { Button, IconLeftCircle, IconRightCircle } from "@bookie/components";
import React from "react";
import { useOrgEditor } from "../../use/use-org-editor";
import { useLocate } from "@bookie/oracle-location";
import { TaxSuiteDataCollection } from "@bookie/glossary";
import { OnboardingWelcome } from "./welcome";
import { OnboardingRegistrationType } from "./registration-type";
import { OnboardingLocation } from "./location";
import { OnboardingName } from "./name";
import { OnboardingCollection } from "./collection";
import { OnboardingDescription } from "./description";
import css from "./index.module.css";
import cx from "classnames";
import { OnboardingSuccess } from "./success";

export const Onboarding: React.FC<IOnboarding> = (props) => {

  const editor = useOrgEditor();

  const [ hasBeenIntroduced, setHasBeenIntroduced ] = React.useState<boolean>(false);
  const [ stage, setStage ] = React.useState<"registration" | "location" | "name" | "description" | "collection" | "success">();
  const [ isOnboarded, setIsOnboarded ] = React.useState<boolean>(false);

  const [ collectionRequests, setCollectionRequests ] = React.useState<TaxSuiteDataCollection[]>();

  useLocate(
    hasBeenIntroduced,
    (l) => {

      editor.related.entityEditor.edit.countryCode(l.countryCodeIso3);

      const collection = props.getCollectionRequest?.(l.countryCodeIso3);
      setCollectionRequests(collection);

    }
  );

  const areThereCollectionRequests = collectionRequests && collectionRequests.length > 0;

  const isRegistrationReady = editor.related.entityEditor.data.registrationType;
  const isNameReady = editor.related.entityEditor.data.name;
  const isDescriptionReady = editor.related.entityEditor.data.businessDescription;
  const isLocationReady = editor.related.entityEditor.data.countryCode;
  const isCollectionReady = collectionRequests?.every(c => c.validate ? c.validate(editor.related.entityEditor.data.collectionOnboarding) : true);

  const isStageReady = (
    !stage ||
    (stage === "registration" && isRegistrationReady) ||
    (stage === "name" && isNameReady) ||
    (stage === "description" && isDescriptionReady) ||
    (stage === "location" && isLocationReady) ||
    (stage === "collection" && isCollectionReady)
  );

  return (
    <div
      className={ "y fill-x fill-y surface-primary apply-bg apply-fg align-x-center align-y-center" }>

      {
        isOnboarded && 
          <OnboardingSuccess />
      }

      {
        !isOnboarded && !editor.state.isCreatingOrg &&
          <div 
            className={ "x" }>
            
            <div 
              style={{ width: "180px", cursor: "pointer" }}
              className={ "x align-y-center align-x-center" }>
              <div
                onClick={ async () => {
                  switch (stage) {
                    case "name": setStage("registration"); break;
                    case "description": setStage("name"); break;
                    case "location": setStage("description"); break;
                    case "collection": setStage("location"); break;
                  }
                } }>
                {
                  stage !== "registration" && stage && 
                    <IconLeftCircle size={ 32 } />
                }
              </div>
            </div>

            <div 
              className={ "fill-x" }>
              
              {
                !hasBeenIntroduced &&
                  <OnboardingWelcome /> 
              }
              
              {
                hasBeenIntroduced && 
                  <>
                    {
                      stage === "registration" && 
                        <OnboardingRegistrationType
                          editor={ editor } />
                    }
      
                    {
                      stage === "name" && 
                        <OnboardingName
                          editor={ editor } />
                    }
      
                    {
                      stage === "description" && 
                        <OnboardingDescription
                          editor={ editor } />
                    }
      
                    {
                      stage === "location" && 
                        <OnboardingLocation
                          editor={ editor } />
                    }
      
                    {
                      stage === "collection" && areThereCollectionRequests && 
                        <OnboardingCollection
                          editor={ editor }
                          collectionRequests={ collectionRequests } />
                    }
                  </>
              }

            </div>

            <div 
              style={{ width: "180px", cursor: "pointer" }}
              className={ "x align-y-center align-x-center" }>
                <div
                  className={ cx(
                    isStageReady ? "hvr-pulse hvr" : css.navigator_isDisabled 
                  ) }
                  onClick={ async () => {
                    switch (stage) {
                      case "registration": setStage("name"); break;
                      case "name": setStage("description"); break;
                      case "description": setStage("location"); break;
                      case "location": setStage("collection"); break;
                      case "collection": setStage("registration"); break;
                    }  
                  } }>

                  {
                    !stage && 
                      <Button 
                        surface={ css.navigator_button }
                        label={ "Get Started" }
                        onClick={ () => {
                          setHasBeenIntroduced(true);
                          setStage("registration");
                        } } />
                  }

                  {
                    stage === "collection" && 
                      <Button
                        surface={ css.navigator_button }
                        label={ "Finish" }
                        onClick={ async () => {
                          const result = await editor.commit();
                          if (result) {
                            setIsOnboarded(true);
                            setTimeout(
                              () => window.location.href = `${ window.location.origin }/bookkeeping/income-&-expenses`,
                              3000 
                            );
                          }
                        } } />
                  }

                  {
                    stage !== "collection" && stage &&
                      <IconRightCircle 
                        size={ 32 } />
                  }
                  
                </div>
            </div>
          
          </div>
      }

    </div>
  );

}

export interface IOnboarding {
  getCollectionRequest?: GetCollectionRequest
}

export type GetCollectionRequest = (countryCode?: string) => TaxSuiteDataCollection[] | undefined