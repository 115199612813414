import { EntityRole, OfferingTaxCode, TransactionOffering, TransactionOfferingOrphan, TransactionType } from "@bookie/glossary";
import { useIdentity } from "@bookie/module-identity";
import { useSupabase } from "@bookie/supabase";
import React from "react";
import { useDebounce } from "@bookie/utils";
import { useTaxSuites } from "../use-tax-suites";

export const useInferTaxCodes = (
  targetCountry?: string,
  targetCurrencyCode?: string,
  targetRole?: EntityRole,
  transactionType?: TransactionType,
  transactionOfferings?: {
    new: TransactionOfferingOrphan[],
    existing: TransactionOffering[]
  },
  onInferredNew?: (i: number, codes: OfferingTaxCode[]) => void,
  onInferredExisting?: (i: number, codes: OfferingTaxCode[]) => void // TODO do we actually need this?
): IUseInferTaxCodes => {

  const { supabase, fns } = useSupabase();
  const { ownership } = useIdentity();
  const { suite } = useTaxSuites();

  const [isInferring, setIsInferring] = React.useState<boolean>(false);
  const [hasAttemptedToInfer, setHasAttemptedToInfer] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const prevMeta = React.useRef<any>();
  const prevNewOfferings = React.useRef<TransactionOfferingOrphan[]>();

  const baseCountry = ownership?.current?.entity?.countryCode;
  const businessDescription = ownership?.current?.entity?.businessDescription;
  const registrationType = ownership?.current?.entity?.registrationType;
  const collectionData = ownership?.current?.entity?.collectionOnboarding;

  const collection = suite?.dataCollection.onboarding?.map(c => ({ ...c, value: collectionData?.[c.propertyName] }));

  const meta = React.useMemo(
    () => ({ targetCountry, targetRole, transactionType }),
    [targetCountry, targetRole, transactionType]
  );

  const inferTaxCodes = async (_inferees: { index: number, offering: TransactionOfferingOrphan }[]) => {
    for (const { index, offering } of _inferees) {
      try {

        setIsInferring(true);

        if (!hasAttemptedToInfer) {
          setHasAttemptedToInfer(true);
        }

        const taxCodes = await supabase.functions.invoke(
          fns.inferTaxCodes,
          {
            body: {
              baseCountry,
              targetCountry,
              targetRole,
              businessDescription,
              transactionType,
              transactionOffering: {
                name: offering.name,
                type: offering.type,
                currencyCode: targetCurrencyCode,
                grossSale: offering.grossSale,
                quantity: offering.quantity,
                tax: 0
              },
              registrationType,
              collection
            }
          }
        );

        if (onInferredNew && !offering.id) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onInferredNew(index, taxCodes.data.related.map((tc: any) => ({ ...tc, by: "ai" })) || []);
        }

        if (onInferredExisting && offering.id) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onInferredExisting(index, taxCodes.data.related.map((tc: any) => ({ ...tc, by: "ai" })) || [])
        }

        setIsInferring(false);
      } catch (e) {
        setIsInferring(false);
      }
    }
  };

  const debouncedInferTaxCodes = useDebounce(inferTaxCodes, 3000);

  React.useEffect(() => {
    if (prevMeta.current || prevNewOfferings.current) {
      const _prevNewOfferings = prevNewOfferings.current;
      const _prevMeta = prevMeta.current;

      let _inferees: { index: number, offering: TransactionOfferingOrphan }[] = [];

      if (_prevMeta && JSON.stringify(_prevMeta) !== JSON.stringify(meta)) {
        _inferees = (transactionOfferings?.new || []).map((offering, index) => ({ index, offering }));
      } else {
        const _changedOfferings = transactionOfferings?.new.map((c, i) => {
          const previous = _prevNewOfferings?.[i];
          if (
            previous &&
            (
              JSON.stringify([previous.name, previous.type, previous.description]) !==
              JSON.stringify([c.name, c.type, c.description])
            )
          ) {
            return { index: i, offering: c };
          }
          return null;
        }).filter((c) => c !== null) as { index: number, offering: TransactionOfferingOrphan }[];

        _inferees = _changedOfferings || [];
      }

      if (_inferees.length > 0) {
        debouncedInferTaxCodes(_inferees);
      }
    }

    prevNewOfferings.current = transactionOfferings?.new;
    prevMeta.current = meta;

  }, [
    transactionOfferings?.new,
    meta,
    debouncedInferTaxCodes
  ]);

  return {
    isInferring,
    hasAttemptedToInfer,
    inferTaxCodesDebounced: debouncedInferTaxCodes,
    inferTaxCodes: inferTaxCodes
  };
};

export interface IUseInferTaxCodes {
  isInferring: boolean;
  hasAttemptedToInfer: boolean;
  inferTaxCodesDebounced: InferTaxCodes;
  inferTaxCodes: InferTaxCodes;
}

export type InferTaxCodes = (inferees: { index: number, offering: TransactionOfferingOrphan }[]) => Promise<void>
