import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SupabaseCtxProvider } from "@bookie/supabase";
import { Authenticator } from "@bookie/module-auth";
import { TimezoneCtxProvider } from "@hny_/timezone";
import { CurrencyCtxProvider } from "@hny_/currency";
// import "@hny_/rct/style.css";
import "./reset.css";
import "../../../honey.nest.css";
import "./styles.css";

import { App } from "./app";
import { IdentityCtxProvider } from "@bookie/module-identity";
import { BookkeepingCtxProvider } from "@bookie/module-bookkeeping";
import { CoreCtxProvider } from "@bookie/module-core";
import { IconError, IconSuccess, NotificationCtxProvider, WorkbenchProvider } from "@bookie/components";

import * as Sentry from "@sentry/react";
import { getMyTaxSuite, TaxSuitesProvider } from "@bookie/module-tax-suites";

Sentry.init({
  dsn: "https://2ef3bbeef81c0c85ebc16f16648692b6@o4508144425762816.ingest.de.sentry.io/4508144428253264",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <BrowserRouter>
      <SupabaseCtxProvider
        api={import.meta.env.VITE_SB_API_URL}
        anonKey={import.meta.env.VITE_SB_ANON_KEY}
      >
        <TimezoneCtxProvider>
          <CurrencyCtxProvider>
            <WorkbenchProvider> 
              <NotificationCtxProvider
                variants={[
                  { type: "error", class: "surface-negative", icon: <IconError /> },
                  { type: "success", class: "surface-positive", icon: <IconSuccess /> }
                ]}>
                <Authenticator
                  authenticatedApp={
                    <IdentityCtxProvider>
                      <TaxSuitesProvider>
                        <CoreCtxProvider
                          // We are piping this through here 
                          // to avoid a circular dependency here.
                          getCollectionRequestForOnboarding={ (countryCode?: string) => {
                            if (countryCode) {
                              const suite = getMyTaxSuite(countryCode);
                              return suite?.dataCollection.onboarding;
                            }
                          } }>
                          <BookkeepingCtxProvider>
                            <App />   
                          </BookkeepingCtxProvider>
                        </CoreCtxProvider>
                      </TaxSuitesProvider>
                    </IdentityCtxProvider>
                } />
              </NotificationCtxProvider>
            </WorkbenchProvider>
          </CurrencyCtxProvider>
        </TimezoneCtxProvider>
      </SupabaseCtxProvider>
    </BrowserRouter>
  </StrictMode>
);
