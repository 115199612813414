import React from "react";
import { OnboardingCard } from "../_onboarding-card";
import { IllustrationWelcomeBackground } from "@bookie/images";
import css from "./index.module.css";
import cx from "classnames";
import { IconSuccessTick } from "@bookie/components";

export const OnboardingSuccess: React.FC<IOnboardingSuccess> = (props) => {
  return (
    <OnboardingCard
      style={{ width: "620px", height: "140px" }}
      className={ "y align-x-center" }>
      
      <div 
        style={{
          backgroundImage: `url(${ IllustrationWelcomeBackground })`
        }}
        className={ cx(
          css.background,
          "surface-alt fill-x align-x-center x border-b space-blocks-frame-tb"
        ) }>
        <div 
          className={ "apply-fg x align-x-center align-y-center" }
          style={{
            width: "120px",
            height: "140px",
            borderRadius: "50%",
            position: "relative"
          }}>
            <IconSuccessTick  
              color={ "#fff" }
              weight={ "fill" }
              size={ 32 } />
        </div>
      </div>
          
    </OnboardingCard>
  );
}

export interface IOnboardingSuccess {
}