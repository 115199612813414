import { TaxCode } from "@bookie/glossary";

export const getTaxFromCodes = (taxCodes: TaxCode[]): number => {

  // First we'll check for any out of scope 
  // tax codes. Technically if it's out of scope 
  // you'd only have one, however since we're
  // dealing with an array of potential codes
  // it's safer to look for wild cards first. 

  const isOutOfScope = taxCodes.find(c => c.taxFunction === "out-of-scope");

  if (isOutOfScope) {
    return -1;
  }

  return taxCodes.reduce((totalTax, taxCode) => {

    // An ouput tax is tax a business would 
    // collect from a sale, owed to their government.

    if (taxCode.taxFunction === "output") {
      return totalTax + Number(taxCode.tax);
    } 
    
    // An input is tax a business would
    // pay on a purchase, they can claim back from their government.
    
    else if (taxCode.taxFunction === "input") {
      return totalTax - Number(taxCode.tax);
    }

    return totalTax;
  
  }, 0);
};