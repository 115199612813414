import { 
  AddressBook,
  AirplaneTakeoff,
  ArrowCircleDown,
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowLeft,
  ArrowRight,
  ArrowSquareIn,
  ArrowSquareOut,
  At,
  Barcode,
  CalendarDots,
  CaretCircleDoubleLeft,
  CaretCircleDoubleRight,
  CaretDoubleDown,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretDoubleUp,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  ChartPie,
  CheckCircle,
  CheckFat,
  ClockClockwise,
  Coins,
  CoinVertical,
  Cube,
  DotsThreeCircle,
  DownloadSimple,
  Eye,
  // Eraser,
  FilePdf,
  Footprints,
  Funnel, 
  // Gear, 
  GlobeHemisphereWest, 
  HandTap, 
  HandWaving, 
  Hash, 
  House, 
  IconProps,
  IdentificationCard,
  Info,
  Invoice,
  // Key,
  Lifebuoy,
  Lightning,
  // ListDashes,
  Lock,
  LockLaminated,
  MathOperations,
  // MoneyWavy,
  // Money,
  Notepad,
  Package,
  PaperPlaneTilt,
  Pen,
  Percent,
  Person,
  Plus,
  PlusCircle,
  PlusMinus,
  Repeat,
  Robot,
  Scales,
  Scan,
  Shapes,
  Sliders,
  Stack,
  StackPlus,
  Storefront,
  Swap,
  Swatches,
  Tag,
  TextAlignLeft,
  // TrashSimple,
  UploadSimple,
  // User,
  UserCircle,
  UsersThree,
  // Warning,
  WarningCircle,
  X,
  XCircle
} from "@phosphor-icons/react";
import { IconBase } from "./_icon";

export const IconEstimate = (props: IconProps) => <IconBase { ...props }><Scales /></IconBase>
export const IconInvoice = (props: IconProps) => <IconBase { ...props }><Invoice /></IconBase>
export const IconPayment = (props: IconProps) => <IconBase { ...props }><Coins /></IconBase>

export const IconFilter = (props: IconProps) => <IconBase { ...props }><Funnel /></IconBase>
export const IconCurrency = (props: IconProps) => <IconBase { ...props }><Coins /></IconBase>
export const IconClient = (props: IconProps) => <IconBase { ...props }><UserCircle /></IconBase>
export const IconDescription = (props: IconProps) => <IconBase { ...props }><TextAlignLeft /></IconBase>
export const IconPeriod = (props: IconProps) => <IconBase { ...props }><CalendarDots /></IconBase>
export const IconDates = (props: IconProps) => <IconBase { ...props }><CalendarDots /></IconBase>
export const IconNotes = (props: IconProps) => <IconBase { ...props }><Notepad /></IconBase>
export const IconOfferings = (props: IconProps) => <IconBase { ...props }><Stack /></IconBase> 
export const IconSegment = (props: IconProps) => <IconBase { ...props } ><ChartPie /></IconBase>
export const IconApprove = (props: IconProps) => <IconBase { ...props }><CheckCircle /></IconBase>
export const IconReject = (props: IconProps) => <IconBase { ...props }><XCircle /></IconBase>
export const IconOfferingsAdd = (props: IconProps) => <IconBase { ...props }><PlusCircle /></IconBase>
export const IconNotesAdd = (props: IconProps) => <IconBase { ...props }><PlusCircle /></IconBase>
export const IconPayments = (props: IconProps) => <IconBase { ...props }><CoinVertical /></IconBase>
export const IconGross = (props: IconProps) => <IconBase { ...props }><CoinVertical /></IconBase>
export const IconExchange = (props: IconProps) => <IconBase { ...props }><Swap /></IconBase>
export const IconCountry = (props: IconProps) => <IconBase { ...props }><GlobeHemisphereWest /></IconBase>
export const IconAccounting = (props: IconProps) => <IconBase { ...props }><MathOperations /></IconBase>

export const IconDown = (props: IconProps) => <IconBase { ...props }><CaretDown /></IconBase>
export const IconDownCircled = (props: IconProps) => <IconBase { ...props }><ArrowCircleDown /></IconBase>
export const IconUp = (props: IconProps) => <IconBase { ...props }><CaretUp /></IconBase>
export const IconRight = (props: IconProps) => <IconBase { ...props }><CaretRight /></IconBase>
export const IconLeft = (props: IconProps) => <IconBase { ...props }><CaretLeft /></IconBase>
export const IconLeftArrow = (props: IconProps) => <IconBase { ...props }><ArrowLeft /></IconBase>
export const IconAdd = (props: IconProps) => <IconBase { ...props }><PlusCircle /></IconBase>
export const IconPlus = (props: IconProps) => <IconBase { ...props }><Plus /></IconBase>
export const IconAddData = (props: IconProps) => <IconBase { ...props }><StackPlus /></IconBase>
export const IconWarning = (props: IconProps) => <IconBase { ...props }><WarningCircle /></IconBase>
export const IconInfo = (props: IconProps) => <IconBase { ...props }><Info /></IconBase>
export const IconMore = (props: IconProps) => <IconBase { ...props }><DotsThreeCircle /></IconBase>
export const IconDelete = (props: IconProps) => <IconBase { ...props }><X /></IconBase>
export const IconError = (props: IconProps) => <IconBase { ...props }><XCircle /></IconBase>
export const IconValidation = (props: IconProps) => <IconBase { ...props }><WarningCircle /></IconBase>
export const IconSuccess = (props: IconProps) => <IconBase { ...props }><CheckCircle /></IconBase>
export const IconSuccessTick = (props: IconProps) => <IconBase { ...props }><CheckFat /></IconBase>
export const IconDownAccordian = (props: IconProps) => <IconBase { ...props }><CaretDoubleDown /></IconBase>
export const IconUpAccordian = (props: IconProps) => <IconBase { ...props }><CaretDoubleUp /></IconBase>
export const IconLeftAccordian = (props: IconProps) => <IconBase { ...props }><CaretDoubleLeft /></IconBase>
export const IconRightAccordian = (props: IconProps) => <IconBase { ...props }><CaretDoubleRight /></IconBase>
export const IconLock = (props: IconProps) => <IconBase { ...props }><Lock /></IconBase>
export const IconClose = (props: IconProps) => <IconBase { ...props }><X /></IconBase>
export const IconEdit = (props: IconProps) => <IconBase { ...props }><Pen /></IconBase>

export const IconRightCircle = (props: IconProps) => <IconBase { ...props }><ArrowCircleRight /></IconBase>
export const IconLeftCircle = (props: IconProps) => <IconBase { ...props }><ArrowCircleLeft /></IconBase>

export const IconUpload = (props: IconProps) => <IconBase { ...props }><UploadSimple /></IconBase>
export const IconRepeat = (props: IconProps) => <IconBase { ...props }><Repeat /></IconBase>
export const IconSend = (props: IconProps) => <IconBase { ...props }><PaperPlaneTilt /></IconBase>
export const IconHelp = (props: IconProps) => <IconBase { ...props }><Lifebuoy /></IconBase>

export const IconDownload = (props: IconProps) => <IconBase { ...props }><DownloadSimple /></IconBase>
export const IconDocument = (props: IconProps) => <IconBase { ...props }><FilePdf /></IconBase>
export const IconPreview = (props: IconProps) => <IconBase { ...props }><Eye /></IconBase>

export const IconInclude = (props: IconProps) => <IconBase { ...props }><ArrowSquareIn /></IconBase>
export const IconExclude = (props: IconProps) => <IconBase { ...props }><ArrowSquareOut /></IconBase>

export const IconRightPager = (props: IconProps) => <IconBase { ...props }><CaretCircleDoubleRight /></IconBase>
export const IconLightPager = (props: IconProps) => <IconBase { ...props }><CaretCircleDoubleLeft /></IconBase>

export const IconFooter = (props: IconProps) => <IconBase { ...props }><Footprints /></IconBase>
export const IconShipping = (props: IconProps) => <IconBase { ...props }><Package /></IconBase>
export const IconCustomer = (props: IconProps) => <IconBase { ...props }><Person /></IconBase>

export const IconBranding = (props: IconProps) => <IconBase { ...props }><Tag /></IconBase>
export const IconAddress = (props: IconProps) => <IconBase { ...props }><AddressBook /></IconBase>
export const IconContact = (props: IconProps) => <IconBase { ...props }><At /></IconBase>

export const IconManual = (props: IconProps) => <IconBase { ...props }><HandTap /></IconBase>
export const IconAuto = (props: IconProps) => <IconBase { ...props }><Robot /></IconBase>
export const IconAI = (props: IconProps) => <IconBase { ...props }><Lightning /></IconBase>

export const IconEssential = (props: IconProps) => <IconBase { ...props }><UserCircle /></IconBase>
export const IconLogo = (props: IconProps) => <IconBase { ...props }><Scan /></IconBase>
export const IconTakeMe = (props: IconProps) => <IconBase { ...props }><AirplaneTakeoff /></IconBase>

export const IconWave = (props: IconProps) => <IconBase { ...props }><HandWaving /></IconBase>

export const IconGlobal = (props: IconProps) => <IconBase { ...props }><GlobeHemisphereWest /></IconBase>
export const IconManageClients = (props: IconProps) => <IconBase { ...props }><UsersThree /></IconBase>
export const IconManageMerchants = (props: IconProps) => <IconBase { ...props }><Storefront /></IconBase>
export const IconManageInventory = (props: IconProps) => <IconBase { ...props }><Stack /></IconBase>
export const IconCustomise = (props: IconProps) => <IconBase { ...props }><Swatches /></IconBase>

export const IconEmail = (props: IconProps) => <IconBase { ...props }><At /></IconBase>
export const IconWaitlist = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><ArrowRight /></IconBase>
export const IconWaitlistSuccess = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><CheckFat /></IconBase>

export const IconSKU = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><Barcode /></IconBase>
export const IconOfferingType = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><Shapes /></IconBase>
export const IconOfferingCost = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><Coins /></IconBase>
export const IconOfferingPrice = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><Coins /></IconBase>
export const IconName = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><IdentificationCard /></IconBase>
export const IconQuantity = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><Hash /></IconBase>
export const IconTax = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><Percent /></IconBase>
export const IconTaxType = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><PlusMinus /></IconBase>
export const IconPrivacy = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><LockLaminated /></IconBase>
export const IconFiltersSegmentation = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><Sliders /></IconBase>


export const IconService = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><ClockClockwise /></IconBase>
export const IconProduct = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><Cube /></IconBase>

export const IconDomestic = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><House /></IconBase>
export const IconInternational = (props: IconProps) => <IconBase { ...props } weight={ "bold" } ><GlobeHemisphereWest /></IconBase>

