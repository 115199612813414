import { ITransactionEditorAPI } from "../../../../use/use-transaction-editor";
import React from "react";
import { Button, IconPayment, IconSuccessTick  } from "@bookie/components";

export const StageControlsPayment: React.FC<IStageControlsPayment> = (props) => {
  return (
    <div
      className={ "y align-x-end space-inline-children" }>
      
      {
        props.editor?.state.paymentState === "paid_in_full" && 
          <IconSuccessTick
            weight={ "fill" } />
      }
      
      {
        props.editor?.state.paymentState !== "paid_in_full" &&
          <Button 
            label={ "Log a payment" }
            size={ "slim" }
            icon={ <IconPayment /> }
            onClick={ () => props.editor?.related.transactionPaymentsEditor.new.add() } />
      }

    </div>
  );
}

export interface IStageControlsPayment {
  editor?: ITransactionEditorAPI 
}