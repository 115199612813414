import React from "react";
import { TransactionExpandedSegment, uuid } from "@bookie/glossary";
import { TransactionCard } from "../transaction-card";
import { useBookkeeping } from "../../use-bookkeeping";
import { getEntity, useCore } from "@bookie/module-core";
import { useCurrency } from "@hny_/currency";
import cx from "classnames";
import { format } from "date-fns";

export const TransactionList: React.FC<ITransactionList> = (props) => {

  const { segment } = useBookkeeping();
  const { entities } = useCore();
  const curr = useCurrency();

  const [ hasInitiallyScrolled, setHasInitiallyScrolled ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!hasInitiallyScrolled) {
      if (segment.segmentation === "segment_by_month") {
        const today = format(new Date(), "MMMM");
        const todaySegment = document.getElementById(today);
        todaySegment?.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        const root = document.getElementById("transaction-list-root");
        root?.scrollIntoView({ behavior: "smooth", block: "start" })
      }
      setHasInitiallyScrolled(true);
    }
  }, [ segment, hasInitiallyScrolled ]);

  return (
    <div 
      id={ "transaction-list-root" }
      style={{ marginBottom: "100%" }}
      className={ "y space-cubes-children" }>

      {
        props.transactionSegments?.map(ts => (
          <div
            id={ ts.label }>

            {
              (ts.label && segment.segmentation === "segment_by_entity") &&
                <div
                style={{ background: "transparent" }}
                  className={ "y space-lines-frame surface-alt align-y-center" }>
                  <p><b>{ getEntity(ts.label, entities.all)?.name }</b></p>
                  <h2>{ curr.toHumanReadable(ts.summary?.all?.total || 0) }</h2>
                </div>
            }

            {
              (ts.label && segment.segmentation === "segment_by_month") &&
                <div
                  style={{ background: "transparent" }}
                  className={ "y space-lines-frame surface-alt align-y-center" }>
                  <p><b>{ ts.label }</b></p>
                  <h2>{ curr.toHumanReadable(ts.summary?.all?.total || 0) }</h2>
                </div>
            }

            <div 
              className={ cx(
                "y surface fill-x border-r border-l radius",
                { "border-t": ts.transactions.length > 0 }
              ) }>
              {
                ts.transactions?.map(tx => {
                  return (
                    <TransactionCard
                      tx={ tx }
                      summary={ tx.summary }
                      paymentState={ tx.paymentState }
                      isSelected={ tx.id === props.selectedTransactionId }
                      onClick={ (txId) => {
                        if (txId === props.selectedTransactionId) {
                          props.onSelectTransaction?.();
                        } else {
                          props.onSelectTransaction?.(txId);
                        }
                      }} />
                  );

                })
              }
            </div>

          </div>
        ))
      }

    </div>
  );
}

export interface ITransactionList {
  transactionSegments?: TransactionExpandedSegment[]
  selectedTransactionId?: uuid
  onSelectTransaction?: (id?: uuid) => void
};