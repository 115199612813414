import { COUNTRIES, Country, CURRENCIES, Currency, uuid } from "@bookie/glossary";
import { InputText, InputSelect, Tooltip, Button, IconRightAccordian } from "@bookie/components";
import React from "react";
import cx from "classnames";
import { IEntityEditorAPI, useEntityEditor } from "../../use/use-entity-editor";
import { getCountry } from "../../fns/get-country";
import { getCurrency } from "../../fns/get-currency";
import { SelectEntityRole } from "../../components/select-entity-role";
import { SelectEntityRegistrationType } from "../../components/select-entity-registration-type";

export const EntityEditorQuick: React.FC<IEntityEditorQuick> = (props) => {

  const editor = useEntityEditor(
    props.entityId,
    props.editor
  );

  return (
    <div 
      className={ "y fill" }>

      <div 
        style={{ borderRadius: "0", height: "55px" }}
        className={ "x surface border-b space-lines-frame-lr align-x-between align-y-center" }>
        <div 
          className={ "x align-y-center space-lines-children" }>
            
            <Button 
              label={ "Save" }
              onClick={ props.onFinish }
              size={ "slim" } />

            <Tooltip
              base={
                <Button
                  icon={ <IconRightAccordian /> }
                  className={ "hvr-icon-forward" }
                  variant={ "inline" }
                  onClick={ () => props.onFinish?.() } />
              }
              message={ "Close and cancel any changes" }
              position={ "right" } />
              
        </div>
      </div>
    
    
      <div
        className={ cx(
          "y fill-x space-lines-children space-lines-frame"
        ) }>
          
          <p
            className={ "surface-info" }>
            { "This Client will be saved for you when you save the transaction." }
          </p>

          {
            editor.data.type === "client" && 
              <SelectEntityRole
                value={ editor.data.role }
                onSelect={ (v) => editor.edit.role(v.id) } />
          }

          {
            (
              (editor.data.type === "client" && editor.data.role === "business") ||
              (editor.data.type === "merchant") 
            ) && 
              <SelectEntityRegistrationType
                label={ "Business Type" }
                value={ editor.data.registrationType }
                onSelect={ (v) => editor.edit.registrationType(v.id) } />
          }

          {
            editor.data.registrationType === "business" &&
              <InputText
                type={ "text" }
                className={ "fill-inputs" }
                label={ "Business Name" }
                placeholder={ "Bookie" }
                value={ editor.data.name }
                onChange={ v => editor.edit.name(v as string) }
                error={ editor.errors.validation?.["name"] } /> 
          }

          {
            editor.data.registrationType === "individual" && 
              <div 
                className={ "x space-lines-children" }>

                <InputText
                  type={ "text" }
                  className={ "fill-inputs" }
                  label={ "First Name" }
                  placeholder={ "Bookie" }
                  value={  editor.related.contactEditor.data.nameGiven }
                  onChange={ v => {
                    editor.related.contactEditor.edit.nameGiven(v as string);
                    editor.edit.name(`${ v as string } ${ editor.related.contactEditor.data.nameFamily }`);
                  } }
                  error={ editor.errors.validation?.["name"] } /> 
                
                <InputText
                  type={ "text" }
                  className={ "fill-inputs" }
                  label={ "Last Name" }
                  placeholder={ "Bookerton" }
                  value={ editor.related.contactEditor.data.nameFamily }
                  onChange={ v => {
                    editor.related.contactEditor.edit.nameFamily(v as string);
                    editor.edit.name(`${ editor.related.contactEditor.data.nameGiven } ${ v as string }`);
                  } }
                  error={ editor.errors.validation?.["name"] } /> 

              </div>
          }

          <div 
            className={ "x space-lines-children" }>
          
            <InputSelect
              type={ "text" }
              optionsAlign={ "right" }
              label={ "Country" }
              name={ "entity-country" }
              mode={ "edit" }
              value={ getCountry(editor.data.countryCode) }
              options={ COUNTRIES }
              matchKey={ "alpha3" }
              searchKey={ "name" }
              formatLabel={ (c: Country) => {
                return (
                  <div
                    className={ "x space-inline-children align-y-baseline" }>
                    <div>{ c.emoji }</div>
                    <div>{ c.name }</div>
                  </div>
                ); 
              } }
              onSelect={ (c: Country) => editor.edit.countryCode(c.alpha3) }
              error={ editor.errors.validation?.["countryCode"] } />
            
            <InputSelect
              type={ "text" }
              label={ "Currency" }
              optionsAlign={ "right" }
              name={ "entity-currency" }
              mode={ "edit" }
              value={ getCurrency(editor.data.currencyCode) }
              options={ CURRENCIES }
              matchKey={ "code" }
              searchKey={ "name" }
              formatLabel={ (c: Currency) => {
                return (
                  <div
                    className={ "x space-inline-children align-y-baseline" }>
                    <div>{ c.emoji }</div>
                    <div>{ c.name }</div>
                  </div>
                ); 
              } }
              onSelect={ (c: Currency) => editor.edit.currencyCode(c.code) }
              error={ editor.errors.validation?.["currencyCode"] } />
          
          </div>
          
          {/* <InputText
            type={ "text" }
            labelPosition={ "left" }
            align={ "right" }
            label={ "TIN" }
            placeholder={ "Tax Identification number" }
            value={ editor.data.tin }
            onChange={ v => editor.edit.tin(v as string) }
            className={ "fill-x" } /> */}

      </div>
    </div>
  );
}

export interface IEntityEditorQuick {
  entityId?: uuid | "create"
  editor?: IEntityEditorAPI
  onFinish?: () => void
}