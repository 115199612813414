import React from "react";
import { useCurrency } from "@hny_/currency";
import { Button, ChartBar, IconAdd, Workbench } from "@bookie/components";
import { Stat, Empty } from "@bookie/components";
import { useBookkeeping } from "../../use-bookkeeping";
import { TransactionFilter } from "../../components/transaction-filter";
import { TransactionList } from "../../components/transaction-list";
import { uuid } from "@bookie/glossary";
import { TransactionEditor, ITransactionEditorConfig } from "../transaction-editor";

export const TransactionDashboard: React.FC = () => {

  const { transactions } = useBookkeeping();
  const { toHumanReadable, toApplicationReadable, toDataReadable } = useCurrency();

  const isEmpty = (
    transactions.meta.totalIncome === 0
  );

  const [ editorConfig, setEditorConfig ] = React.useState<ITransactionEditorConfig>({});

  return (
    <div
      className={ "y fill-x" }>

        <TransactionFilter
            type={ "income" }
            actions={[
              isEmpty
                ? null
                : (
                  <div
                    className={ "x fill-y" }>
                    
                    <div 
                      className={ "x space-lines-frame-lr border-l border-r space-inlinez-children gr-income-lr align-y-center" }>

                      <Button
                        variant={ "fill" }
                        surface={ "surface-positive hvr-pulse hvr-brighten" }
                        icon={ <IconAdd /> }
                        label={ "Estimate" }
                        onClick={ () => {
                          setEditorConfig({
                            ...editorConfig,
                            txId: "create",
                            transactionStage: "estimate",
                            transactionType: "income",
                            useAssist: false
                          });
                        } } />

                      <Button
                        variant={ "fill" }
                        surface={ "surface-positive hvr-pulse hvr-brighten" }
                        icon={ <IconAdd /> }
                        label={ "Invoice" }
                        onClick={ () => {
                          setEditorConfig({
                            ...editorConfig,
                            txId: "create",
                            transactionStage: "invoice",
                            transactionType: "income",
                            useAssist: false
                          });
                        } } />
                      
                      {/* <Button
                        surface={ "surface-positive" }
                        variant={ "fill" }
                        icon={ <IconAdd /> }
                        onClick={ () => {
                          setEditorConfig({
                            ...editorConfig,
                            txId: "create",
                            transactionStage: "invoice",
                            transactionType: "income",
                            useAssist: false
                          });
                        } } /> */}

                    </div>
                    
                    <div 
                      className={ "x space-lines-frame-lr space-inlinez-children gr-expense-lr align-y-center" }>
                      {/* <Button
                        surface={ "surface-primary-as-button" }
                        variant={ "fill" }
                        icon={ <IconAdd /> }
                        label={ "Expense" }
                        onClick={ () => {
                          setEditorConfig({
                            ...editorConfig,
                            txId: "create",
                            transactionStage: "invoice",
                            transactionType: "expense",
                            useAssist: true
                          });
                        } } /> */}
                      <Button
                        surface={ "surface-negative" }
                        variant={ "fill" }
                        className={ "surface-button-negative hvr-pulse hvr-brighten" }
                        icon={ <IconAdd /> }
                        label={ "Expense" }
                        onClick={ () => {
                          setEditorConfig({
                            ...editorConfig,
                            txId: "create",
                            transactionStage: "invoice",
                            transactionType: "expense",
                            useAssist: false
                          });
                        } } />
                    </div>

                  </div>
                )
            ]} />

      <div 
        style={{ position: "relative" }}
        className={ "x fill-x fill-y" }>
        
        <div 
          className={ "y fill-x surface-aside apply-bg" }>
          
          <div 
            className={ "x fill-x fill-y align-x-center" }>
            
            <div
              style={{ maxWidth: "1400px" }}
              className={ "y fill-x" }>
              <div
                style={{ height: "0", maxWidth: "1400px" }} // <- we need this to activate scroll overflow, since the height is variable with flex-grow
                className={ "y fill-y scrollable space-lines-frame-tb space-cubes-frame-lr space-inline-children" }>

                {
                  !isEmpty && 
                    <TransactionList
                      transactionSegments={ transactions.segmented?.segments }
                      selectedTransactionId={ editorConfig.txId }
                      onSelectTransaction={ (id?: uuid) => setEditorConfig({ txId: id }) } />
                }
                
                {
                  isEmpty && 
                    <Empty
                      label={ "This is where you can find all of your Income and Expenses." }
                      description={[
                        "All the money you’ve brought in — whether it’s from sales, services, or anything else that fills your pockets — and all the money you spend — from expenses, purchases, or other outflows — belong here.",
                        "In Bookie, everything revolves around a transaction. Whether it's income where you are the payee or expenses where you are the payer, every financial movement is seamlessly managed.",
                        "Drafts, estimates, invoices, and receipts are different stages of the same transaction as it moves through its lifecycle. It’s all handled in one smooth flow — simple, clear, and all in one place.",
                        "Start tracking your income by creating an estimate or an invoice, or if you prefer, start with your expenses"
                      ]}
                      callToActions={
                        <div 
                          className={ "x space-lines-children space-lines-frame-t fill-x" }>

                          <Button
                            variant={ "inline" }
                            icon={ <IconAdd /> }
                            label={ "Create your first Estimate" }
                            onClick={
                              () => {
                                setEditorConfig({
                                  ...editorConfig,
                                  txId: "create",
                                  transactionStage: "estimate",
                                  transactionType: "income",
                                  useAssist: true
                                });
                              }
                            } />
                          
                          <Button
                            variant={ "inline" }
                            icon={ <IconAdd /> }
                            label={ "Create your first Invoice" }
                            onClick={ () => {
                              setEditorConfig({
                                ...editorConfig,
                                txId: "create",
                                transactionStage: "invoice",
                                transactionType: "income",
                                useAssist: true
                              });
                            } } />
                          
                          <Button
                            variant={ "inline" }
                            icon={ <IconAdd /> }
                            label={ "Create your first Expense" }
                            onClick={ () => {
                              setEditorConfig({
                                ...editorConfig,
                                txId: "create",
                                transactionStage: "invoice",
                                transactionType: "expense",
                                useAssist: true
                              });
                            } } />
                        
                        </div>
                      } />
                }

              </div>
            </div>

            <div 
              style={{ minWidth: "550px", maxWidth: "550px", flexGrow: 1, display: "none" }}
              className={ "surface-alt apply-bg y fill border-r" }>
              
              <div 
                className={ "x border-b" }>
                 
                <div 
                  style={{ width: "60%" }}
                  className={ "x align-y-center border-r space-cubes-frame-lr" }>
                  <Stat
                    label={ "Gross Accrued" }
                    className={ "surface-income" }
                    value={ toHumanReadable(transactions.segmented?.summary.accrued?.total || 0) } />
                </div>
                
                <div
                  style={{ width: "40%" }}
                  className={ "y" }>

                  <div
                    className={ "y space-inline-children space-lines-frame border-b" }>
                    <Stat
                      label={ "Net Accrued" }
                      className={ "surface-income" }
                      size={ "tiny" }
                      value={ toHumanReadable((transactions.segmented?.summary.accrued?.total || 0) - (transactions.segmented?.summary.expensesPaid?.total || 0)) } />
                  </div>
                  <div
                    className={ "y space-inline-children space-lines-frame" }>
                    <Stat
                      label={ "Cost Accrued" }
                      className={ "surface-expense" }
                      size={ "tiny" }
                      value={ toHumanReadable((transactions.segmented?.summary.accrued?.total || 0) + (transactions.segmented?.summary.expensesPaid?.total || 0)) } />
                  </div>

                </div>

              </div>

              <div
                style={{ maxWidth: "500px", height: "400px", maxHeight: "600px", flexGrow: 1, translate: "rotate(90deg)" }}
                className={ "x space-cubes-frame" }>
                <ChartBar
                  tooltipValueFormatter={ v => toHumanReadable(toDataReadable(Number(v))) } 
                  data={ transactions.segmented?.segments.map(s => ({
                    name: s.label || "",
                    net: toApplicationReadable(s.summary?.accrued?.total || 0),
                    cost: toApplicationReadable((s.summary?.accrued?.total || 0) + (s.summary?.expensesPaid?.total || 0))
                  })) }/>
              </div>

            </div>

          </div>

        </div>

        <Workbench
          width={ "50vw" }
          isOpen={ typeof editorConfig.txId !== "undefined" }
          onClose={ () => setEditorConfig({ }) }>
          
          <TransactionEditor 
            transactionType={ editorConfig.transactionType }
            txId={ editorConfig.txId || "" }
            transactionStage={ editorConfig.transactionStage }
            useAssist={ editorConfig.useAssist }
            onFinish={ () => {
              setEditorConfig({ })
            }}
            onFinishAssist={ () => {
              setEditorConfig({ ...editorConfig, useAssist: false })
            }}
            onUseAssist={ () => {
              setEditorConfig({ ...editorConfig, useAssist: true })
            } }
            onEdit={ (id, s, uA) => {
              // MIGHT NEED SOME TLC
              // AFTER MERGING INCOME AND EXPENSES
              setEditorConfig({
                ...editorConfig,
                txId: id,
                transactionStage: s,
                useAssist: typeof uA === "undefined" ? true : uA
              })
            }}
            onAnother={ (s?: "estimate" | "invoice") => {
              setEditorConfig({ });
              setTimeout(
                () => {
                  setEditorConfig({
                    ...editorConfig,
                    txId: "create",
                    transactionStage: s,
                    useAssist: true
                  })
                },
                200
              );
            }} />

        </Workbench>

      </div>

    </div>
  );
}