import React from "react";
import { OnboardingCard } from "../_onboarding-card";
import { IOrgEditorAPI } from "../../../use/use-org-editor";
import { InputTextArea } from "@bookie/components";
import { IllustrationDescription } from "@bookie/images";
import css from "./index.module.css";
import cx from "classnames";

export const OnboardingDescription: React.FC<IOnboardingDescription> = (props) => {
  return (
    <OnboardingCard
      className={ cx(
        css.onboardingCard,
        "y align-y-end relative surface-altx"
        ) }>

      <div 
        className={ css.description_illustration }>
        <img 
          src={ IllustrationDescription }
          alt={ "Name" } />
      </div>
    
      <div 
        className={ cx(
          css.description_form,
          "y space-cubes-frame-tb space-lines-children align-x-center surface"
        ) }>

        <div 
          className={ "y space-lines-children" }>
          <h2
            style={{ fontWeight: "bold", color: "#008080" }}>
            { `Describe what ${ props.editor?.related.entityEditor.data.registrationType === "individual" ? "you do" : "your business does" }` }
          </h2>

          <p
            style={{ fontSize: "16px" }}>
            {  `The better you describe the business, the better our Artifical Intelligence can serve you.` }
          </p>
        </div>
        
        <InputTextArea
          style={{
            "--hny-input-w": "100%"
          } as React.CSSProperties}
          backgroundType={ "fill" }
          placeholder={ " " }
          value={ props.editor?.related.entityEditor.data.businessDescription }
          onChange={ v => props.editor?.related.entityEditor.edit.businessDescription(v as string) } />
      
      </div>
      
    </OnboardingCard>
  )
}

export interface IOnboardingDescription {
  editor?: IOrgEditorAPI
}