import { Button, InputMoney, InputSelect, InputText } from "@bookie/components";
import { uuid } from "@bookie/glossary";
import React from "react";
import cx from "classnames";
import { SelectCurrency } from "../../components/select-currency";
import { SelectOfferingType } from "../../components/select-offering-type";
import { useCurrency } from "@hny_/currency";
import { IOfferingEditorAPI, useOfferingEditor } from "../../use/use-offering-editor";

export const OfferingEditor: React.FC<IOfferingEditor> = (props) => {

  const curr = useCurrency();

  const editor = useOfferingEditor(
    props.offeringId,
    props.editor
  );

  return (
    <div
      style={{ maxWidth: "900px" }}
      className={ "y fill-x border-r surface" }>

      <div 
        className={ "x surface border-b space-blocks-frame-lr space-lines-frame-tb align-x-between" }>
        
        <div 
          className={ "x" }>
        
          <Button
            label={ "Save" }
            size={ "slim" }
            onClick={ async () => {
              const result = await editor.commit();
              result && props.onFinish?.();
            } } />
          
          <Button
            size={ "slim" }
            variant={ "text" }
            label={ "Cancel" }
            onClick={ props.onFinish } />
            
        </div>
        
        {
          props.offeringId !== "create" && 
            <Button 
              label={ "Delete" }
              size={ "slim" }
              variant={ "dangerous" }
              surface={ "surface-negative" }
              isDangerous={ true }
              onClick={ async () => {
                const result = await editor.delete();
                result && props.onFinish?.();
              } } />
        }

      </div>

      <div 
        className={ "x fill" }>
        
        <div className={ "y fill" }>
          <div 
            style={{ height: "0px" }}
            className={ "y fill surface scrollable" }>
            
            <div 
              id={ "definition" }
              className={ cx(
                "surface border-b space-blocks-frame space-blocks-children y"
              ) }>
              
              <span 
                className={ "surface-base surface-heading" }>
                { "Definition" }
              </span>
              
              <InputText
                type={ "text" }
                label={ "Name" }
                value={ editor.data.name }
                onChange={ v => editor.edit.name(v as string) }
                error={ editor.errors.validation?.["name"] } />
              
              <InputText
                type={ "text" }
                label={ "Description" }
                className={ "fill-inputs" }
                value={ editor.data.description }
                onChange={ v => editor.edit.description(v as string) }
                error={ editor.errors.validation?.["description"] } />
              
              <SelectCurrency
                label={ "Currency" }
                showName={ true }
                selectedCurrencyCode={ editor.data.currencyCode }
                onChange={ v => editor.edit.currencyCode(v as string) }
                error={ editor.errors.validation?.["currencyCode"] } />
              
              <SelectOfferingType
                label={ "Type" }
                selectedOfferingType={ editor.data.type }
                onSelect={ v => editor.edit.type(v) }
                error={ editor.errors.validation?.["type"] } />
              
              <InputText
                type={ "text" }
                label={ "SKU" }
                className={ "fill-inputs" }
                value={ editor.data.sku }
                onChange={ v => editor.edit.sku(v as string) }
                error={ editor.errors.validation?.["sku"] } />

            </div>

            <div 
              id={ "pricing" }
              className={ cx(
                "surface border-b space-blocks-frame space-blocks-children y"
              ) }>
              
              <span 
                className={ "surface-base surface-heading" }>
                { "Pricing" }
              </span>
              
              {/* <InputMoney
                label={ "Cost Price" }
                description={ "Did this cost you anything to produce?" }
                value={ editor.data.grossCost }
                onChange={ v => { editor.edit.grossCost(v as number) } }
                toApplicationReadable={ curr.toApplicationReadable }
                toDataReadable={ curr.toDataReadable }
                toHumanReadable={ curr.toHumanReadable }
                error={ editor.errors.validation?.["grossCost"] } /> */}
              
              <InputMoney
                label={ "Sale Price" }
                description={ "How much are you selling this for?" }
                value={ editor.data.grossSale }
                onChange={ v => { editor.edit.grossSale(v as number) } }
                toApplicationReadable={ curr.toApplicationReadable }
                toDataReadable={ curr.toDataReadable }
                toHumanReadable={ curr.toHumanReadable }
                error={ editor.errors.validation?.["grossSale"] } />
              
              <InputSelect
                label={ "Tax Type" }
                options={[
                  { id: "additive", label: "Calculate the % off of the Sale price" },
                  { id: "inclusive", label: "Include the tax in the Sale price" },
                ]}
                matchKey={ "id" }
                formatLabel={ x => x.label }
                value={ [
                  { id: "additive", label: "Calculate the % off of the Sale price" },
                  { id: "inclusive", label: "Include the tax in the Sale price" },
                ].find(tt => tt.id === editor.data.taxType) }
                onSelect={ x => editor.edit.taxType(x.id) }
                description={ "How do you want to apply the tax?" }
                error={ editor.errors.validation?.["taxType"] } />
              
              {/* <InputNumber
                label={ "Tax (%)" }
                value={ editor.data.tax }
                onChange={ v => editor.edit.tax(v as number) }
                error={ editor.errors.validation?.["tax"] } />   */}
              
              {/* <div
                className={ "surface-alt space-lines-frame-tb" }>
                <p>
                  {
                    summariseTransactionOffering(
                      editor.data.grossSale,
                      1,
                      editor.data.tax,
                      editor.data.taxType
                    )
                  }
                </p>
              </div> */}

            </div>

          </div>
        </div>
      </div>

    </div>
  );
}

export interface IOfferingEditor {
  offeringId?: uuid | "create"
  editor?: IOfferingEditorAPI
  onFinish?: () => void
}