import { Button, IconAddData, IconPlus, InputSelect, Scrollable } from "@bookie/components";
import React from "react";
import { SelectOfferingType, useCore } from "@bookie/module-core";
import { useCurrency } from "@hny_/currency";
import { TransactionOfferingEditor } from "../transaction-offering-editor";
import { TransactionType } from "@bookie/glossary";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";

export const TransactionOfferingsEditor: React.FC<ITransactionOfferingsEditor> = (props) => {

  const { offerings } = useCore();
  const curr = useCurrency();

  const [selectedOffering, setSelectedOffering] = React.useState<number>(-1);

  return (
    <div
      className={ "y fill-x" }>

      <div>
        {
          props.editor?.related.transactionOfferingsEditor?.existing.data.map((offering, i) => (
            <TransactionOfferingEditor
              i={ i }
              key={ offering.id }
              offering={ offering }
              surfaceDisplay={ props.surfaceDisplay }
              transactionType={ props.transactionType }
              currencyCode={ props.currencyCode }
              isSelected={ selectedOffering === i }
              onSelect={ () => setSelectedOffering(i) }
              onEdit={ (f, v) => props.editor?.related.transactionOfferingsEditor?.existing.edit(i, f, v) }
              onDelete={ () => props.editor?.related.transactionOfferingsEditor?.existing.remove(offering.id) }
              errors={ props.editor?.related.transactionOfferingsEditor?.existing.errors.validation?.[i] }
              onMinimise={ () => setSelectedOffering(-1) }
              isInferringTaxCodes={ props.editor?.related.transactionOfferingsEditor.state.isInferringTaxCodes }
              inferTaxCodes={ props.editor?.related.transactionOfferingsEditor?.inferTaxCodes }
              hasAttemptedToInfer={ props.editor?.related.transactionOfferingsEditor?.state.hasAttemptedToInfer }
              onEditTaxCode={ () => props.onEditTaxCode?.("existing", i) } />
          ))
        }

        {
          props.editor?.related.transactionOfferingsEditor?.new.data.map((offering, i) => (
            <TransactionOfferingEditor
              i={ i }
              key={ offering.id || `new-${i}` } // Ensure unique keys
              offering={ offering }
              surfaceDisplay={ props.surfaceDisplay }
              inventory={ typeof props.editor?.related.transactionOfferingsEditor?.inventory.inventory[i] !== "undefined" ? true : false }
              addToInventory={ () => props.editor?.related.transactionOfferingsEditor?.inventory.addToInventory(i) }
              removeFromInventory={ () => props.editor?.related.transactionOfferingsEditor?.inventory.removeFromInventory(i) }
              transactionType={ props.transactionType }
              currencyCode={ props.currencyCode }
              isSelected={ selectedOffering === (i + (props.editor?.related.transactionOfferingsEditor?.existing.data.length || 0)) }
              onSelect={ () => setSelectedOffering(i + (props.editor?.related.transactionOfferingsEditor?.existing.data.length || 0)) }
              onEdit={ (f, v) => props.editor?.related.transactionOfferingsEditor?.new.edit(i, f, v) }
              onDelete={ () => props.editor?.related.transactionOfferingsEditor?.new.remove(i) }
              errors={ props.editor?.related.transactionOfferingsEditor?.new.errors.validation?.[i] }
              onMinimise={ () => setSelectedOffering(-1) }
              inferTaxCodes={ props.editor?.related.transactionOfferingsEditor?.inferTaxCodes }
              isInferringTaxCodes={ props.editor?.related.transactionOfferingsEditor.state.isInferringTaxCodes }
              hasAttemptedToInfer={ props.editor?.related.transactionOfferingsEditor?.state.hasAttemptedToInfer }
              onEditTaxCode={ () => props.onEditTaxCode?.("new", i) } />
          ))
        }
      </div>

      <div 
        className={ "x fill-x fill-btns space-inline-children space-lines-frame-tb" }>
        
        <Button
          variant={ "inline" }
          size={ "slim" }
          surface={ "surface-button-inline fill-x" }
          label={ "Add a new line item" }
          // onClick={ (e) => {
          //   e?.stopPropagation();
          //   props.editor?.related.transactionOfferingsEditor?.new.add();
          // } } 
          icon={ <IconPlus weight={ "regular" } /> }
          // iconSize={ 14 }
          optionsIconStyle={ "chevron" }
          options={
            <div
              style={{ maxHeight: "220px", minHeight: "200px" }}
              className={ "x fill-x surface radius border shadow" }>
              <Scrollable
                className={ "x wrap space-lines-frame space-lines-children center" }>
                
                <SelectOfferingType
                  align={ "center" }
                  tileSize={ "medium" }
                  onSelect={ v => props.editor?.related.transactionOfferingsEditor?.new.add({ type: v }) } />

              </Scrollable>
            </div>
          } />

        {
          props.transactionType !== "expense" && 
            <Button
              variant={ "inline" }
              size={ "slim" }
              icon={ <IconAddData /> }
              surface={ "surface-button-inline fill-x" }
              label={ "Add from your Inventory" }
              optionsIconStyle={ "chevron" }
              options={
                <InputSelect
                  formatLabel={ opt => (
                    <div
                      style={{ fontWeight: "normal" }}
                      className={ "x align-x-between fill-x" }>
                      <p>{ opt.name }</p>
                      <p>{ curr.toHumanReadable(opt.grossSale, undefined, opt.currencyCode) }</p>
                    </div>
                  ) }
                    
                  matchKey={ "id" }
                  onSelect={ o => props.editor?.related.transactionOfferingsEditor?.new.add(o) }
                  options={ offerings.all }
                  noOptionsCopy={ "You haven't created any products or services in your inventory yet. To create one, start adding a new one here and select 'Save to Inventory'." }
                  forceOpen={ true }
                  searchKey={ "name" }
                  optionsAlign={ "center" }
                  onlyOptions={ true } />
              } />
        }

      </div>

    </div>
  );
}

export interface ITransactionOfferingsEditor {
  currencyCode?: string
  editor?: ITransactionEditorAPI
  transactionType?: TransactionType
  transactionStage?: "estimate" | "invoice"
  renderBare?: boolean
  isUsingAssist?: boolean
  surfaceDisplay?: string
  onEditTaxCode?: (type: "new" | "existing", index: number) => void
}
