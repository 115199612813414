import { TaxSetPeriod, TaxSetSales, timestamptz, TransactionOffering } from "@bookie/glossary";
import { CODES } from "./codes";
import { IVATForm, VATForm } from "./vat-form";
import { endOfDay, startOfDay, isBefore } from "date-fns";


export const VAT: TaxSetSales<IVATForm> = {

  label: "Value Added Tax",
  shorthand: "VAT",
  codes: CODES,

  description: `
  Malta's Value-Added Tax (VAT) is a consumption tax applied to most goods and services sold within the country. Businesses are responsible for charging VAT on their sales and can reclaim VAT paid on their purchases, ensuring that the tax is ultimately borne by the end consumer. The VAT system in Malta includes various tax codes that categorize different types of transactions, such as sales to other EU countries, exports, local sales, and purchases from both domestic and EU suppliers. These codes help businesses accurately calculate their VAT obligations, comply with Maltese tax regulations, and maintain organized financial records. By using the appropriate VAT codes, businesses can streamline their tax reporting processes and ensure precise adherence to the national VAT framework.
  `,

  calculateCurrentYear: () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const taxYearStart = new Date(`${currentYear}-11-01`);

    // If today is before November 1st, we're in the tax year for the NEXT calendar year
    if (isBefore(date, taxYearStart)) {
      return currentYear; // e.g., October 2024 -> tax year is 2024
    } else {
      return currentYear + 1; // e.g., November 2024 -> tax year is 2025
    }
  },

  calculatePeriods: (year?: number) => {

    if (!year) return [];
    
    const startOfQ1 = new Date(year - 1, 10, 1);

    const periods: TaxSetPeriod[] = [
      {
        id: "Q1",
        label: `Q1`,
        from: startOfDay(startOfQ1).toISOString() as timestamptz,
        to: endOfDay(new Date(year, 0, 31)).toISOString() as timestamptz
      },
      {
        id: "Q2",
        label: `Q2`,
        from: startOfDay(new Date(year, 1, 1)).toISOString() as timestamptz,
        to: endOfDay(new Date(year, 3, 30)).toISOString() as timestamptz
      },
      {
        id: "Q3",
        label: `Q3`,
        from: startOfDay(new Date(year, 4, 1)).toISOString() as timestamptz,
        to: endOfDay(new Date(year, 6, 31)).toISOString() as timestamptz
      },
      {
        id: "Q4",
        label: `Q4`,
        from: startOfDay(new Date(year, 7, 1)).toISOString() as timestamptz,
        to: endOfDay(new Date(year, 9, 31)).toISOString() as timestamptz
      },
    ];

    return periods;

  },

  inferTaxCode: (to: TransactionOffering) => [],

  Form: VATForm

}