import { TaxCode } from "@bookie/glossary";

export const CODES: TaxCode[] = [
  {
    id: "MLT_VAT_OOS",
    name: "Out of Scope",
    description: "This good or service does not fall into the scope of Malta's VAT laws, so the tax applied is subject to the laws of the other party",
    type: "unspecified",
    scope: "international",
    tax: -1,
    taxFunction: "out-of-scope"
  },
  {
    id: "MLT_VAT_01",
    name: "Sales Outside Malta to Businesses",
    officialBoxRef: "1",
    officialName: "Exempt IC Supplies of Goods and Supplies of Services where customer is liable for the tax",
    description: "For goods or services sold to businesses in other EU countries. The buyer is responsible for paying the tax in their country, so you don't charge VAT.",
    type: "income",
    scope: "international",
    tax: 0,
    taxFunction: "output"
  },
  {
    id: "MLT_VAT_02",
    officialBoxRef: "2",
    name: "Sales Outside Malta to Customers",
    officialName: "Supplies of Goods and Services where Place of Supply is outside Malta - EU and Non EU",
    description: "For goods or services sold to individual customers outside Malta (including both EU and non-EU countries). No VAT is charged because the income happens outside Malta.",
    type: "income",
    scope: "international",
    tax: 0,
    taxFunction: "output"
  },
  {
    id: "MLT_VAT_03",
    officialBoxRef: "3",
    name: "Purchases from EU Businesses",
    officialName: "IC Acquisitions of Goods and Services received from other EU Member States",
    description: "For goods or services bought from businesses in other EU countries. You are required to calculate and pay the VAT in Malta.",
    type: "expense",
    scope: "international",
    tax: 18,
    taxFunction: "output"
  },
  {
    id: "MLT_VAT_04",
    name: "Purchases in Malta",
    officialBoxRef: "4",
    officialName: "Goods and Services received where Place of Supply is Malta other than those reported in Box 3",
    description: "For goods or services expensed locally within Malta, where the seller charges VAT. This applies to most standard expenses in Malta.",
    type: "expense",
    scope: "domestic",
    tax: 18,
    taxFunction: "output"
  },
  {
    id: "MLT_VAT_09",
    name: "Goods Bought from the EU",
    officialBoxRef: "9",
    officialName: "IC Acquisitions of Goods excluding Capital Goods",
    description: "For goods expensed from businesses in other EU countries, excluding large equipment or property (capital goods). You calculate and pay the VAT in Malta.",
    type: "expense",
    scope: "international",
    tax: 18,
    taxFunction: "input"
  },
  {
    id: "MLT_VAT_09A",
    name: "Services Bought from the EU",
    officialBoxRef: "9A",
    officialName: "Services received from EU Member States where the expenser is liable for VAT",
    description: "For services expensed from businesses in other EU countries. You calculate and pay the VAT in Malta.",
    type: "expense",
    scope: "international",
    tax: 18,
    taxFunction: "input"
  },
  {
    id: "MLT_VAT_10",
    name: "Large Purchases from the EU",
    officialBoxRef: "10",
    officialName: "IC Acquisitions of Capital Goods",
    description: "For large expenses like equipment or property (capital goods) bought from EU businesses. You calculate and pay the VAT in Malta.",
    type: "expense",
    scope: "international",
    tax: 18,
    taxFunction: "input"
  },
  {
    id: "MLT_VAT_11",
    name: "Local Purchases in Malta",
    officialBoxRef: "11",
    officialName: "Goods and Services Received where Place of Supply is Malta",
    description: "For goods and services bought from businesses in Malta. VAT is included in the price you pay.",
    type: "expense",
    scope: "domestic",
    tax: 18,
    taxFunction: "input"
  },
  {
    id: "MLT_VAT_18",
    name: "Standard VAT",
    officialBoxRef: "18",
    officialName: "Taxable Goods/Services @ 18%",
    description: "For goods and services sold or bought in Malta with the standard VAT rate of 18%.",
    type: "income",
    scope: "domestic",
    tax: 18,
    taxFunction: "output"
  },
  {
    id: "MLT_VAT_18A",
    name: "Reduced VAT for Tourism",
    officialBoxRef: "18A",
    officialName: "Taxable Services @7%",
    description: "For specific services, like tourism-related activities, that are taxed at a reduced VAT rate of 7%.",
    type: "income",
    scope: "domestic",
    tax: 7,
    taxFunction: "output"
  },
  {
    id: "MLT_VAT_19",
    name: "Reduced VAT for Essentials",
    officialBoxRef: "19",
    officialName: "Taxable Goods/Services @ 5%",
    description: "For certain goods and services, like utilities or medical products, taxed at a reduced VAT rate of 5%.",
    type: "income",
    scope: "domestic",
    tax: 5,
    taxFunction: "output"
  },
  {
    id: "MLT_VAT_20",
    name: "Tax-Free Exports",
    officialBoxRef: "20",
    officialName: "Exempt with Credit / Exports",
    description: "For goods or services sold outside Malta (exports) or to businesses where VAT is not charged because you can claim it back later.",
    type: "income",
    scope: "international",
    tax: 0,
    taxFunction: "output"
  },
  {
    id: "MLT_VAT_21",
    name: "Tax-Free Sales (No Credit)",
    officialBoxRef: "21",
    officialName: "Exempt without Credit",
    description: "For goods or services sold in Malta that are VAT-exempt but without the ability to claim back any VAT you paid.",
    type: "income",
    scope: "domestic",
    tax: 0,
    taxFunction: "output"
  },
  {
    id: "MLT_VAT_27",
    name: "Goods for Reincome",
    officialBoxRef: "27",
    officialName: "Taxable Purchases for re-income @ 18%",
    description: "For goods bought to resell that are taxed at the standard VAT rate of 18%.",
    type: "expense",
    scope: "domestic",
    tax: 18,
    taxFunction: "input"
  },
  {
    id: "MLT_VAT_28",
    name: "Goods for Reincome",
    officialBoxRef: "28",
    officialName: "Taxable Purchases for re-income @ 5%",
    description: "For goods bought to resell that are taxed at the reduced VAT rate of 5%.",
    type: "expense",
    scope: "domestic",
    tax: 5,
    taxFunction: "input"
  },
  {
    id: "MLT_VAT_29",
    name: "Reincome Goods",
    officialBoxRef: "29",
    officialName: "Exempt Purchases for re-income",
    description: "For goods bought to resell that are VAT-exempt.",
    type: "expense",
    scope: "domestic",
    tax: 0,
    taxFunction: "input"
  },
  {
    id: "MLT_VAT_30",
    name: "Large Purchases (Capital Goods)",
    officialBoxRef: "30",
    officialName: "Capital Goods",
    description: "For large expenses like equipment or property used for your business. These items are treated differently for VAT purposes.",
    type: "expense",
    scope: "domestic",
    tax: 18,
    taxFunction: "input"
  },
  {
    id: "MLT_VAT_31",
    name: "Overhead Costs",
    officialBoxRef: "31",
    officialName: "Services & Overheads @ 18%",
    description: "For business expenses like rent, utilities, or supplies taxed at the standard VAT rate of 18%.",
    type: "expense",
    scope: "domestic",
    tax: 18,
    taxFunction: "input"
  },
  {
    id: "MLT_VAT_31A",
    name: "Overhead Costs",
    officialBoxRef: "31A",
    officialName: "Services & Overheads @7%",
    description: "For business expenses related to specific industries, like tourism, taxed at a reduced VAT rate of 7%.",
    type: "expense",
    scope: "domestic",
    tax: 7,
    taxFunction: "input"
  },
  {
    id: "MLT_VAT_32",
    name: "Overhead Costs",
    officialBoxRef: "32",
    officialName: "Services & Overheads @ 5%",
    description: "For business expenses taxed at a reduced VAT rate of 5%.",
    type: "expense",
    scope: "domestic",
    tax: 5,
    taxFunction: "input"
  }
];
