import { TransactionType } from "@bookie/glossary";
import { hydrateEntityType, HydratedEntityType } from "@bookie/module-core";

export const hydrateTransactionType = (type?: TransactionType): HydratedTransactionType => {

  switch (type) {
    
    case "income": return ({
      id: type,
      label: "Income",
      entity: hydrateEntityType("client")
    });

    case "expense": return ({
      id: type,
      label: "Expense",
      entity: hydrateEntityType("merchant")
    });

    default: return ({
      id: "income",
      label: "Transaction"
    });

  }
}

export interface HydratedTransactionType {
  id: string,
  label: string,
  entity?: HydratedEntityType
}