import { DisplayAddress, DisplayContact, IEntityEditorAPI, SelectCurrency } from "@bookie/module-core";
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import React from "react";
import { Button, IconDates, IconExchange, InputDate, Tooltip } from "@bookie/components";
import { useTimezone } from "@hny_/timezone";
import { TransactionStage, TransactionType } from "@bookie/glossary";
import { useIdentity } from "@bookie/module-identity";
import { TransactionEntityEditor } from "../../transaction-entity-editor";
import { ITransactionEditorWorkbenchStates } from "..";

export const TransactionEditorTransactionMeta: React.FC<ITransactionEditorTransactionMeta> = (props) => {

  const { ownership } = useIdentity();
  const tz = useTimezone();

  return (
    <div 
      className={"x fill-x space-lines-frame-tb align-x-between space-cubes-children"}>
      
      {
        props.editor?.type === "income" && 
          <TransactionEntityEditor

            editor={ props.editor }
            style={{ flexBasis: "2", flexGrow: "1" }}

            isEditing={ props.isEditing }

            isEditingEntity={ props.isEditingEntity }
            onEditEntity={ props.onEditEntity }
            onFinishEditingEntity={ props.onFinishEditingEntity }

            onEditEntityAddress={ props.onEditEntityAddress }
            isEditingEntityAddress={ props.isEditingEntityAddress }
            onFinishEditingEntityAddress={ props.onFinishEditingEntityAddress }

            onEditEntityContact={ props.onEditEntityContact }
            isEditingEntityContact={ props.isEditingEntityContact }
            onFinishEditingEntityContact={ props.onFinishEditingEntityContact } />
      }

      {
        props.editor?.type === "expense" && 
          <div
            style={{ flexBasis: "2", flexGrow: "1" }}
            className={ "y space-inline-children" }>

            <h2>{ ownership.current?.entity?.name }</h2>
            
            <DisplayAddress
              className={ "y" }
              isEditing={ props.isEditing }
              address={ props.userEditor?.related.addressEditor.data }
              isEditingAddress={ props.isEditingAddress }
              onEditAddress={ props.onEditAddress }
              onFinishEditingAddress={ props.onFinishEditingAddress } />
              
            <DisplayContact
              className={ "y" }
              isEditing={ props.isEditing }
              entity={ props.userEditor?.data }
              isEditingContact={ props.isEditingContact }
              onFinishEditingContact={ props.onFinishEditingContact }
              onEditContact={ props.onEditContact } />

          </div>
      }


      <div 
        style={{ flexBasis: "2", flexGrow: "1" }}
        className={ "y fill-x space-lines-children" }>
        
        <div 
          className={ "x fill-x fill-inputs" }>
          {
            props.transactionStage === "estimate" && 
              <>
                
                <InputDate
                  label={ "Valid from" }
                  icon={ <IconDates /> }
                  value={ props.editor?.data.estimateDate }
                  onChange={ v => props.editor?.edit.estimateDate(v as string) }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable } />

                <InputDate 
                  label={ "Until" }
                  icon={ <IconDates /> }
                  value={ props.editor?.data.estimateExpiration }
                  onChange={ v => props.editor?.edit.estimateExpiration(v as string) }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable } />

              </>
          }

          {
            props.transactionStage === "invoice" && 
              <>
                <InputDate 
                  className={ (
                    (
                      props.editor?.data.estimateState === "approved" ||
                      props.editor?.data.estimateState === "n/a"
                    ) 
                      ? "" 
                      : "disabled"
                  ) }
                  label={ "Date" }
                  icon={ <IconDates /> }
                  value={ props.editor?.data.invoiceDate }
                  onChange={ v => props.editor?.edit.invoiceDate(v as string) }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable } />

                <InputDate 
                  className={ (
                    (
                      props.editor?.data.estimateState === "approved" ||
                      props.editor?.data.estimateState === "n/a"
                    ) 
                      ? "" 
                      : "disabled"
                  ) }
                  label={ "Due" }
                  icon={ <IconDates /> }
                  value={ props.editor?.data.invoiceDue }
                  onChange={ v => props.editor?.edit.invoiceDue(v as string) }
                  toApplicationReadable={ tz.toApplicationReadable }
                  toDataReadable={ tz.toDataReadable }
                  toHumanReadable={ tz.toHumanReadable } />

              </>
          }
        </div>

        <div 
          className={ "y" }>
          
          <SelectCurrency
            label={ "Currency" }
            showName={ true }
            optionsAlign={ "right" }
            selectedCurrencyCode={ props.editor?.data.currencyCode }
            onSelect={ c => props.editor?.edit.currencyCode(c.code) } />
          
          {
            props.editor?.state.isEntityInternational && 
              <Tooltip
                base={
                  <Button
                    variant={ "inline" }
                    size={ "slim" }
                    icon={ <IconExchange /> }
                    label={ `Switch to ${ props.editor.related.entityEditor.data.currencyCode }` }
                    onClick={ () => {
                      if (props.editor?.related.entityEditor.data.currencyCode) {
                        props.editor?.edit.currencyCode(props.editor.related.entityEditor.data.currencyCode);
                      }
                    } } />
                }  
                message={ `${ props.editor.related.entityEditor.data.name } uses ${ props.editor.related.entityEditor.data.currencyCode }. Do you want to switch to this currency for this transaction?` } />
          }

          {
            props.editor?.state.requiresLocalisation && 
              <Button
                variant={ "inline" }
                size={ "slim" }
                icon={ <IconExchange /> }
                label={ `Switch back to ${ ownership.current?.entity?.currencyCode }` }
                onClick={ () => {
                  if (ownership.current?.entity?.currencyCode) {
                    props.editor?.edit.currencyCode(ownership.current.entity.currencyCode);
                  }
                } } />
          }

        </div>
      
      </div>

    </div>
  );
}

export interface ITransactionEditorTransactionMeta extends ITransactionEditorWorkbenchStates {
  editor?: ITransactionEditorAPI
  userEditor?: IEntityEditorAPI
  transactionType?: TransactionType
  transactionStage?: TransactionStage
}