import { Status } from "@bookie/components";
import { EstimateState } from "@bookie/glossary";
import React from "react";

const LABELS = {
  "draft": "Draft",
  "submitted": "Sent to Client, requires approval",
  "approved": "Approved",
  "rejected": "Rejected",
  "n/a": "N/A"
};

export const TransactionStatusEstimate: React.FC<ITransactionStatusEstimate> = (props) => {
  return (
    <Status
      label={ props.state && props.label ? LABELS[props.state] : undefined }
      isPendingApproval={ props.state === "submitted" }
      isApproved={ props.state === "approved" }
      isRejected={ props.state === "rejected" }
      isActive={ props.state === "draft" }
      isInactive={ !props.state || props.state === "n/a" } />
  );
} 

export interface ITransactionStatusEstimate {
  label?: string
  state?: EstimateState
}