import React from "react";
import { useTaxSuites } from "../../use-tax-suites";
import css from "./index.module.css";
import cx from "classnames";
import { OfferingTaxCode } from "@bookie/glossary";
import { Tooltip } from "@bookie/components";
import { TaxCode } from "../tax-code";

export const TaxCodeTag: React.FC<ITaxCodeTag> = (props) => {

  const { getTaxCode } = useTaxSuites();

  const taxCode = getTaxCode(props.taxCode?.taxCodeId || "");

  if (!taxCode) {
    return null;
  }

  const isOutOfScope = taxCode.taxFunction === "out-of-scope";

  return (
    <Tooltip
      base={
        (
          <div
            className={ cx(
              css.taxCodeTag,
              "x",
              { [css.taxCodeTag_outOfScope]: isOutOfScope }
            ) }>    
        
            <div
              className={ css.taxCodeTag_name }>
              <span>{ taxCode.name }</span>
            </div>

            {
              !isOutOfScope && 
                <div
                  className={ css.taxCodeTag_id }>
                  <span>{`${ taxCode.taxFunction === "input" ? "-" : "+" } ${ taxCode.tax }% `}</span>
                </div>
            }
          
          </div>
        )
      }
      message={(
        <div
          style={{ width: "400px" }}
          className={ "surface-aside space-lines-frame space-lines-children radius y fill-y" }>
          
          <TaxCode
            code={ taxCode } />
          
          <div>{ props.taxCode?.reason }</div>

        </div>
      )} />
  );
}

export interface ITaxCodeTag {
  taxCode?: OfferingTaxCode
}