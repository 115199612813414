import React from "react";
import css from "./base.module.css";
import cx from "classnames";
import { IconValidation } from "../icon";
import { Tooltip } from "../tooltip";

export const InputBase: React.FC<IInputBase> = (props) => {
  return (
    <div
      style={ props.style }
      className={ cx(
        "hny-input",
        css.input,
        props.className,
        props.size ? css[`input_size_${ props.size }`] : "",
        css[`input_border_${ props.borderType || "none" }`],
        css[`input_labelPosn_${ props.labelPosition || "top" }`],
        css[`input_background_${ props.backgroundType || "none" }`],
        { [css.input_align_right]: props.align === "right" },
        { "hny-input_error": typeof props.error !== "undefined" }
      ) }>
      
      {
        (props.label || props.description) &&
          <div 
            className={ cx(
              css.input_meta, 
              "y",
              { "align-x-end": props.align === "right" }
            ) }>
            
            <div 
              className={ "x space-inline-children align-y-center" }>
            
              { 
                props.icon && 
                  React.cloneElement(
                    props.icon,
                    { size: props.iconSize || 18 }
                  ) 
              }

              <label
                className={ css.input_label }>
                { props.label }
              </label>

              {
                (props.error && props.labelPosition === "left") &&
                  <div
                    className={ cx(
                      css.input_error,
                      "x align-y-center"
                    ) }>
                    <Tooltip
                      message={ props.error }
                      base={ <IconValidation size={ 16 } /> }
                      position={ "bottom" } />
                  </div>
              }
            
            </div>

            {
              props.description && 
                <p
                  className={ cx(
                    css.input_description,
                    "x space-inline-frame-tb"
                  ) }>
                  { props.description }
                </p>
            }

          </div>
      }
      
      <div 
        className={ cx(
          css.input_container,
          props.hoverEffect,
          props.classNameForContainer
        ) }>
        
        {
          !props.startSideMission && 
            props.children
        }

        {
          props.startSideMission && props.sideMission && 
            props.sideMission
        }
      
      </div>

      {
        (props.error && props.labelPosition !== "left") &&
          <div
            className={ cx(
              css.input_error,
              "x space-inline-children align-y-center"
            ) }>
            <IconValidation size={ 16 } />
            <span>{ props.error }</span>
          </div>
      }

    </div>
  );
}

export interface IInputBase extends IInput {
  children?: React.ReactNode;
};

export interface IInput {
  type?: InputType;
  mode?: InputMode;
  label?: string;
  icon?: React.ReactElement;
  iconSize?: number;
  description?: string;
  name?: string;
  size?: InputSize;
  align?: InputAlign;
  labelPosition?: InputLabelPosition;
  borderType?: InputBorderType;
  backgroundType?: InputBackgroundType;
  onChange?: (value: unknown) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  sideMission?: React.ReactNode;
  startSideMission?: boolean;
  className?: string;
  classNameForContainer?: string
  hoverEffect?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: {[key: string]: any};
  error?: string
};

export type InputAlign = 
  "left" | 
  "right" |
  "center";

export type InputType = 
  "text" |
  "number";

export type InputMode = 
  "edit" |
  "read-only";

export type InputSize = 
  "small";

export type InputBackgroundType = 
  "fill" |
  "none";

export type InputBorderType = 
  "underline" |
  "outline" |
  "none";

export type InputLabelPosition = 
  "top" |
  "left" |
  "inside";