import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const Paper: React.FC<IPaper> = (props) => {

  if (props.dontRender) {
    return null;
  }

  return (
    <div 
      className={ cx(
        "surface apply-bg apply-fg radius shadow border space-cubes-frame",
        css.paper,
        { [css.paper_a4]: props.variant === "a4" }
      )}>

      { props.children }
      
    </div>
  );
}

export interface IPaper {
  children?: React.ReactNode
  variant?: IPaperVariant
  dontRender?: boolean
}

export type IPaperVariant = 
  "a4"