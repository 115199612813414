import React from "react";
import { OnboardingCard } from "../_onboarding-card";
import { IllustrationWelcomeBackground, LogoWhite as Logo } from "@bookie/images";
import css from "./index.module.css";
import cx from "classnames";

export const OnboardingWelcome: React.FC<IOnboardingWelcome> = (props) => {
  return (
    <OnboardingCard
      style={{ width: "620px", height: "440px" }}
      className={ "y align-x-center" }>
      
      <div 
        style={{
          backgroundImage: `url(${ IllustrationWelcomeBackground })`
        }}
        className={ cx(
          css.background,
          "surface-alt fill-x align-x-center x border-b space-blocks-frame-tb"
        ) }>
        <div 
          className={ "apply-fg x align-x-center align-y-center" }
          style={{
            width: "120px",
            height: "140px",
            borderRadius: "50%",
            position: "relative"
          }}>
          <img 
            style={{ position: "relative" }}
            src={ Logo }
            alt={ "Bookie" } />
          {/* <div 
            className={ "wave" }
            style={{ position: "absolute", left: "-30px", top: "140px"  }}>
            <IconWave size={ 46 } color={ "#008080" } />
          </div> */}
        </div>
      </div>
          
      <div
        style={{ paddingLeft: "80px", paddingRight: "80px" }}
        className={ "y fill-y space-blocks-frame align-x-center align-y-between space-inlinez-children" }>
    
        <div
          className={ "y align-x-center align-y-center space-inlinez-children" }>
          <h2>
            { "Welcome" }
          </h2>
          <p
            style={{ textAlign: "center", fontSize: "18px" }}>
            { "You’re almost ready to roll, we just need to ask you a few questions about your business" }
          </p>
        </div>
      
      </div>

    </OnboardingCard>
  );
}

export interface IOnboardingWelcome {
}