import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const OnboardingCard: React.FC<IOnboardingCard> = (props) => {
  return (
    <div
      style={ props.style }
      className={ cx(
        css.onboardingCard,
        "surface radius border shadow",
        props.className
      ) }>
      { props.children }
    </div>
  )
}

export interface IOnboardingCard {
  children?: React.ReactNode
  className?: string
  style?: { [key: string]: string }
}