import { Button, IconPlus, IconRightAccordian } from "@bookie/components";
import { EntityOrphan } from "@bookie/glossary";
import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const DisplayContact: React.FC<IDisplayContact> = (props) => {

  const isDefined = (
    props.entity?.email ||
    props.entity?.phoneNumber
  );

  if (isDefined) {
    return (
      <div
        onClick={ props.onEditContact }
        className={ cx(
          css.display,
          props.className,
          props.isEditing && !props.isEditingContact ? "disabled" : ""
        ) }>
  
        {
          (props.entity?.email) &&
            <p>
              { `${ props.entity?.email }` }
            </p>
        }
  
        {
          (props.entity?.phoneNumber) && 
            <p>
              { `${ props.entity?.phoneCountryCode } ${ props.entity?.phoneNumber }` }
            </p>
        }
  
      </div>
    );
  }

  if (props.isEditingContact) {
    return (
      <Button
        size={ "slim" }
        variant={ "inline" }
        style={{ padding: "0", height: "auto" }}
        label={ "Close Contact" }
        icon={ <IconRightAccordian /> }
        iconSize={ 14 }
        surface={ "surface-button-inline" }
        onClick={ props.onFinishEditingContact } />
    );
  } else {
    return (
      <Button
        size={ "slim" }
        variant={ "inline" }
        style={{ padding: "0", height: "auto" }}
        label={ "Add Contact" }
        className={ props.isEditing && !props.isEditingContact ? "disabled" : "" }
        icon={ <IconPlus weight={ "regular" } /> }
        iconSize={ 14 }
        surface={ "surface-button-inline" }
        onClick={ props.onEditContact } />
    );
  }

}

export interface IDisplayContact {
  className?: string
  entity?: EntityOrphan
  isEditing?: boolean
  isEditingContact?: boolean
  onEditContact?: () => void
  onFinishEditingContact?: () => void
}