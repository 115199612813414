/* eslint-disable react-hooks/rules-of-hooks */
import { ErrorAPI, ErrorValidation, InterfaceAsUndefined, ObjectFieldValidation, Offering, OfferingOrphan, OfferingTaxCode, OfferingType, RateInterval, TaxType, uuid } from "@bookie/glossary";
import { useIdentity } from "@bookie/module-identity";
import React from "react";
import { useCoreAPI } from "../use-core-api";
import { useCore } from "../use-core";
import { getCountryFromLocale } from "../fns/get-country-from-locale";
import { useValidator } from "@bookie/utils";
import { useNotification } from "@bookie/components";
import { validateOffering } from "../fns/validate-offering";

export const useOfferingEditor = (
  offeringId?: uuid | "create" | "seed",
  offeringEditor?: IOfferingEditorAPI,
  seed?: InterfaceAsUndefined<Offering>
): IOfferingEditorAPI => {

  if (offeringEditor) {
    return offeringEditor;
  }

  const n = useNotification();
  const { ownership } = useIdentity();
  const api = useCoreAPI();
  const { offerings } = useCore();

  // Offering
  // These properties are properties
  // required to create a new Offering 

  const [ name, setName ] = React.useState<string>();
  const [ description, setDescription ] = React.useState<string>();
  const [ currencyCode, setCurrencyCode ] = React.useState<string>();
  const [ sku, setSku ] = React.useState<string>();
  const [ type, setType ] = React.useState<OfferingType>();
  const [ grossCost, setGrossCost ] = React.useState<number>();
  const [ grossSale, setGrossSale ] = React.useState<number>();
  const [ taxCodes, setTaxCodes ] = React.useState<OfferingTaxCode[]>();
  const [ taxType, setTaxType ] = React.useState<TaxType | undefined>("inclusive");
  const [ rateInterval, setRateInterval ] = React.useState<RateInterval>();

  // And these are post-create properties 

  const [ id, setId ] = React.useState<string>();
  const [ createdAt, setCreatedAt ] = React.useState<string>();
  const [ createdBy, setCreatedBy ] = React.useState<string>();

  // States.
  // Explain.

  const [ isUpdatingOffering, setIsUpdatingOffering ] = React.useState<boolean>(false);
  const [ isCreatingOffering, setIsCreatingOffering ] = React.useState<boolean>(false);
  const [ isDeletingOffering, setIsDeletingOffering ] = React.useState<boolean>(false);

  const [ isReady, setIsReady ] = React.useState<boolean>(false);

  React.useEffect(() => {

    // If the ID is set to "create"
    // then it implies we are creating a new 
    // entity. 

    // Over here all we need to do is initialise
    // with any necessary defaults.

    if (offeringId === "create") {
      if (!ownership.current?.entity?.countryCode) {
        setCurrencyCode(getCountryFromLocale(navigator.language)?.currency);
      } else {
        setCurrencyCode(ownership.current?.entity?.currencyCode);
      }
      setIsReady(true);
    }

    else if (offeringId === "seed" && seed) {
      setName(seed.name);
      setDescription(seed.description);
      setCurrencyCode(seed.currencyCode);
      setType(seed.type);
      setSku(seed.sku);
      setGrossCost(seed.grossCost);
      setGrossSale(seed.grossSale);
      setTaxType(seed.taxType);
      setTaxCodes(seed.taxCodes);
      setRateInterval(seed.rateInterval);
      setIsReady(true);
    }

    // If we've got an actual ID
    // then we will need to fetch the
    // full entity object,
    // including any joins.

    else if (typeof offeringId === "string") {
      (async () => {

        const _offering = offerings.all?.find(o => o.id === offeringId);

        if (!_offering) {
          return;
        }

        // We'll save all to context store
        // so that it can be easily used for 
        // the rest of the logic.

        setName(_offering.name);
        setDescription(_offering.description);
        setCurrencyCode(_offering.currencyCode);
        setType(_offering.type);
        setSku(_offering.sku);
        setGrossCost(_offering.grossCost);
        setGrossSale(_offering.grossSale);
        setTaxCodes(_offering.taxCodes);
        setTaxType(_offering.taxType);
        setRateInterval(_offering.rateInterval);

        setId(_offering.id);
        setCreatedAt(_offering.createdAt);
        setCreatedBy(_offering.createdBy);

        setIsReady(true);

      })();
    }

  }, [
    offerings.all, 
    offeringId, 
    ownership, 
    api, 
    seed
  ]);

  const {
    validate,
    isVirgin,
    setDirty,
    validationErrors,
    setValidationErrors
  } = useValidator(
    (_o) => {
      const _validation = validateOffering(_o);
      setValidationErrors(_validation.errors);
      return _validation.isValid;
    },
    {
      name,
      description,
      currencyCode,
      type,
      taxType,
      sku,
      grossCost,
      grossSale,
      taxCodes,
      rateInterval
    },
    undefined,
    isReady
  )

  return {

    data: {
      name,
      description,
      currencyCode,
      type,
      sku,
      grossCost,
      grossSale,
      taxCodes,
      rateInterval,
      taxType,
      id,
      createdAt,
      createdBy
    },

    edit: {
      name: setName,
      description: setDescription,
      currencyCode: setCurrencyCode,
      type: setType,
      taxType: setTaxType,
      sku: setSku,
      grossCost: setGrossCost,
      grossSale: setGrossSale,
      taxCodes: setTaxCodes,
      rateInterval: setRateInterval
    },

    state: {
      isWorking: (
        isUpdatingOffering ||
        isCreatingOffering ||
        isDeletingOffering
      ),
      isUpdatingOffering,
      isCreatingOffering,
      isDeletingOffering,
      isVirgin
    },

    validate,

    delete: async () => {
      if (offeringId) {
        try {
          setIsDeletingOffering(true);
          const { offering } = await api.deleteOffering(offeringId);
          n.notify({ type: "success", message: `Deleted ${ offering.name }` });
          setIsDeletingOffering(false);
          offerings.api?.remove(offering.id);
          return true;
        } catch (e) {
          setIsDeletingOffering(false);
          if (e instanceof ErrorAPI) {
            n.notify({ type: "error", message: e.error });
          }
        }
      }
      return false;
    },

    commit: async () => {

      setDirty();

      const canCommit = validate();
      if (!canCommit) throw new ErrorValidation({ name: "offering" });

      const _offering = {
        name,
        description,
        type,
        currencyCode,
        sku,
        grossCost: grossCost || undefined,
        grossSale,
        taxCodes,
        rateInterval,
        taxType,
        id
      };

      try {

        if (offeringId === "create" && !isVirgin) {
          setIsCreatingOffering(true);
          const { offering } = await api.createOffering(_offering as OfferingOrphan);
          setIsCreatingOffering(false);
          n.notify({ type: "success", message: `Created ${ offering.name }` });
          offerings.api?.sync(offering);
          return offering;
        }

        else if (offeringId && !isVirgin) {
          setIsUpdatingOffering(true); 
          const { offering } = await api.updateOffering(_offering as Offering);
          setIsUpdatingOffering(false);
          n.notify({ type: "success", message: `Updated ${ offering.name }` });
          offerings.api?.sync(offering);
          return offering;
        }

      } catch (e) {
        setIsCreatingOffering(false);
        if (e instanceof ErrorAPI) {
          n.notify({ type: "error", message: e.error });
        }
      }

      return false;

    },

    errors: {
      setDirty,
      validation: validationErrors,
      setValidation: setValidationErrors
    }

  }

}

export interface IOfferingEditorAPI {
  data: OfferingOrphan,
  edit: {
    name: (v: string) => void,
    description: (v: string) => void,
    currencyCode: (v: string) => void,
    type: (v: OfferingType) => void,
    sku: (v: string) => void,
    grossCost: (v: number) => void,
    grossSale: (v: number) => void,
    taxCodes: (c: OfferingTaxCode[]) => void,
    taxType: (v: TaxType) => void,
    rateInterval: (v: RateInterval) => void
  },
  state: (
    IOfferingEditorState
  ),
  validate: () => boolean,
  delete: () => Promise<boolean>,
  commit: () => Promise<Offering | false>,
  errors: {
    setDirty: () => void,
    validation?: ObjectFieldValidation,
    setValidation: (v: ObjectFieldValidation) => void 
  }
}

export interface IOfferingEditorState {
  isWorking: boolean,
  isVirgin: boolean,
  isUpdatingOffering: boolean,
  isDeletingOffering: boolean,
  isCreatingOffering: boolean
}