import React from "react";
import { TransactionExpanded, TransactionExpandedSegment, TransactionType, uuid } from "@bookie/glossary";
import { TableX } from "@bookie/components";
import { getEntity, getLocaleFromCountryCode, useCore } from "@bookie/module-core";
import { useTimezone } from "@hny_/timezone";
import { useCurrency } from "@hny_/currency";
import { useIdentity } from "@bookie/module-identity";
import { generateTransactionTable } from "./tables/generate-transaction-table";
import { generateCurrencyExchangeDiffTable } from "./tables/generate-currency-exchange-diff-table";
import { generateCreditedBadDebtTable } from "./tables/generate-credited-bad-debt-table";
import cx from "classnames";

export const TransactionsReport: React.FC<ITransactionsReport> = (props) => {

  const { ownership } = useIdentity();
  const { entities } = useCore();

  const tz = useTimezone();
  const curr = useCurrency();

  return (
    <div 
      className={ "y space-lines-children" }>
      
      <div 
        className={ "y surface-alt fill-x border-r border-l border-t radius space-lines-children" }>
        
        <TableX
          columns={[
            { 
              header: "Segment",
              renderCell: (segment: TransactionExpandedSegment) => {
                return getEntity(segment.label, entities.all)?.name || segment.label;
              }   
            },
            {
              header: "Expenses",
              align: "right",
              renderCell: (segment: TransactionExpandedSegment) => {

                let expenses = (segment.summary?.accrued?.total || 0) + (segment.summary?.expensesPaid?.total || 0);
                
                if (
                  segment.type === "credited-bad-credit"
                ) {
                  expenses = segment.summary?.all?.total || 0;
                };

                if (
                  segment.type === "currency-exchange-diff"
                ) {
                  expenses = segment.summary?.all?.total || 0;
                };

                return (
                  <span 
                    style={{ 
                      padding: "2px 4px", 
                      fontWeight: "bold",
                      fontSize: "14px",
                      border: "none", 
                      color: expenses === 0 ? "grey" : "", 
                      backgroundColor: expenses === 0 ? "transparent" : "" 
                    }}
                    className={ cx(
                      "surface-negative apply-bg apply-fg"
                    ) }>
                    { 
                      curr.toHumanReadable(
                        expenses, 
                        getLocaleFromCountryCode(ownership.current?.entity?.countryCode), 
                        ownership.current?.entity?.currencyCode
                      ) 
                    }
                  </span>
                );
              }
            },
            {
              header: "Revenue",
              align: "right",
              width: "150px",
              renderCell: (segment: TransactionExpandedSegment) => {

                let gross = (segment.summary?.accrued?.total || 0) + (segment.summary?.badDebt?.total || 0)
                
                if (
                  segment.type === "currency-exchange-diff"
                ) {
                  gross = segment.summary?.all?.total || 0;
                };

                return (
                  <span 
                    style={{ 
                      padding: "2px 4px", 
                      fontWeight: "bold",
                      fontSize: "14px",
                      border: "none", 
                      color: gross === 0 ? "grey" : "", 
                      backgroundColor: gross === 0 ? "transparent" : "" 
                    }}
                    className={ cx(
                      "surface-positive apply-bg apply-fg"
                    ) }>
                    { 
                      curr.toHumanReadable(
                        gross, 
                        getLocaleFromCountryCode(ownership.current?.entity?.countryCode), 
                        ownership.current?.entity?.currencyCode
                      ) 
                    }
                  </span>
                );
              }
            }
          ]}
          data={ props.transactionSegments }
          onHighlightRowClassName={ (segment: TransactionExpandedSegment) => "" }
          
          groupData={ (segment: TransactionExpandedSegment) => segment.transactions }
          groupColumns={
            (segment: TransactionExpandedSegment) => {
              if (ownership.current) {
                if (segment.type === "transaction") {
                  return generateTransactionTable(ownership.current, curr, tz);
                } else if (segment.type === "currency-exchange-diff") {
                  return generateCurrencyExchangeDiffTable(ownership.current, curr, tz);
                } else if (segment.type === "credited-bad-credit") {
                  return generateCreditedBadDebtTable(ownership.current, curr, tz);
                }
              }
              return [];
            }
          }
          onHighlightGroupedRowClassName={ (segment: TransactionExpandedSegment, tx: TransactionExpanded) => {
            if (tx.paymentState === "bad_debt") {
              return "surface-reject"
            }
            if (segment.issues?.[tx.id]) {
              return "surface-warning";
            }
            return ""
          } }
          onClickGroupedRow={ (segment: TransactionExpandedSegment, tx: TransactionExpanded) => {
            if (tx.id === props.selectedTransactionId) {
              props.onSelectTransaction?.(undefined);
            } else {
              props.onSelectTransaction?.(tx.id);
            }
          } } />

      </div>
      
    </div>
  );
}

export interface ITransactionsReport {
  transactionSegments?: TransactionExpandedSegment[]
  transactionType?: TransactionType
  selectedTransactionId?: uuid
  onSelectTransaction?: (id?: uuid) => void
};