import React from "react";
import { Button, IconAdd, IconAddress, IconContact, InputImage, InputSelect, InputText, InputTextArea, useNotification, Workbench } from "@bookie/components";
import { Country, EntityType, uuid } from "@bookie/glossary";
import css from "./index.module.css";
import cx from "classnames";
import { IEntityEditorAPI, useEntityEditor } from "../../use/use-entity-editor";
import { SelectCurrency } from "../../components/select-currency";
import { SelectCountry } from "../../components/select-country";
import { AddressEditor } from "../address-editor";
import { getReadableAddress } from "../../fns/get-readable-address";
import { ContactEditor } from "../contact-editor";
import { getLogoUrl } from "../../fns/get-logo-url";
import { useIdentity } from "@bookie/module-identity";
import { EntityEditorContact } from "../entity-editor-contact";
import { SelectEntityRole } from "../../components/select-entity-role";
import { SelectEntityRegistrationType } from "../../components/select-entity-registration-type";

export const EntityEditor: React.FC<IEntityEditor> = (props) => {
  
  const { ownership } = useIdentity();
  const n = useNotification();

  const editor = useEntityEditor(
    props.entityId,
    props.editor,
    undefined,
    {
      entityType: props.entityType, 
      editorType: "expanded"
    }
  );

  const [ contactId, setContactId ] = React.useState<uuid | "create">();
  const [ addressId, setAddressId ] = React.useState<uuid | "create">();

  const isEntityOfTheUser = editor.data.type === "self";
 
  return (
    <div
      style={{ maxWidth: "900px" }}
      className={ "x fill-x border-r surface" }>

      <div 
        className={ cx(
          "y fill-x"
        ) }>
        
        <div 
          className={ "x surface border-b space-blocks-frame-lr space-lines-frame-tb align-x-between" }>
          
          <Button
            label={ "Save" }
            size={ "slim" }
            className={ "surface-button" }
            onClick={ async () => {
              const result = await editor.commit();
              if (result) {
                if (ownership.current?.entity?.id === editor.data.id) {
                  window.location.reload();
                } else {
                  props.onFinish?.();
                }
              }
            } } />
          
          {
            editor.data.type !== "self" && 
              <Button 
                label={ "Delete" }
                size={ "slim" }
                variant={ "dangerous" }
                surface={ "surface-negative" }
                isDangerous={ true }
                onClick={ async () => {
                  const result = await editor.delete();
                  result && props.onFinish?.();
                } } />
          }

        </div>

        <div 
          className={ "x fill" }>
          
          <div className={ "y fill" }>
            <div 
              style={{ height: "0px" }}
              className={ "y fill surface scrollable" }>
              
              <div 
                id={ "legal-registration" }
                className={ cx(
                  "surface border-b space-blocks-frame space-blocks-children y",
                  css.form_fullWidth
                ) }>
                
                <span className={ "surface-base surface-heading" }>{ "Legal Registration" }</span>
              
                {
                  !isEntityOfTheUser && editor.data.type === "client" &&
                    <SelectEntityRole
                      onSelect={ e => editor.edit.role(e.id) }
                      value={ editor.data.role } />
                }

                {
                  (
                    (editor.data.type === "client" && editor.data.role === "business") ||
                    (editor.data.type === "merchant" || editor.data.type === "self") 
                  ) && 
                    <SelectEntityRegistrationType
                      label={ "Business Type" }
                      value={ editor.data.registrationType }
                      onSelect={ (v) => editor.edit.registrationType(v.id) } />
                }
                
                {
                  editor.data.registrationType === "business" &&
                    <InputText
                      type={ "text" }
                      className={ "fill-inputs" }
                      label={ "Business Name" }
                      placeholder={ "Bookie" }
                      value={ editor.data.name }
                      onChange={ v => editor.edit.name(v as string) }
                      error={ editor.errors.validation?.["name"] } /> 
                }

                {
                  editor.data.registrationType === "individual" && 
                    <div 
                      className={ "x space-lines-children" }>

                      <InputText
                        type={ "text" }
                        className={ "fill-inputs" }
                        label={ "First Name" }
                        placeholder={ "Bookie" }
                        value={  editor.related.contactEditor.data.nameGiven }
                        onChange={ v => {
                          editor.related.contactEditor.edit.nameGiven(v as string);
                          editor.edit.name(`${ v as string } ${ editor.related.contactEditor.data.nameFamily }`);
                        } }
                        error={ editor.errors.validation?.["name"] } /> 
                      
                      <InputText
                        type={ "text" }
                        className={ "fill-inputs" }
                        label={ "Last Name" }
                        placeholder={ "Bookerton" }
                        value={ editor.related.contactEditor.data.nameFamily }
                        onChange={ v => {
                          editor.related.contactEditor.edit.nameFamily(v as string);
                          editor.edit.name(`${ editor.related.contactEditor.data.nameGiven } ${ v as string }`);
                        } }
                        error={ editor.errors.validation?.["name"] } /> 

                    </div>
                }
                
                <InputText
                  type={ "text" }
                  label={ "Tax Identification Number" }
                  value={ editor.data.tin }
                  onChange={ v => editor.edit.tin(v as string) }
                  className={ "fill-x fill-inputs" } />
                
                {
                  !isEntityOfTheUser && 
                    <InputSelect 
                      label={ "Type" }
                      formatLabel={ e => e.label }
                      matchKey={ "id" }
                      options={[
                        {
                          id: "client",
                          label: "Client"
                        },
                        {
                          id: "merchant",
                          label: "Merchant"
                        }
                      ]}
                      onSelect={ e => editor.edit.type(e.id) }
                      value={ [
                        {
                          id: "client",
                          label: "Client"
                        },
                        {
                          id: "merchant",
                          label: "Merchant"
                        }
                      ].find(x => x.id === editor.data.type)  } />
                }

                {
                  isEntityOfTheUser && 
                    <InputTextArea
                      style={{
                        "--hny-input-w": "100%" 
                      } as React.CSSProperties}
                      label={ "Business Description" }
                      description={ "Tell us what you or your business does. Remember, the more description you are the more our AI can serve you." }
                      value={ editor.data.businessDescription }
                      backgroundType={ "fill" }
                      onChange={ v => editor.edit.businessDescription(v as string) } />

                }

              </div>

              <div 
                id={ "display" }
                className={ "surface border-b space-blocks-frame space-blocks-children y fill-inputs" }>

                <span className={ "surface-base surface-heading" }>{ "Display" }</span>
                
                <InputText
                  type={ "text" }
                  label={ "Nickname" }
                  value={ editor.data.nickname }
                  onChange={ v => editor.edit.nickname(v as string) } />
                
                {
                  ownership.current?.entity?.id === editor.data.id && 
                    <div
                      className={ "x space-cubes-children" }>

                      <InputImage
                        type={ "text" }
                        label={ "Logo" }
                        accept={ ".jpg,.jpeg,.png,image/jpeg,image/png" }
                        description={ "Max. file size is 800kb." }
                        onMaxFileSize={ () => n.notify({ type: "error", message: "This image you tried to upload is larger then 800kb. Please use a smaller image." }) }
                        onUnsupportedFileType={ () => n.notify({ type: "error", message: "This image you tried to upload is has an unsupported format. Please use .jpg or .png" }) }
                        currentImageUrl={ editor.data.logoUrl ? getLogoUrl(editor.data.logoUrl) : undefined }
                        value={ editor.meta.logoFile }
                        onChange={ v => {
                          if (!v) {
                            editor.edit.logoUrl("");
                          } else {
                            editor.edit.logoFile(v as File)
                          }
                        } } />
                    
                    </div>
                }

              </div>

              <div
                id={ "locale" }
                className={ "surface border-b space-blocks-frame space-blocks-children y" }>
                
                <span className={ "surface-base surface-heading" }>{ "Locale" }</span>

                <SelectCountry
                  label={ "Country" }
                  selectedCountryCode={ editor.data.countryCode }
                  onSelect={ (c: Country) => editor.edit.countryCode(c.alpha3) } />

                <SelectCurrency
                  label={ "Currency" }
                  showName={ true }
                  selectedCurrencyCode={ editor.data.currencyCode }
                  onSelect={ c => editor.edit.currencyCode(c.code) } />

              </div>

              <div 
                id={ "contact" }
                className={ "surface border-b space-blocks-frame space-blocks-children y" }>
                
                <span className={ "surface-base surface-heading" }>{ "Contact" }</span>

                <EntityEditorContact
                  editor={ editor }
                  renderBare={ true } />

              </div>

              <div
                id={ "primary-address" }
                className={ "surface border-b space-blocks-frame space-blocks-children y" }>
                
                <div 
                  className={ "x align-x-between align-y-center" }>
                  <span className={ "surface-base surface-heading" }>{ "Primary Business Address" }</span>
                  {
                    (!editor.data.primaryAddress) && 
                      <Button
                        variant={ "text" }
                        icon={ <IconAdd /> }
                        onClick={ () => {
                          contactId && setContactId(undefined);
                          setAddressId("create");
                        } } />
                  }
                </div>
                
                {
                  (editor.data.primaryAddress || !editor.related.addressEditor.state.isVirgin) && 
                    <Button 
                      variant={ "inline" }
                      icon={ <IconAddress /> }
                      label={ getReadableAddress(editor.related.addressEditor.data) }
                      onClick={ () => {
                        contactId && setContactId(undefined);
                        setAddressId(editor.related.addressEditor.data.id || "create");
                      } } />
                }

              </div>

              
              <div 
                id={ "primary-contact" }
                className={ "surface border-b space-blocks-frame space-blocks-children y" }>

                <div 
                  className={ "x align-x-between align-y-center" }>
                  <span className={ "surface-base surface-heading" }>{ "Primary Contact" }</span>
                  {
                    (!editor.related.contactEditor.data.nameGiven) && 
                      <Button
                        variant={ "text" }
                        icon={ <IconAdd /> }
                        onClick={ () => {
                          addressId && setAddressId(undefined);
                          setContactId("create");
                        } } />
                  }
                </div>

                {
                  (editor.data.primaryContact || !editor.related.contactEditor.state.isVirgin) && 
                    <Button 
                      variant={ "inline" }
                      icon={ <IconContact /> }
                      label={ `${ editor.related.contactEditor.data?.nameGiven } ${ editor.related.contactEditor.data?.nameFamily }` }
                      onClick={ () => {
                        addressId && setAddressId(undefined);
                        setContactId(editor.related.contactEditor.data.id);
                      } } />
                }

              </div> 
             

            </div>
          </div>

          <Workbench
            isOpen={ typeof contactId !== "undefined" || typeof addressId !== "undefined" }
            onClose={ () => {
              if (contactId) setContactId(undefined);
              if (addressId) setAddressId(undefined);
            } }>
            {
              contactId && 
                <ContactEditor
                  contactId={ contactId }
                  editor={ editor.related.contactEditor }
                  onFinish={ () => setContactId(undefined) } />
            }
            {
              addressId &&
                <AddressEditor
                  addressId={ addressId }
                  editor={ editor.related.addressEditor }
                  onFinish={ () => setAddressId(undefined) } />
            }
          </Workbench>
        
        </div>

      </div>

    </div>
  );
}

export interface IEntityEditor {
  entityId: string | "create"
  editor?: IEntityEditorAPI
  entityType?: EntityType
  onFinish?: () => void
}