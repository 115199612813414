import { IllustrationProduct, IllustrationService } from "@bookie/images";

export const OFFERING_TYPES: OfferingTypeDefinition[] = [
  {
    label: "Product",
    id: "physical_product",
    image: IllustrationProduct 
  },
  {
    label: "Service",
    id: "service",
    image: IllustrationService
  }
];

export interface OfferingTypeDefinition {
  id: string
  label: string
  image?: string
}

// {
//   label: "Experience",
//   id: "experience"
// },
// {
//   label: "Intellectual Property",
//   id: "intellectual_property"
// },
// {
//   label: "Financial Product",
//   id: "financial_product"
// },
// {
//   label: "Membership",
//   id: "membership"
// }