import { OfferingTaxCode, TaxCode, TransactionExpanded, TransactionOffering, TransactionSummary } from "@bookie/glossary";
import { summariseTransactionOffering } from "./summarise-transaction-offering";
import { getTaxFromCodes } from "@bookie/module-tax-suites";

export const summariseTransaction = (
  transaction: TransactionExpanded,
  getTaxCodes?: (taxCodes: OfferingTaxCode[]) => TaxCode[] | undefined
): TransactionSummary => {

  const exchangeRate = transaction.invoiceExchangeRate || transaction.estimateExchangeRate || 1;
  
  return (
    (transaction.offerings || []).reduce(
      (summary: TransactionSummary, o: TransactionOffering) => {

        const taxCodes = getTaxCodes?.(o.taxCodes || []);
        const tax = getTaxFromCodes(taxCodes || []);

        const _summary = summariseTransactionOffering(
          o.grossSale,
          o.quantity,
          Math.abs(tax),
          o.taxType
        );
  
        return ({
          
          subTotal: summary.subTotal + _summary.subTotal,
          tax: summary.tax + _summary.tax,
          total: summary.total + _summary.total,

          // If the transaction's currency 
          // is in a different one to the local. 
  
          localised: (
            exchangeRate !== 1
              ? (
                {
                  subTotal: summary.subTotal + Math.round(_summary.subTotal * exchangeRate),
                  tax: summary.tax + Math.round(_summary.tax * exchangeRate),
                  total: summary.total + Math.round(_summary.total * exchangeRate)
                }
              )
              
              // If there's no exchange rate we can assume 
              // that the transaction is already localised
              // so we will copy the values. 
              
              // We will always want to return a value 
              // in localised so it will be accessible
              // from one location.  
              
              : (
                {
                  subTotal: summary.subTotal + _summary.subTotal,
                  tax: summary.tax + _summary.tax,
                  total: summary.total + _summary.total
                }
              )
          )
  
        });
  
      },
      {
        subTotal: 0,
        tax: 0,
        total: 0
      }
    )
  );

}