import React from "react";
import { getCountry } from "@bookie/module-core";
import { useTaxSuites } from "../../";
import { TaxCode } from "../../components/tax-code";
import { IconDomestic, IconInternational, Button } from "@bookie/components";
import { useSupabase } from "@bookie/supabase";

export const MyTaxSuite: React.FC = () => {
  
  const { supabase, fns } = useSupabase();

  const { suite } = useTaxSuites();

  const salesCodes = suite?.salesTax.codes?.filter(c => c.type === "income");
  const purchaseCodes = suite?.salesTax.codes?.filter(c => c.type === "expense");

  const country = getCountry(suite?.countryCode);

  return (
    <div
      style={{ height: "fit-content" }}
      className={ "x surface-alt fill-x align-x-center space-blocks-frame" }>
        <div
          style={{ width: "50vw", maxWidth: "1100px", height: "100%" }}
          className={ "y space-blocks-children" }>

            {/* Description */}

            <div 
              className={ "surface apply-bg apply-fg border radius space-lines-frame y space-inline-children" }>
              
              <p 
                className={ "surface-info" }>
                { "Based on your location, you fall under the following Tax Suite" }
              </p>

              <div 
                className={ "x space-inline-children align-y-center" }>
                <p>{ country?.emoji }</p>
                <p><b>{ country?.name }</b></p>
                <p>({ country?.currency })</p>
              </div>

            </div>

            {/* Sales Tax */}

            <div
              className={ "surface apply-bg apply-fg border radius" }>
              
              <div 
                className={ "y space-blocks-children" }>
                
                <div 
                  className={ "space-lines-frame space-blocks-children y" }>
                
                  <div
                    className={ "y space-inline-children" }>
                    <h3>{ "Sales Tax" }</h3>
                    <h1>{ `${ suite?.salesTax.label } (${ suite?.salesTax.shorthand })` }</h1> 
                  </div>
                  
                  {
                    suite?.salesTax.description && 
                      <p>{ suite?.salesTax.description }</p>
                  }

                  <Button 
                    label={ "Ask Bookie" }
                    onClick={ async () => {
                      const x = await supabase.functions.invoke(
                        fns.inferTaxCodes,
                        { 
                          body: {
                            baseCountry: "Malta",
                            targetCountry: "Germany",
                            customerType: "Business",
                            businessDescription: "I sell software development services",
                            transactionType: "buying",
                            transactionOffering: {
                              name: "Adobe Creative Suite",
                              type: "digital_product",
                              currencyCode: "EUR",
                              grossSale: "€50",
                              quantity: "1",
                              tax: "exempt"
                            }
                          } 
                        }
                      )
                      const taxCodes = JSON.parse(x.data);
                      console.log(taxCodes);
                    } } />

                </div>

                {
                  suite?.salesTax.codes && 
                    <div 
                      className={ "y space-blocks-children" }>
                      
                      <div 
                        className={ "y space-blocks-children space-lines-frame border-t border-b surface-aside apply-bg apply-fg" }>
                        
                        <div 
                          className={ "x align-y-center space-inlinez-children" }>
                          <IconDomestic size={ 20 } />
                          <h2>{ "Domestic Tax Codes" }</h2>
                        </div>

                        <div 
                          className={ "y space-inlinez-children" }>
                          <h3>{ "Sales" }</h3>
                          <div 
                            className={ "y space-lines-children" }>
                            <div 
                              className={ "y fill-x space-inlinez-children" }>
                              {
                                salesCodes
                                  ?.filter(c => c.scope === "domestic")
                                  ?.map(code => {
                                  return (
                                    <TaxCode
                                      code={ code } />
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>

                        <div 
                          className={ "y space-inlinez-children" }>
                          <h3>{ "Purchases" }</h3>
                          <div 
                            className={ "y space-lines-children" }>
                            <div 
                              className={ "y fill-x space-inlinez-children" }>
                              {
                                purchaseCodes
                                  ?.filter(c => c.scope === "domestic")
                                  ?.map(code => {
                                  return (
                                    <TaxCode
                                      code={ code } />
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>

                      </div>

                      <div 
                        className={ "y space-blocks-children space-lines-frame border-t border-b surface-aside apply-bg apply-fg" }>

                        <div 
                          className={ "x align-y-center space-inlinez-children" }>
                          <IconInternational size={ 20 } />
                          <h2>{ "International Tax Codes" }</h2>
                        </div>

                        <div 
                          className={ "y space-inlinez-children" }>
                          <h3>{ "Sales" }</h3>
                          <div 
                            className={ "y space-lines-children" }>
                            <div 
                              className={ "y fill-x space-inlinez-children" }>
                              {
                                salesCodes
                                  ?.filter(c => c.scope === "international")
                                  ?.map(code => {
                                  return (
                                    <TaxCode
                                      code={ code } />
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>

                        <div 
                          className={ "y space-inlinez-children" }>
                          <h3>{ "Purchases" }</h3>
                          <div 
                            className={ "y space-lines-children" }>
                            <div 
                              className={ "y fill-x space-inlinez-children" }>
                              {
                                purchaseCodes
                                  ?.filter(c => c.scope === "international")
                                  ?.map(code => {
                                  return (
                                    <TaxCode
                                      code={ code } />
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                }

              </div>

            </div>


        </div>
    </div>
  );
}