import { Entity, EntityOrphan } from "../entity"
import { TableBase, uuid, timestamptz, Tenancy, InterfaceAsUndefined } from "../global"
import { TransactionOffering, TransactionOfferingOrphan } from "./transaction-offerings"
import { Payment, PaymentOrphan } from "../payment"
import { IMoney } from "../currency"
import { Address } from "../address"

export * from "./transaction-offerings"
export * from "./transaction-repeat"

export interface Transaction extends TableBase, Tenancy {
  
  sourceId: uuid
  destinationId: uuid
  description: string
  notes?: string

  currencyCode: string

  isManuallyLocalised?: boolean
  isManuallyCalculated?: boolean
  
  estimateState?: EstimateState
  estimateDate?: timestamptz
  estimateExpiration?: timestamptz
  estimateExchangeRate?: number

  invoiceState?: InvoiceState
  invoiceDate?: timestamptz
  invoiceDue?: timestamptz
  invoiceShippingAddressId?: uuid
  invoiceUrl?: string
  invoiceExchangeRate?: number
  
  deferredDate?: timestamptz
  deferredReason?: string

}

export type TransactionOrphan = InterfaceAsUndefined<Transaction>

export interface TransactionSummary {
  total: number,
  subTotal: number,
  tax: number,
  localised?: Omit<TransactionSummary, "localised">
}

export interface TransactionsSummary {
  all?: TransactionSummary
  accrued?: TransactionSummary
  expensesPaid?: TransactionSummary
  expensesDue?: TransactionSummary
  cash?: TransactionSummary
  badDebt?: TransactionSummary
  invoicePending?: TransactionSummary
  estimatePending?: TransactionSummary
}

export interface TransactionExpanded extends Transaction {
  sourceEntity?: Entity
  destinationEntity?: Entity 
  offerings?: TransactionOffering[]
  payments?: Payment[]
  newEntity?: EntityOrphan
  shippingAddress?: Address
  summary?: TransactionSummary
  paymentState?: PaymentState
  type?: TransactionType
}

// TODO I want to delete this.
export interface TransactionSeed {
  transaction?: TransactionOrphan
  transactionOfferings?: InterfaceAsUndefined<TransactionOfferingOrphan>[]
  transactionPayments?: PaymentOrphan[]
  entity?: InterfaceAsUndefined<EntityOrphan>
}

// TODO 
// I want to combine these two interfaces

export interface TransactionSegment {
  label?: string
  transactions: Transaction[]
  value?: IMoney // TODO I want to delete this
  issues?: TransactionIssue
}

export interface TransactionExpandedSegment {
  label?: string
  transactions: TransactionExpanded[]
  value?: IMoney // TODO I want to delete this
  summary?: TransactionsSummary
  issues?: TransactionIssue
  type?: TransactionSegmentType
}

export interface TransactionSegmentGroup {
  label?: string 
  segments: TransactionExpandedSegment[]
  summary: TransactionsSummary
}

export interface TransactionIssue {
  [uuid: string]: string // If there are issues, then it is probable that the value is incomplete (ex: no offerings = no value to transaction)
}

export type TransactionSegmentType = 
  "transaction" |
  "currency-exchange-diff" |
  "credited-bad-credit" |
  "income-costs";

export type TransactionType =
  "income" |
  "expense" |
  "unknown" |
  "unspecified"

export type TransactionFilterPeriods = 
  "today" |
  "this_month" |
  "this_year" |
  "last_year" |
  "all_time";

export type TransactionSegments = 
  "segment_by_month" |
  "segment_by_entity";

export type TransactionStage = 
  "estimate" |
  "invoice" |
  "payment";

export type EstimateState = 
  "draft" | 
  "submitted" |
  "approved" | 
  "rejected" |
  "n/a";

export type InvoiceState = 
  "draft" | 
  "submitted" | 
  "rejected"; // implies bad debt

export type PaymentState = 
  "due" | 
  "overdue" | 
  "paid_in_full" | 
  "paid_in_part" |
  "bad_debt";

export type TransactionDocumentType = 
  "estimate" | 
  "invoice" |
  "receipt";

export type TaxType = 
  "inclusive" | 
  "additive"

export const SUMMARY: TransactionSummary = {
  subTotal: 0,
  tax: 0,
  total: 0
};

export const SUMMARIES: TransactionsSummary = {
  all: SUMMARY,
  accrued: SUMMARY,
  cash: SUMMARY,
  invoicePending: SUMMARY,
  estimatePending: SUMMARY
}