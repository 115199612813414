import { TaxSetPeriod } from "@bookie/glossary";
import { isWithinInterval } from "date-fns";

export const getCurrentPeriod = (periods: TaxSetPeriod[], currentDate: Date = new Date()): TaxSetPeriod | null => {
  for (const period of periods) {
    const startDate = new Date(period.from);
    const endDate = new Date(period.to);
    if (isWithinInterval(currentDate, { start: startDate, end: endDate })) {
      return period;
    }
  }
  return null;
};
