import React from "react";
import { SelectEntities } from "../../../../module-core/src/components/select-entities";
import { hydrateEntityType } from "../../../../module-core/src/fns/hydrate-entity-type";
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import { DisplayAddress, DisplayContact, IEntityEditorAPI } from "@bookie/module-core";
import { Button, IconEdit, IconRightAccordian } from "@bookie/components";
import cx from "classnames";

export const TransactionEntityEditor: React.FC<ITransactionEntityEditor> = (props) => {
;
  const entityType = hydrateEntityType(props.editor?.related.entityEditor.data.type);
  
  const sourceOrDestination = (
    props.editor?.type === "income"
      ? "sourceId"
      : "destinationId"
  );

  return (
    <div
      style={ props.style }
      className={ cx(
        "y space-inline-children",
        { "align-x-start": props.align === "left" },
        { "align-x-end": props.align === "right" }
      ) }>
      
      <SelectEntities
        entityType={ entityType.id }
        label={ entityType.label }
        align={ props.align }
        optionsAlign={ props.editor?.type === "expense" ? "left" : "right" }
        placeholder={ `Choose your ${ entityType.label } or create a new one` }
        actions={[
          {
            label: "Create",
            onClick: () => {
              props.editor?.trigger.createNewEntity(true);
              props.onEditEntity?.()
            }
          }
        ]}
        selectedEntityId={ props.editor?.data[sourceOrDestination] }
        selectedNewEntity={(
          props.editor?.state.isCreatingNewEntity 
            ? props.editor?.related.entityEditor.data
            : undefined
        )}
        onSelect={ (e) => {
          props.editor?.edit[sourceOrDestination](e.id);
          props.editor?.trigger.createNewEntity(false);
        } }
        error={ props.editor?.errors.validation?.[sourceOrDestination] } />
      
      {
          props.editor?.related.entityEditor.data.name &&
            <>

              {
                props.editor?.state.isCreatingNewEntity && 
                  <>
                    
                    {
                      props.isEditingEntity && 
                        <Button
                          size={ "slim" }
                          variant={ "inline" }
                          style={{ padding: "0", height: "auto" }}
                          label={ "Close New Client" }
                          icon={ <IconRightAccordian /> }
                          iconSize={ 14 }
                          surface={ "surface-button-inline" }
                          onClick={ props.onFinishEditingEntity } />
                    }

                    {
                      !props.isEditingEntity && 
                        <Button
                          size={ "slim" }
                          variant={ "inline" }
                          className={ props.isEditing && !props.isEditingEntity ? "disabled" : "" }
                          style={{ padding: "0", height: "auto" }}
                          label={ "Edit" }
                          icon={ <IconEdit weight={ "regular" } /> }
                          iconSize={ 14 }
                          surface={ "surface-button-inline" }
                          onClick={ props.onEditEntity } />
                    }
                  </>
              }

              <DisplayAddress
                className={ "y" }
                isEditing={ props.isEditing }
                address={ props.editor?.related.entityEditor.related.addressEditor.data }
                onEditAddress={ props.onEditEntityAddress }
                isEditingAddress={ props.isEditingEntityAddress }
                onFinishEditingAddress={ props.onFinishEditingEntityAddress } />

              <DisplayContact
                className={ "y" }
                isEditing={ props.isEditing }
                entity={ props.editor?.related.entityEditor.data }
                onEditContact={ props.onEditEntityContact }
                isEditingContact={ props.isEditingEntityContact }
                onFinishEditingContact={ props.onFinishEditingEntityContact } />

            </> 
        }

    </div>
  );
}

export interface ITransactionEntityEditor {

  align?: "left" | "right"
  style?: { [key: string]: string }

  editor?: ITransactionEditorAPI

  // TODO extends WorkbenchStates interface
  isEditing?: boolean
  isEditingEntity?: boolean
  onEditEntity?: () => void
  onFinishEditingEntity?: () => void
  onEditEntityAddress?: () => void 
  isEditingEntityAddress?: boolean 
  onFinishEditingEntityAddress?: () => void 
  onEditEntityContact?: () => void 
  isEditingEntityContact?: boolean 
  onFinishEditingEntityContact?: () => void

}