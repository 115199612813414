import React from "react";
import { IUseEntitiesAPI, useEntities } from "./_use/use-entities";
import { Entity, Offering } from "@bookie/glossary";
import { IUseOfferingsAPI, useOfferings } from "./_use/use-offerings";
import { useIdentity } from "@bookie/module-identity";
import { GetCollectionRequest, Onboarding } from "./views/onboarding";

const CoreCtx = React.createContext<CoreAPI | undefined>(undefined);

const CoreCtxProvider: React.FC<ICoreCtxProvider> = (props) => {

  const entities = useEntities();
  const offerings = useOfferings();

  const { status } = useIdentity();

  return (
    <CoreCtx.Provider
      value={{
        entities: {
          all: entities.entities,
          clients: entities.clients,
          merchants: entities.merchants,
          uncategorised: entities.uncategorised,
          api: entities.api
        },
        offerings: {
          all: offerings.offerings,
          api: offerings.api
        }
      }}>
      
      {
        status.requiresOnboarding 
          ? <Onboarding getCollectionRequest={ props.getCollectionRequestForOnboarding } />
          : props.children
      }
      
    </CoreCtx.Provider>
  );
}

export interface ICoreCtxProvider {
  children?: React.ReactNode
  getCollectionRequestForOnboarding?: GetCollectionRequest
}

const useCore = (): CoreAPI => {
  const value = React.useContext(CoreCtx);
  if (value === undefined) {
    throw new Error("Missing CoreCtxProvider");
  }
  return value;
}

export { CoreCtxProvider, useCore }

interface CoreAPI {
  entities: {
    all?: Entity[]
    clients?: Entity[]
    merchants?: Entity[]
    uncategorised?: Entity[],
    api: IUseEntitiesAPI
  },
  offerings: {
    all?: Offering[],
    api?: IUseOfferingsAPI
  }
}