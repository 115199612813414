import { TaxSetIncome, TransactionOffering } from "@bookie/glossary";

export const IncomePersonalTax: TaxSetIncome = {

  type: "personal",
  label: "Personal Income Tax (Set)",
  shorthand: "INC",
  
  calculateCurrentYear: () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    return currentYear;
  },

  calculatePeriods: (year?: number) => {
    return [];
  },

  inferTaxCode: (to: TransactionOffering) => []
}