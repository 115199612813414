import { ObjectFieldValidation, OFFERING_TYPES, RATE_INTERVALS, TransactionOffering, TransactionOfferingOrphan, TransactionType } from "@bookie/glossary";
import { Amount, Button, IconAI, IconDelete, IconDescription, IconDownAccordian, IconEdit, IconName, IconOfferingCost, IconOfferingPrice, IconQuantity, IconSKU, IconUpAccordian, IconValidation, IconWarning, InputCheckbox, InputMoney, InputNumber, InputSelect, InputText, Tooltip } from "@bookie/components";
import React from "react";
import cx from "classnames";
import { useCurrency } from "@hny_/currency";
import { useIdentity } from "@bookie/module-identity";
import { getLocaleFromCountryCode, SelectOfferingRateInterval } from "@bookie/module-core";
import { summariseTransactionOffering } from "../../fns/summarise-transaction-offering";
import { getTaxFromCodes, InferTaxCodes, TaxCodeTag, useTaxSuites } from "@bookie/module-tax-suites";
import { capitaliseFirstLetter } from "@bookie/utils";

export const TransactionOfferingEditor: React.FC<ITransactionOfferingEditor> = (props) => {

  const currency = useCurrency();
  const { ownership } = useIdentity();
  const { getTaxCodes } = useTaxSuites();

  const locale = getLocaleFromCountryCode(ownership.current?.entity?.countryCode);

  const hasValidation = Object.keys(props.errors || {}).length > 0;

  const [ addDescription, setAddDescription ] = React.useState<boolean>(false);
  const [ addSKU, setAddSKU ] = React.useState<boolean>(false);
  const [ addCost, setAddCost ] = React.useState<boolean>(typeof props.offering.grossCost === "string" || (props.offering.grossCost || 0) > 0);

  const offeringAlreadyExists = props.offering.id;

  const offeringType = OFFERING_TYPES.find(ot => ot.id === props.offering.type);
  const rateInterval = RATE_INTERVALS.find(ri => ri.id === props.offering.rateInterval);

  const taxCodes = getTaxCodes(props.offering.taxCodes || []);
  const tax = getTaxFromCodes(taxCodes || []);
  const isOutOfTaxScope = tax === -1;
  const hasTaxCodes = props.offering.taxCodes && props.offering.taxCodes.length > 0;

  const summary = summariseTransactionOffering(
    props.offering.grossSale,
    props.offering.quantity,
    tax,
    props.offering.taxType
  );

  if (!props.isSelected) {
    return (
      <div
        onClick={ props.onSelect }
        style={{ cursor: "pointer" }}
        className={ cx(
          "x apply-fg radius border-t fill-x hvr-icon-down",
          `${ props.surfaceDisplay || "surface-aside" }`
        ) }>
        
        <div
          style={{ width: "6%" }}
          className={ cx(
            "align-y-center x",
            `${ props.surfaceDisplay || "" }`
          ) }>
          <Button
            variant={ "text" }
            className={ "hvr-icon" }
            icon={ <IconDownAccordian /> }
            iconSize={ 14 }
            size={ "slim" } />
        </div>
        
        <div 
          style={{ width: "22%", marginRight: "30px" }}
          className={ "x space-inline-children align-y-center trim-text-ellipsis" }>
          
          <p>{ props.offering.name }</p>
          
          {
            hasValidation && 
              <Tooltip
                className={ "surface-negative" }
                message={ "There are some errors in here" }
                base={ <IconValidation size={ 18 } /> } />
          }
          
        </div>
        
        <div
          style={{ width: "32%" }}
          className={ "x align-y-center" }>
          <p>
            { `${ props.offering.quantity } ${ props.offering.type === "service" ? rateInterval?.nounPlural || "rate" : "units" } x ${ currency.toHumanReadable(props.offering.grossSale || 0, locale, props.currencyCode) } / ${ props.offering.type === "service" ? rateInterval?.noun : "unit" } `}
          </p>
        </div>

        <div
          style={{ width: "12%" }}
          className={ "x align-y-center" }>
          <p>{ tax + "%" }</p>
        </div>

        <div
          style={{ width: "22%" }}
          className={ "x align-y-center align-x-end" }>
          
          <div 
            className={ "space-inlinez-frame" }>
            <p>
              <b>
                { 
                  currency.toHumanReadable(summary.total, locale, props.currencyCode) 
                }
              </b>
            </p>
          </div>
          
        
        </div>

        <div 
          style={{ width: "6%" }}
          className={ cx(
            "border-l align-y-center x",
            `${ props.surfaceDisplay || "" }`
          ) }>
          <Button
            variant={ "text" }
            className={ "hvr-pulse" }
            onClick={ e => {
              e?.preventDefault();
              props.onDelete?.();
            } }
            icon={ 
              <Tooltip 
                message={ "Delete" }
                base={ <IconDelete size={ 20 } /> } />
            }
            size={ "slim" } />
        </div>

      </div>
    )
  };

  return (
    <div
      className={ props.isSelected ? "apply-selected" : "" }>
      <div
        style={{ position: "relative" }}
        className={ cx(
          "y surface-aside apply-bg apply-fg radius border fill-x"
        ) }>
        
        <div 
          onClick={ e => {
            e?.preventDefault();
            props.onMinimise?.();
          } }
          style={{ cursor: "pointer" }}
          className={ "x surface border-b space-inline-frame-tb align-x-between" }>
          <Button
            variant={ "text" }
            onClick={ e => {
              e?.preventDefault();
              props.onMinimise?.();
            } }
            icon={
              <Tooltip 
                message={ "Minimise" }
                base={ <IconUpAccordian size={ 18 } /> } />
            }
            size={ "slim" } />
        </div>

        {
          props.transactionType !== "expense" && 
            <div 
              style={{ paddingTop: "3px", paddingBottom: "3px" }}
              className={ "x space-inlinez-frame-lr border-b align-x-end surface align-y-center space-inline-children" }>
              <Tooltip
                style={{
                  whiteSpace: "wrap",
                  width: "400px"
                }}
                position={ "right" }
                base={ <IconWarning size={ 18 } /> }
                message={ 
                  `Saving a copy to your Inventory will **not affect the line item in this transaction**, but allow you to easily add common Products or Services to future transactions.

                  It will also be saved to your Inventory with the **currency of this transaction**. 
                  
                  If you want to create it in another currency, or just make a change to it, **navigate to the Products & Services page** from the top bar.
                  `
                } />
              <InputCheckbox
                size={ "small" }
                value={ props.inventory }
                label={ "Create a copy of this line item in your Inventory" }
                labelPosition={ "left" }
                onChange={ v => {
                  if (v === false) {
                    props.removeFromInventory?.();
                  } else if (v === true) {
                    props.addToInventory?.();
                  }
                }} />
            </div>
        }
        
        <div
          className="x space-lines-frame space-cubes-children">
          
          {/* <SelectOfferingType
            label={ "Type" }
            description={ `The kind of thing you are ${ props.transactionType === "income" ? "selling" : "buying" } here` }
            selectedOfferingType={ props.offering.type }
            onSelect={ v => props.onEdit?.("type", v) }
            error={ props.errors?.["type"] } /> */}
          
          <InputText
            label={ "Name" }
            icon={ <IconName /> }
            className={ "fill-inputs" }
            classNameForContainer={ "y align-y-end fill-y" }
            description={ `A simple identifier or label for this ${ offeringType?.label.toLowerCase() }` }
            placeholder={ `The name of your ${ offeringType?.label }` }
            value={ props.offering.name }
            onChange={ v => props.onEdit?.("name", v) }
            error={ props.errors?.["name"] } />
          
          {
            props.offering.type !== "service" && addSKU && 
              <InputText
                label={ "SKU" }
                icon={ <IconSKU /> }
                classNameForContainer={ "y align-y-end fill-y" }
                description={ "Optional" }
                placeholder={ "ABC-12345-S-B" }
                value={ props.offering.sku }
                onChange={ v => props.onEdit?.("sku", v) } />
          }

          <div 
            style={{ minWidth: "100px" }}
            className={ "y fill-x align-x-end" }>
            {
              !addDescription &&
                <Button 
                  variant={ "inline" }
                  size={ "slim" }
                  label={ "Add a description" }
                  onClick={ () => setAddDescription(true) } /> 
            }
            {
              !addSKU && props.offering.type !== "service" && 
                <Button 
                  variant={ "inline" }
                  size={ "slim" }
                  label={ "Add an SKU" }
                  onClick={ () => setAddSKU(true) } />
            }
          </div>

        </div>
 
        {
          (addDescription || props.offering.description) && 
            <div 
              className={ "x space-lines-frame fill-x border-t" }>
                <InputText
                  label={ "Description" }
                  icon={ <IconDescription /> }
                  className={ "fill-inputs" }
                  description={ "Optional" }
                  placeholder={ `Any additional information or specifications relevant to this ${ offeringType?.label.toLowerCase() }`}
                  value={ props.offering.description }
                  onChange={ v => props.onEdit?.("description", v) } />
            </div>
        }

        <div 
          className={ "y space-lines-frame border-t space-lines-children" }>
          
          <div
            className={ "x space-cubes-children" }>
            
            {
              props.offering.type === "service" && 
                <SelectOfferingRateInterval
                  style={{ maxWidth: "30%" }}
                  label={ "Rate Interval" }
                  selectedOfferingRateInterval={ props.offering.rateInterval }
                  onSelect={ v => props.onEdit?.("rateInterval", v) } />
            }
            
            {
              (
                props.transactionType === "income" && 
                props.offering.type !== "service" && 
                addCost 
              ) && 
                <InputMoney
                  style={{ maxWidth: "30%" }}
                  classNameForContainer={ "y align-y-end fill-y" }
                  label={ "Cost Price" }
                  icon={ <IconOfferingCost /> }
                  description={ `Optional. The cost price of this ${ offeringType?.label.toLowerCase() }. This will only be visible to you.` }
                  placeholder={ "10" }
                  // size={ "small" }
                  value={ props.offering.grossCost }
                  onChange={ v => props.onEdit?.("grossCost", v) }
                  toApplicationReadable={ currency.toApplicationReadable }
                  toDataReadable={ currency.toDataReadable }
                  toHumanReadable={ currency.toHumanReadable }
                  config={{
                    currencyCode: props.currencyCode,
                    locale
                  }}
                  error={ props.errors?.["grossCost"] } />
            }

            {
              (
                (rateInterval && props.offering.type === "service") ||
                (props.offering.type !== "service")
              ) && 
                <InputMoney
                  style={{ maxWidth: "40%" }}
                  label={ 
                    props.transactionType === "income"
                      ? (
                        (props.offering.type === "digital_product" || props.offering.type === "physical_product")
                          ? "Sell Price"
                          : "Rate"
                      )
                      : "Purchase Price"
                  }
                  description={
                    props.transactionType === "income"
                      ? (
                        (props.offering.type === "service")
                          ? `The price per ${ rateInterval?.noun } for this ${ offeringType?.label.toLowerCase() }`
                          : (
                            addCost
                              ? `The sell price per unit of this ${ offeringType?.label.toLowerCase() }. If you are passing on an expense to your client, you can set this price to the cost price or include a profit on top.`
                              : `The sell price per unit of this ${ offeringType?.label.toLowerCase() }`
                          )
                      )
                      : "Purchase Price"
                  }
                  classNameForContainer={ "y align-y-end fill-y" }
                  icon={ <IconOfferingPrice /> }
                  placeholder={ "10" }
                  value={ props.offering.grossSale }
                  onChange={ v => props.onEdit?.("grossSale", v) }
                  toApplicationReadable={ currency.toApplicationReadable }
                  toDataReadable={ currency.toDataReadable }
                  toHumanReadable={ currency.toHumanReadable }
                  config={{
                    currencyCode: props.currencyCode,
                    locale
                  }}
                  error={ props.errors?.["grossSale"] } />
            }
            
            {
              (
                (rateInterval && props.offering.type === "service") ||
                (props.offering.type !== "service")
              ) && 
                <InputNumber
                  label={ props.offering.type === "service" ? capitaliseFirstLetter(rateInterval?.nounPlural) : "Quantity" }
                  classNameForContainer={ "y align-y-end fill-y" }
                  icon={ <IconQuantity /> }
                  description={
                    props.offering.type === "service"
                      ? `Total number of ${ rateInterval?.nounPlural }`
                      : "Total quantity of units"
                  }
                  placeholder={ "5" }
                  value={ props.offering.quantity }
                  onChange={ v => props.onEdit?.("quantity", v) }
                  error={ props.errors?.["quantity"] } />
            }
          
          {/* {
            props.transactionType === "expense" && 
              <InputMoney 
                label={ "Tax" }
                description={ "How much tax did you pay?" }
                value={ props.offering.tax }
                onChange={ (v) => props.onEdit?.("tax", v) }
                placeholder="0"
                toApplicationReadable={ currency.toApplicationReadable }
                toDataReadable={ currency.toDataReadable }
                toHumanReadable={ currency.toHumanReadable }
                config={{
                  currencyCode: props.currencyCode,
                  locale
                }} />
          } */}

          {
            (props.transactionType === "income" && props.offering.type !== "service") &&
              <div 
                className={ "x fill-x align-x-end" }>
                {
                  !addCost &&
                    <Button
                      variant={ "inline" }
                      size={ "slim" }
                      label={ "Calculate your Profit Margin" }
                      style={{ padding: "0" }}
                      onClick={ () => setAddCost(true) } />
                }
                {
                  addCost &&
                    <Button
                      variant={ "inline" }
                      size={ "slim" }
                      label={ "Remove Cost" }
                      style={{ padding: "0" }}
                      onClick={ () => {
                        props.onEdit?.("grossCost", 0);
                        setAddCost(false);
                      } } />
                }
              </div>
          }
            
          </div>

          
        
        </div>

        <div 
          className={ "surface-alt y space-lines-frame space-lines-children border-t align-x-between" }>
          
          <div 
            className={ "x space-blocks-children" }>
          
            {
              !isOutOfTaxScope && 
                <div 
                  className={ "y space-inlinez-children" }>
                  
                  <h3>{ "TAX" }</h3>
                      
                  {
                    !props.isInferringTaxCodes && !props.offering.taxCodes && !offeringAlreadyExists && !props.hasAttemptedToInfer &&
                      <>
                        
                        <p className={ "surface-info" }>
                          { "After you've added the Client and named this line item, we will infer which Tax Codes apply for you" }
                        </p>

                        <Button 
                          surface={ "surface-button-inline" }
                          size={ "slim" }
                          icon={ <IconEdit /> }
                          iconSize={ 13 }
                          label={ "Edit manually" }
                          onClick={ props.onEditTaxCode } />

                      </>
                  }

                  {
                    (
                      (!props.isInferringTaxCodes) &&
                      (
                        (offeringAlreadyExists && !hasTaxCodes) ||
                        props.hasAttemptedToInfer && !hasTaxCodes
                      )
                    ) &&
                      <div 
                        className={ "x space-inline-children" }>
                        
                        <Button 
                          surface={ "surface-button-inline" }
                          size={ "slim" }
                          icon={ <IconEdit /> }
                          iconSize={ 13 }
                          label={ "Edit" }
                          onClick={ props.onEditTaxCode } />
                        
                        <Button
                          label={ "Infer with AI" }
                          size={ "slim" }
                          surface={ "surface-button-inline" }
                          icon={ <IconAI /> }
                          iconSize={ 13 }
                          onClick={ async () => {
                            await props.inferTaxCodes?.([{ index: props.i, offering: props.offering  }]);
                          } } />

                      </div>
                  }

                  {
                    props.isInferringTaxCodes && 
                      <p className={ "surface-info" }>
                        { "Working on it, this may take a minute" }
                      </p>
                  }

                  {
                    hasTaxCodes && !props.isInferringTaxCodes &&
                      <div
                        style={{ fontWeight: "bold", fontSize: "18px" }}>
                        { tax === 0 ? "Exempt" : tax + "%" }
                      </div>
                  }

                </div>
            }
            
            {
              !props.isInferringTaxCodes && hasTaxCodes &&
                <div 
                  className={ "y fill-x space-inlinez-children" }>
                  <h3>{ "CODES" }</h3>
                  <div 
                    className={ "x fill-x" }>
                      <div 
                        style={{ gap: "3px" }}
                        className={ "y" }>
                        {
                          props.offering.taxCodes?.map(code => (
                            <TaxCodeTag taxCode={ code } />
                          ))
                        }
                      </div>
                  </div>
                </div>  
            }

            <div 
              className={ "y align-x-end space-inline-children" }>
              {
                !props.isInferringTaxCodes && hasTaxCodes &&
                  <>

                    <Button 
                      variant={ "inline" }
                      size={ "slim" }
                      icon={ <IconEdit /> }
                      iconSize={ 14 }
                      label={ "Edit" }
                      onClick={ props.onEditTaxCode } />
                    
                    <Button
                      label={ "Infer with AI" }
                      size={ "slim" }
                      variant={ "inline" }
                      icon={ <IconAI /> }
                      iconSize={ 13 }
                      onClick={ async () => {
                        await props.inferTaxCodes?.([{ index: props.i, offering: props.offering  }]);
                      } } />

                  </>
              }
            </div>

          </div>

          {
            !props.isInferringTaxCodes && hasTaxCodes && Math.abs(tax) > 0 && !isOutOfTaxScope &&
              <div 
                className={ "y" }>
                
                <h3>{ "TAX APPLICATION" }</h3>

                <InputSelect
                  size={ "small" }
                  options={[
                    { id: "additive", label: "Calculate the % off of the final price" },
                    { id: "inclusive", label: "Include the tax in the final price" },
                  ]}
                  matchKey={ "id" }
                  formatLabel={ x => x.label }
                  value={ [
                    { id: "additive", label: "Calculate the % off of the final price" },
                    { id: "inclusive", label: "Include the tax in the final price" },
                  ].find(tt => tt.id === props.offering.taxType) }
                  onSelect={ x => props.onEdit?.("taxType", x.id) }
                  // description={ "How do you want to apply this tax?" }
                  error={ props.errors?.["taxType"] } /> 
                
              </div>
          }
          

          {/* <InputSelect
            label={ "Tax Type" }
            icon={ <IconTaxType /> }
            options={[
              { id: "additive", label: "Calculate the % off of the final price" },
              { id: "inclusive", label: "Include the tax in the final price" },
            ]}
            matchKey={ "id" }
            formatLabel={ x => x.label }
            value={ [
              { id: "additive", label: "Calculate the % off of the final price" },
              { id: "inclusive", label: "Include the tax in the final price" },
            ].find(tt => tt.id === props.offering.taxType) }
            onSelect={ x => props.onEdit?.("taxType", x.id) }
            description={ "How do you want to apply this tax?" }
            error={ props.errors?.["taxType"] } /> 
          
          <InputNumber
            label={ "Tax" }
            icon={ <IconTax /> }
            classNameForContainer={ "y align-y-end fill-y" }
            placeholder={ "18%" }
            value={ props.offering.tax }
            onChange={ v => props.onEdit?.("tax", v) } /> */}          

        </div>

        <div
          className={ "x space-inlinez-frame-tb space-lines-frame-lr align-y-center space-inline-children border-t surface-alt" }>

          <Amount
            localisedAmount={ summary.total }
            currency={ props.currencyCode }
            localisedCurrency={ props.currencyCode || "" }
            locale={ locale || "" } />

        </div> 

      </div>
    </div>
  );

}

export interface ITransactionOfferingEditor {
  i: number,
  surfaceDisplay?: string
  currencyCode?: string
  offering: TransactionOfferingOrphan
  inventory?: boolean 
  addToInventory?: () => void
  removeFromInventory?: () => void
  transactionType?: TransactionType
  isSelected?: boolean
  onSelect?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit?: (field: keyof TransactionOffering, value: any) => void
  onDelete?: () => void
  errors?: ObjectFieldValidation
  onMinimise?: () => void
  isUsingAssist?: boolean
  isInferringTaxCodes?: boolean
  hasAttemptedToInfer?: boolean
  inferTaxCodes?: InferTaxCodes
  onEditTaxCode?: () => void
}