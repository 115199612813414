import { EntityType } from "@bookie/glossary";

export const hydrateEntityType = (type?: EntityType): HydratedEntityType => {
  switch (type) {
    
    case "client": return ({
      id: type,
      label: "Client"
    });

    case "merchant": return ({
      id: type,
      label: "Merchant"
    });

    default: return ({
      id: "client",
      label: "Entity"
    });

  }
}

export interface HydratedEntityType {
  id: EntityType 
  label: string
}