import { SUMMARIES, TransactionExpandedSegment, TransactionSegmentGroup, TransactionsSummary } from "@bookie/glossary";

export const groupSegments = (
  segments: TransactionExpandedSegment[] = []
): TransactionSegmentGroup => {
  
  const summary = segments.reduce(
    (summary: TransactionsSummary, segment: TransactionExpandedSegment) => ({
      all: {
        subTotal: (summary.all?.subTotal || 0) + (segment.summary?.all?.subTotal || 0), 
        tax: (summary.all?.tax || 0) + (segment.summary?.all?.tax || 0), 
        total: (summary.all?.total || 0) + (segment.summary?.all?.total || 0), 
      },
      accrued: {
        subTotal: (summary.accrued?.subTotal || 0) + (segment.summary?.accrued?.subTotal || 0), 
        tax: (summary.accrued?.tax || 0) + (segment.summary?.accrued?.tax || 0), 
        total: (summary.accrued?.total || 0) + (segment.summary?.accrued?.total || 0), 
      },
      cash: {
        subTotal: (summary.cash?.subTotal || 0) + (segment.summary?.cash?.subTotal || 0), 
        tax: (summary.cash?.tax || 0) + (segment.summary?.cash?.tax || 0), 
        total: (summary.cash?.total || 0) + (segment.summary?.cash?.total || 0), 
      },
      invoicePending: {
        subTotal: (summary.invoicePending?.subTotal || 0) + (segment.summary?.invoicePending?.subTotal || 0), 
        tax: (summary.invoicePending?.tax || 0) + (segment.summary?.invoicePending?.tax || 0), 
        total: (summary.invoicePending?.total || 0) + (segment.summary?.invoicePending?.total || 0), 
      },
      estimatePending: {
        subTotal: (summary.estimatePending?.subTotal || 0) + (segment.summary?.estimatePending?.subTotal || 0), 
        tax: (summary.estimatePending?.tax || 0) + (segment.summary?.estimatePending?.tax || 0), 
        total: (summary.estimatePending?.total || 0) + (segment.summary?.estimatePending?.total || 0)
      },
      badDebt:{
        subTotal: (summary.badDebt?.subTotal || 0) + (segment.summary?.badDebt?.subTotal || 0), 
        tax: (summary.badDebt?.tax || 0) + (segment.summary?.badDebt?.tax || 0), 
        total: (summary.badDebt?.total || 0) + (segment.summary?.badDebt?.total || 0)
      },
      expensesDue:{
        subTotal: (summary.expensesDue?.subTotal || 0) + (segment.summary?.expensesDue?.subTotal || 0), 
        tax: (summary.expensesDue?.tax || 0) + (segment.summary?.expensesDue?.tax || 0), 
        total: (summary.expensesDue?.total || 0) + (segment.summary?.expensesDue?.total || 0)
      },
      expensesPaid:{
        subTotal: (summary.expensesPaid?.subTotal || 0) + (segment.summary?.expensesPaid?.subTotal || 0), 
        tax: (summary.expensesPaid?.tax || 0) + (segment.summary?.expensesPaid?.tax || 0), 
        total: (summary.expensesPaid?.total || 0) + (segment.summary?.expensesPaid?.total || 0)
      }
    }),
    SUMMARIES
  );

  return ({
    segments,
    summary
  });

}