import { useCurrency } from "@hny_/currency";
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import React from "react";
import { useIdentity } from "@bookie/module-identity";
import { getLocaleFromCountryCode } from "@bookie/module-core";

export const TransactionEditorTransactionSummary: React.FC<ITransactionEditorTransactionSummary> = (props) => {

  const { ownership } = useIdentity();
  const curr = useCurrency();
  const locale = getLocaleFromCountryCode(ownership.current?.entity?.countryCode);

  return (
    <div 
      className={ "space-cubes-frame-t fill-x x align-x-end" }>
      <div 
        style={{ width: "50%" }}
        className={ "y" }>
        <div 
          className={ "x align-x-between border-t space-inline-frame-tb" }>
          <p>{ "Subtotal" }</p>
          <p><b>{ curr.toHumanReadable(props.editor?.summary.subTotal || 0, locale, props.editor?.data.currencyCode) }</b></p>
        </div>
        <div 
          className={ "x align-x-between border-t space-inline-frame-tb" }>
          <p>{ "Total Tax" }</p>
          <p><b>{ curr.toHumanReadable(props.editor?.summary.tax || 0, locale, props.editor?.data.currencyCode) }</b></p>
        </div>
        <div 
          className={ "x align-x-between border-t space-inline-frame-tb" }>
          <p><b>{ "Total Due" }</b></p>
          <p><b>{ curr.toHumanReadable(props.editor?.summary.total || 0, locale, props.editor?.data.currencyCode) }</b></p>
        </div>
        {
          props.editor?.state.requiresLocalisation && 
            <div 
              className={ "x align-x-between border-t space-inline-frame-tb" }>
              <p><b>{ `Total Due (converted to ${ ownership.current?.entity?.currencyCode })` }</b></p>
              <p><b>{ curr.toHumanReadable(props.editor?.summary.localised?.total || 0, locale, ownership.current?.entity?.currencyCode) }</b></p>
            </div>
        }
      </div>
    </div>
  );
}

export interface ITransactionEditorTransactionSummary {
  editor?: ITransactionEditorAPI;
}