import { TaxSuiteConfiguration } from "@bookie/glossary";
import { VAT } from "./vat";
import { IncomePersonalTax } from "./income/personal";

export const TaxSuiteMalta: TaxSuiteConfiguration = {
  
  label: "Malta Tax Suite",
  countryCode: "MLT",

  dataCollection: {
    onboarding: [
      {
        propertyName: "article",
        propertyOptions: [ { id: "10", value: "Article 10"}, { id: "11", value: "Article 11" } ],
        question: "Under which Article is your business registered?",
        questionDescription: "Generally, if you are earning less than €35,000 for a Goods-based business or €30,000 for a Services-based business you are elligible for Article 11. However you need to specifically request this from the relevant Authority. Otherwise it's likely you are registered under Article 10.",
        validate: (collection: { [key: string]: string }) => typeof collection?.["article"] !== "undefined" || collection?.["article"]
      },
    ]
  },

  salesTax: VAT,
  incomeTax: IncomePersonalTax

}