import { TaxSetPeriod } from "@bookie/glossary";
import React from "react";
import { getCurrentPeriod } from "../fns/get-current-period";

export const useCurrentTaxPeriod = (
  periods: TaxSetPeriod[] = [],
  currentTaxYear?: number,
  selectedTaxYear?: number
) => {
  
  const [ currentPeriod, setCurrentPeriod ] = React.useState<TaxSetPeriod | null>(null);

  React.useEffect(() => {
    if (selectedTaxYear && currentTaxYear) {
      if (selectedTaxYear < currentTaxYear) {
        setCurrentPeriod(periods[periods.length - 1]);
      } 
      else if (selectedTaxYear > currentTaxYear) {
        setCurrentPeriod(periods[0]);
      }
      else {
        const current = getCurrentPeriod(periods);
        setCurrentPeriod(current);
      }
    }
  }, [ periods, selectedTaxYear, currentTaxYear ]);

  return {
    currentPeriod,
    setCurrentPeriod
  };

};
