import { ErrorAPI } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";

export const getUserlocation: IGetUserLocation = async () => {

  const _response = await fetch(
    `https://ipapi.co/json/`, 
    { method: 'GET'}
  );
  
  const response = await _response.json();

  if (!response) {
    throw new ErrorAPI({}); 
  }

  return objectToCamel<GeoLocation>(response);

}

export type IGetUserLocation = () => Promise<GeoLocation>

export interface GeoLocation {
  ip: string
  network: string
  version: string
  city: string
  region: string
  regionCode: string
  country: string
  countryName: string
  countryCode: string
  countryCodeIso3: string
  countryCapital: string
  countryTld: string
  continentCode: string
  inEu: boolean
  postal: string
  latitude: number
  longitude: number
  timezone: string
  utcOffset: string
  countryCallingCode: string
  currency: string
  currencyName: string
  languages: string
  countryArea: string
  countryPopulation: string
  asn: string
  org: string
}