import { useIdentity } from "@bookie/module-identity";
import React from "react";
import { getMyTaxSuite } from "./fns/get-my-tax-suite";
import { OfferingTaxCode, TaxCode, TaxCodeScope, TaxSuiteConfiguration, TransactionType } from "@bookie/glossary";
import { filterRelevantTaxCodes } from "./fns/filter-relevant-tax-codes";
import { getTaxCode } from "./fns/get-tax-code";

const TaxSuitesCtx = React.createContext<TaxSuitesAPI | undefined>(undefined);

const TaxSuitesProvider: React.FC<{ 
  children?: React.ReactNode 
}> = (props) => {

  const { ownership } = useIdentity();

  const myTaxSuite = getMyTaxSuite(ownership.current?.entity?.countryCode);

  return (
    <TaxSuitesCtx.Provider
      value={{
        suite: myTaxSuite,
        getTaxCode: (
          (id) => getTaxCode(id, myTaxSuite?.salesTax.codes)
        ),
        getTaxCodes: (
          (taxCodeObjects: OfferingTaxCode[]) => 
            myTaxSuite?.salesTax.codes?.filter(c => 
              taxCodeObjects.some(taxCode => taxCode.taxCodeId === c.id)
            )
        ),
        filterRelevantTaxCodes: (transactionType, scope) => (
          filterRelevantTaxCodes(myTaxSuite?.salesTax.codes || [], { transactionType, scope })
        )
      }}>
      { props.children }
    </TaxSuitesCtx.Provider>
  )
}

const useTaxSuites = (): TaxSuitesAPI => {
  const value = React.useContext(TaxSuitesCtx);
  if (value === undefined) {
    throw new Error("Missing TaxSuitesProvider");
  }
  return value;
}

export { TaxSuitesProvider, useTaxSuites }

export interface TaxSuitesAPI {
  suite: TaxSuiteConfiguration | null,
  getTaxCode: (id: string) => TaxCode | undefined,
  getTaxCodes: (taxCodes: OfferingTaxCode[]) => TaxCode[] | undefined
  filterRelevantTaxCodes: (transactionType?: TransactionType, scope?: TaxCodeScope) => TaxCode[]
}