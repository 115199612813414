import React from "react";
import { IEntityEditorAPI, useEntityEditor } from "./use-entity-editor";
import { useCoreAPI } from "../use-core-api";
import { ErrorAPI } from "@bookie/glossary";
import { useNotification } from "@bookie/components";

export const useOrgEditor = (): IOrgEditorAPI => {

  const api = useCoreAPI();
  const n = useNotification();
  const entityEditor = useEntityEditor("create");

  const [ isCreatingOrg, setIsCreatingOrg ] = React.useState<boolean>(false);

  return ({
    
    commit: async () => {

      const canCommit = entityEditor.validate(true);
      if (!canCommit) return false;

      try {

        setIsCreatingOrg(true);
        await api.createOrg(entityEditor.data);
        setIsCreatingOrg(false);
        // n.notify({ type: "success", message: "Great! You are all setup and ready to go!" });
        return true;

      } catch (e) {
        setIsCreatingOrg(false);
        if (e instanceof ErrorAPI) {
          n.notify({ type: "error", message: e.error });
        }
      }

      return false;

    },

    state: {
      isWorking: isCreatingOrg,
      isCreatingOrg
    },

    related: {
      entityEditor
    }

  });

}

export interface IOrgEditorAPI {
  commit: () => Promise<boolean>
  state: IOrgEditorState
  related: {
    entityEditor: IEntityEditorAPI
  }
}

export interface IOrgEditorState {
  isWorking: boolean
  isCreatingOrg: boolean
}