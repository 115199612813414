import React from "react";
import { OnboardingCard } from "../_onboarding-card";
import { IOrgEditorAPI } from "../../../use/use-org-editor";
import { SelectCurrency } from "../../../components/select-currency";
import { SelectCountry } from "../../..//components/select-country";
import { IllustrationLocation2 } from "@bookie/images";
import css from "./index.module.css";
import cx from "classnames";

export const OnboardingLocation: React.FC<IOnboardingLocation> = (props) => {
  return (
    <OnboardingCard
      className={ cx(
        css.location,
        "y"
      ) }>
      
      <div 
        className={ cx(
          css.location_illustration,
          "x align-y-center align-x-center"
        ) }>
        <img 
          src={ IllustrationLocation2 }
          alt={ "Location" } />
      </div>
    
      <div 
        style={{ zIndex: 999 }}
        className={ "y space-cubes-frame space-cubes-children" }>
      

        <div 
          className={ "x space-cubes-children space-lines-frame" }>
          <SelectCountry
            style={{ flexGrow: 0 }}
            label={ "Country" }
            selectedCountryCode={ props.editor?.related.entityEditor.data.countryCode }
            onSelect={ v => props.editor?.related.entityEditor.edit.countryCode(v.alpha3) }
            error={ props.editor?.related.entityEditor.errors.validation?.countryCode } />
          
          <SelectCurrency
            label={ "Currency" }
            selectedCurrencyCode={ props.editor?.related.entityEditor.data.currencyCode }
            onSelect={ v => props.editor?.related.entityEditor.edit.currencyCode(v.code) }
            error={ props.editor?.related.entityEditor.errors.validation?.currencyCode }
            showName={ true } />
        </div>

        <h2 
          className={ css.location_question }>
          { "Where are you based?" }
        </h2>
      
      </div>
      
    </OnboardingCard>
  )
}

export interface IOnboardingLocation {
  editor?: IOrgEditorAPI
}