import React from "react";
import css from "./index.module.css";
// import cx from "classnames";
import { IconLeft, IconRight, Paper, TableX, Tabs } from "@bookie/components";
import { useTaxSuites } from "../../../../use-tax-suites";
import { TaxCode, TaxSetPeriod, TransactionExpanded } from "@bookie/glossary";
import { useCurrentTaxPeriod } from "../../../../use/use-current-tax-period";
import { format } from "date-fns";
import { ISummariseTransactionOffering, PeriodSegment, segmentByPeriod } from "../../../../fns/segment-by-period";
import { useCurrency } from "@hny_/currency";

export const VATForm: React.FC<IVATForm> = (props) => {

  const { suite, getTaxCodes } = useTaxSuites();
  const curr = useCurrency();

  const intraCommunity = suite?.salesTax.codes?.filter(c => ["MLT_VAT_01", "MLT_VAT_02", "MLT_VAT_03", "MLT_VAT_04"].includes(c.id));
  const reverseCharge = suite?.salesTax.codes?.filter(c => ["MLT_VAT_09", "MLT_VAT_09A", "MLT_VAT_10", "MLT_VAT_11"].includes(c.id));
  const domesticSales = suite?.salesTax.codes?.filter(c => ["MLT_VAT_18", "MLT_VAT_18A", "MLT_VAT_19", "MLT_VAT_20", "MLT_VAT_21"].includes(c.id));
  const domesticPurchases = suite?.salesTax.codes?.filter(c => ["MLT_VAT_27", "MLT_VAT_28", "MLT_VAT_29", "MLT_VAT_30", "MLT_VAT_31", "MLT_VAT_31A", "MLT_VAT_32"].includes(c.id));

  const [ year, setYear ] = React.useState<number>();
  const [ selectedYear, setSelectedYear ] = React.useState<number>();
  const [ periods, setPeriods ] = React.useState<TaxSetPeriod[]>();
  const [ segmentedPeriods, setSegmentedPeriods ] = React.useState<PeriodSegment[]>();

  React.useEffect(() => {
    const _year = suite?.salesTax.calculateCurrentYear();
    setYear(_year);
    setSelectedYear(_year);
  }, [suite?.salesTax]);

  React.useEffect(() => {
    const _periods = suite?.salesTax.calculatePeriods(selectedYear);
    setPeriods(_periods);
  }, [selectedYear, suite?.salesTax]);

  const { currentPeriod, setCurrentPeriod } = useCurrentTaxPeriod(periods, year, selectedYear);

  React.useEffect(() => {
    if (currentPeriod) {
      const segments = segmentByPeriod(
        props.transactions || [], 
        [ currentPeriod ],
        suite?.salesTax.codes || [],
        props.summariseTransactionOffering,
        getTaxCodes
      );
      setSegmentedPeriods(segments);
    }
  }, [ 
    currentPeriod, 
    props.transactions,
    props.summariseTransactionOffering,
    suite?.salesTax.codes,
    getTaxCodes
  ]);

  const hasPeriods = periods && periods.length > 0;

  const formattedStart = currentPeriod && format(new Date(currentPeriod?.from), "do MMMM yyy");
  const formattedEnd = currentPeriod && format(new Date(currentPeriod?.to), "do MMMM yyy");

  return (
    <div
      className={ "fill-x align-x-center y space-lines-children" }>
      
      <div 
        className={ "y space-inline-children align-x-center space-lines-frame-b" }>
        
        <div 
          className={ "x align-y-center space-inline-children" }>
          
          {
            selectedYear && 
              <div 
                className={ css.year_selector }
                onClick={ () => {
                  const newSelectedYear = selectedYear - 1;
                  setSelectedYear(newSelectedYear);
                } }>
                <IconLeft
                  weight={ "fill" }
                  size={ 12 } />
              </div>
          }

          <h3>{ selectedYear }</h3>
        
          {
            selectedYear && year && (selectedYear < year) && 
              <div 
                className={ css.year_selector  }
                onClick={ () => setSelectedYear(selectedYear + 1) }>
                <IconRight
                  weight={ "fill" }
                  size={ 12 } />
              </div>
          }
        
        </div>
        
        {
          hasPeriods &&
            <Tabs
              selectedTab={ currentPeriod?.id }
              options={ periods }
              onSelect={ (p) => setCurrentPeriod(p as TaxSetPeriod) } />
        }

        

      </div>

      <Paper
        variant={ "a4" }>

          <div 
            className={ "y space-blocks-children" }>
            
            <div 
              className={ "x align-x-between align-y-start" }>
              <div 
                className={ "y" }>
                <h2>{ "VAT Return" }</h2>
                <small style={{ fontSize: "10px" }}>{ "In terms of article 27 of the VAT Act. Cap. 406." }</small>
              </div>
            
              <div style={{ fontSize: "14px", paddingTop: "12px" }}>{ `${formattedStart} → ${formattedEnd}` }</div>

            </div>
          
            <TableX
              data={ [
                ...(intraCommunity || []),
                {
                  id: "subtotal",
                  name: "Subtotal",
                  officialBoxRef: "5"
                }
              ] }
              headerClassName={ css.headerRow }
              onHighlightRowClassName={ (c) => (c.id === "subtotal" ? css.row_subtotal : "") }
              columns={[
                {
                  header: "Intra-Community and Non-EU Trade",
                  renderCell: (c: TaxCode) => c.officialName,
                  width: "60%",
                  className: "border-l"
                },
                {
                  header: "",
                  renderCell: (c: TaxCode) => <div className={ css.boxNumber }> { c.officialBoxRef } </div>,
                  width: "2.5%",
                  className: "border-l"
                },
                {
                  header: "Value (€)",
                  renderCell: (c: TaxCode) => {
                    if (c.id === "subtotal") {
                      const intra = segmentedPeriods?.filter(sp => ["MLT_VAT_01", "MLT_VAT_02", "MLT_VAT_03", "MLT_VAT_04"].includes(sp.taxCodeId));
                      const value = intra?.reduce((summary, ps) => (ps.subtotal + summary), 0);
                      return curr.toHumanReadable(value || 0);
                    } else {
                      const code = segmentedPeriods?.find(sp => sp.taxCodeId === c.id);
                      return curr.toHumanReadable(code?.subtotal || 0);
                    }
                  },
                  width: "20%",
                  align: "right"
                },
                {
                  header: "",
                  renderCell: (c: TaxCode) => {
                    let value = null;
                    if (c.officialBoxRef === "3") value = "6";
                    if (c.officialBoxRef === "4") value = "7";
                    if (c.officialBoxRef === "5") value = "8";
                    if (!value) return null;
                    return (
                      <div 
                        className={ css.boxNumber }>
                        { value }
                      </div>       
                    )
                  },
                  width: "2.5%",
                  className: "border-l"
                },
                {
                  header: "Output Tax (€)",
                  renderCell: (c: TaxCode) => {
                    if (c.officialBoxRef === "1" || c.officialBoxRef === "2") return "";
                    if (c.id === "subtotal") {
                      const intra = segmentedPeriods?.filter(sp => ["MLT_VAT_01", "MLT_VAT_02", "MLT_VAT_03", "MLT_VAT_04"].includes(sp.taxCodeId));
                      const value = intra?.reduce((summary, ps) => (ps.tax + summary), 0);
                      return curr.toHumanReadable(value || 0);
                    } else {
                      const code = segmentedPeriods?.find(sp => sp.taxCodeId === c.id);
                      return curr.toHumanReadable(code?.tax || 0);
                    }
                  },
                  width: "20%",
                  align: "right",
                  className: "border-r"
                }
              ]} />

            <TableX
              data={ [
                ...(reverseCharge || []),
                {
                  id: "subtotal",
                  name: "Subtotal",
                  officialBoxRef: "12"
                }
              ] }
              headerClassName={ css.headerRow }
              onHighlightRowClassName={ (c) => (c.id === "subtotal" ? css.row_subtotal : "") }
              columns={[
                {
                  header: "Deductions of Self-Charged VAT",
                  renderCell: (c: TaxCode) => c.officialName,
                  width: "60%",
                  className: "border-l"
                },
                {
                  header: "",
                  renderCell: (c: TaxCode) => <div className={ css.boxNumber }> { c.officialBoxRef } </div>,
                  width: "2.5%",
                  className: "border-l"
                },
                {
                  header: "Value (€)",
                  renderCell: (c: TaxCode) => {
                    if (c.id === "subtotal") {
                      const reverse = segmentedPeriods?.filter(sp => ["MLT_VAT_09", "MLT_VAT_09A", "MLT_VAT_10", "MLT_VAT_11"].includes(sp.taxCodeId));
                      const value = reverse?.reduce((summary, ps) => (ps.subtotal + summary), 0);
                      return curr.toHumanReadable(value || 0);
                    } else {
                      const code = segmentedPeriods?.find(sp => sp.taxCodeId === c.id);
                      return curr.toHumanReadable(code?.subtotal || 0)
                    }
                  },
                  width: "20%",
                  align: "right"
                },
                {
                  header: "",
                  renderCell: (c: TaxCode) => {
                    let value = null
                    if (c.officialBoxRef === "9") value = "13";
                    if (c.officialBoxRef === "9A") value = "13A";
                    if (c.officialBoxRef === "10") value = "14";
                    if (c.officialBoxRef === "11") value = "15";
                    if (c.officialBoxRef === "12") value = "16";
                    if (!value) return null;
                    return (
                      <div 
                        className={ css.boxNumber }>
                        { value }
                      </div>       
                    )
                  },
                  width: "2.5%",
                  className: "border-l"
                },
                {
                  header: "Input Tax (€)",
                  renderCell: (c: TaxCode) => {
                    if (c.officialBoxRef === "1" || c.officialBoxRef === "2") return "nil";
                    if (c.id === "subtotal") {
                      const reverse = segmentedPeriods?.filter(sp => ["MLT_VAT_09", "MLT_VAT_09A", "MLT_VAT_10", "MLT_VAT_11"].includes(sp.taxCodeId));
                      const value = reverse?.reduce((summary, ps) => (ps.tax + summary), 0);
                      return curr.toHumanReadable(value || 0);
                    } else {
                      const code = segmentedPeriods?.find(sp => sp.taxCodeId === c.id);
                      return curr.toHumanReadable(code?.tax || 0)
                    }
                  },
                  width: "20%",
                  align: "right",
                  className: "border-r"
                }
              ]} />
            
            <TableX
              data={ [
                ...(domesticSales || []),
                {
                  id: "subtotal",
                  name: "Subtotal",
                  officialBoxRef: "22"
                }
              ] }
              headerClassName={ css.headerRow }
              onHighlightRowClassName={ (c) => (c.id === "subtotal" ? css.row_subtotal : "") }
              columns={[
                {
                  header: "Domestic Supplies & Exports",
                  renderCell: (c: TaxCode) => c.officialName,
                  width: "60%",
                  className: "border-l"
                },
                {
                  header: "",
                  renderCell: (c: TaxCode) => <div className={ css.boxNumber }> { c.officialBoxRef } </div>,
                  width: "2.5%",
                  className: "border-l"
                },
                {
                  header: "Value (€)",
                  renderCell: (c: TaxCode) => {
                    if (c.id === "subtotal") {
                      const _periods = segmentedPeriods?.filter(sp => ["MLT_VAT_18", "MLT_VAT_18A", "MLT_VAT_18B", "MLT_VAT_19", "MLT_VAT_20", "MLT_VAT_21"].includes(sp.taxCodeId));
                      const value = _periods?.reduce((summary, ps) => (ps.subtotal + summary), 0);
                      return curr.toHumanReadable(value || 0);
                    } else {
                      const code = segmentedPeriods?.find(sp => sp.taxCodeId === c.id);
                      return curr.toHumanReadable(code?.subtotal || 0);
                    }
                  },
                  width: "20%",
                  align: "right"
                },
                {
                  header: "",
                  renderCell: (c: TaxCode) => {
                    let value = null;
                    if (c.officialBoxRef === "18") value = "23";
                    if (c.officialBoxRef === "18A") value = "23A";
                    if (c.officialBoxRef === "18B") value = "23B";
                    if (c.officialBoxRef === "19") value = "24";
                    if (c.officialBoxRef === "22") value = "25";
                    if (!value) return null;
                    return (
                      <div 
                        className={ css.boxNumber }>
                        { value }
                      </div>       
                    )
                  },
                  width: "2.5%",
                  className: "border-l"
                },
                {
                  header: "Output Tax (€)",
                  renderCell: (c: TaxCode) => {
                    if (c.officialBoxRef === "20" || c.officialBoxRef === "21") return "";
                    if (c.id === "subtotal") {
                      const _period = segmentedPeriods?.filter(sp => ["MLT_VAT_18", "MLT_VAT_18A", "MLT_VAT_18B", "MLT_VAT_19"].includes(sp.taxCodeId));
                      const value = _period?.reduce((summary, ps) => (ps.tax + summary), 0);
                      return curr.toHumanReadable(value || 0);
                    } else {
                      const code = segmentedPeriods?.find(sp => sp.taxCodeId === c.id);
                      return curr.toHumanReadable(code?.tax || 0);
                    }
                  },
                  width: "20%",
                  align: "right",
                  className: "border-r"
                }
              ]} />
            
            <TableX
              data={ [
                ...(domesticPurchases || []),
                {
                  id: "subtotal",
                  name: "Subtotal",
                  officialBoxRef: "33"
                }
              ]  }
              headerClassName={ css.headerRow }
              onHighlightRowClassName={ (c) => (c.id === "subtotal" ? css.row_subtotal : "") }
              columns={[
                {
                  header: "Domestic Purchases & Imports",
                  renderCell: (c: TaxCode) => c.officialName,
                  width: "60%",
                  className: "border-l"
                },
                {
                  header: "",
                  renderCell: (c: TaxCode) => <div className={ css.boxNumber }> { c.officialBoxRef } </div>,
                  width: "2.5%",
                  className: "border-l"
                },
                {
                  header: "Value (€)",
                  renderCell: (c: TaxCode) => {
                    if (c.id === "subtotal") {
                      const intra = segmentedPeriods?.filter(sp => ["MLT_VAT_27", "MLT_VAT_28", "MLT_VAT_29", "MLT_VAT_30", "MLT_VAT_31", "MLT_VAT_31A", "MLT_VAT_31B", "MLT_VAT_32"].includes(sp.taxCodeId));
                      const value = intra?.reduce((summary, ps) => (ps.subtotal + summary), 0);
                      return curr.toHumanReadable(value || 0);
                    } else {
                      const code = segmentedPeriods?.find(sp => sp.taxCodeId === c.id);
                      return curr.toHumanReadable(code?.subtotal || 0);
                    }
                  },
                  width: "20%",
                  align: "right"
                },
                {
                  header: "",
                  renderCell: (c: TaxCode) => {
                    let value = null;
                    if (c.officialBoxRef === "27") value = "34";
                    if (c.officialBoxRef === "28") value = "35";
                    if (c.officialBoxRef === "30") value = "36";
                    if (c.officialBoxRef === "31") value = "37";
                    if (c.officialBoxRef === "31A") value = "37A";
                    if (c.officialBoxRef === "31B") value = "37B";
                    if (c.officialBoxRef === "32") value = "38";
                    if (c.officialBoxRef === "33") value = "39";
                    if (!value) return null;
                    return (
                      <div 
                        className={ css.boxNumber }>
                        { value }
                      </div>       
                    )
                  },
                  width: "2.5%",
                  className: "border-l"
                },
                {
                  header: "Input Tax (€)",
                  renderCell: (c: TaxCode) => {
                    if (c.officialBoxRef === "29") return "";
                    if (c.id === "subtotal") {
                      const intra = segmentedPeriods?.filter(sp => ["MLT_VAT_27", "MLT_VAT_28", "MLT_VAT_30", "MLT_VAT_31", "MLT_VAT_31A", "MLT_VAT_31B", "MLT_VAT_32"].includes(sp.taxCodeId));
                      const value = intra?.reduce((summary, ps) => (ps.tax + summary), 0);
                      return curr.toHumanReadable(value || 0);
                    } else {
                      const code = segmentedPeriods?.find(sp => sp.taxCodeId === c.id);
                      return curr.toHumanReadable(code?.tax || 0);
                    }
                  },
                  width: "20%",
                  align: "right",
                  className: "border-r"
                }
              ]} />

        </div>
      </Paper>
    </div>
  );
}

export interface IVATForm {
  transactions?: TransactionExpanded[]
  summariseTransactionOffering: ISummariseTransactionOffering
}