import React from "react";
import { IInput, InputBase } from "../base";
import css from "./index.module.css";
import cx from "classnames";
import { IconSuccessTick } from "../../icon";

export const InputSelectTile: React.FC<IInputSelectTile> = (props) => {

  const tileSize = props.tileSize || "large";

  console.log(props.align)

  return (
    <InputBase
      { ...props }>
      
      <div 
        className={ cx(
          css.selectTile_container,
          "x wrap",
          css[`selectTile_container_align_${ props.align }`],
          { "space-inline-children": tileSize === "small" },
          { "space-lines-children": tileSize === "medium" },
          { "space-blocks-children": tileSize === "large" }
        ) }>
        {
          props.options?.map(o => {

            const isSelected = o.id === props.value;

            return (
              <div 
                className={ cx(
                  css.selectTile_tile,
                  css[`selectTile_tile_size_${ tileSize }`],
                  "apply-fg hvr-pulse y align-x-center align-y-center",
                  { "surface-altx": !isSelected },
                  { "surface-primary": isSelected },
                  { [css.selectTile_tile_isSelected]: isSelected }
                ) }
                onClick={ () => props.onSelect(o) }>
                
                {
                  isSelected &&  
                    <div 
                      className={ css.selectTile_tile_selectedTick }>
                      <IconSuccessTick 
                        color={ "#fff" }
                        weight={ "fill" } />
                    </div>
                }

                {
                  o.image &&
                    <img 
                      src={ o.image }
                      alt={ o.label } /> 
                } 

                {
                  o.render && 
                    o.render
                }
                
                <p
                  className={ css.selectTile_tile_label }>
                  { o.label }
                </p>

              </div>      
            );
          })
        }
      </div>

    </InputBase>
  );
} 

export interface IInputSelectTile extends IInput {
  tileSize?: "small" | "medium" | "large"
  value?: string;
  options?: IInputSelectTileOption[]
  onSelect: (o: IInputSelectTileOption) => void 
}

export interface IInputSelectTileOption {
  id: string
  label: string 
  image?: string
  render?: React.ReactNode
}