import { Button, IconClose, IconDownload, IconPreview, Stat, Tabs } from "@bookie/components";
import { TransactionStage } from "@bookie/glossary";
import React from "react";
import css from "./index.module.css";
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import { StageControlsInvoice } from "./invoice";
import { StageControlsEstimate } from "./estimate";
import { TransactionStatusEstimate } from "../../../components/transaction-status/estimate";
import { TransactionStatusInvoice } from "../../../components/transaction-status/invoice";
import { TransactionStatusPayment } from "../../../components/transaction-status/payment";
import { TransactionDocument } from "../../../components/transaction-document";
import { useIdentity } from "@bookie/module-identity";
import { useCurrency } from "@hny_/currency";
import { IEntityEditorAPI, IOrgTemplateEditorAPI } from "@bookie/module-core";
import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import cx from "classnames";
import { StageControlsPayment } from "./payment";
import { useTaxSuites } from "@bookie/module-tax-suites";

export const TransactionEditorStateControls: React.FC<ITransactionEditorStateControls> = (props) => {

  const { ownership } = useIdentity();
  const curr = useCurrency();
  const { getTaxCodes } = useTaxSuites();

  const hasEstimate = props.editor?.data.estimateState !== "n/a";
  const isEstimateReady = (hasEstimate && props.editor?.data.estimateState === "approved") || !hasEstimate;

  const downloadDocument = React.useRef<HTMLAnchorElement>(null);

  const [ showPreview, setShowPreview ] = React.useState<boolean>(false);

  const Document = () => (
    <TransactionDocument
      getTaxCodes={ getTaxCodes }
      type={ props.selectedStage === "payment" ? "receipt" : props.selectedStage || "invoice" }
      orgId={ ownership.current?.id }
      txEditor={ props.editor } 
      userEditor={ props.userEditor }
      orgTemplateEditor={ props.orgTemplateEditor }
      toHumanReadableCurrency={ curr.toHumanReadable } />
  );

  return (
    <div 
      className={ "y" }>
      
      <div
        className={ "x space-lines-frame align-x-center" }>
        <Tabs
          selectedTab={ props.selectedStage }
          onSelect={ (o) => props.onUpdateStage?.(o.id as TransactionStage) }
          options={[
            {
              id: "estimate",
              label: "Estimate",
              condition: hasEstimate
            },
            {
              id: "invoice",
              label: "Invoice",
              canNavigate: isEstimateReady,
              cantNavigateMessage: (
                props.editor?.data.estimateState === "rejected"
                  ? "The Estimate was rejected"
                  : "Finish and send the Estimate first"
              )
            },
            {
              id: "payment",
              label: "Payment",
              canNavigate: props.editor?.state.isInvoiceComplete,
              cantNavigateMessage: (
                props.editor?.data.estimateState === "rejected"
                  ? "The Estimate was rejected"
                  : (
                    props.editor?.data.invoiceState === "rejected"
                      ? "The Invoice was rejected"
                      : "Finish and send the Invoice first"
                  )
              )
            }
          ]} />
      </div>

      <div
        className={ "x align-x-between align-y-center" }>
        
        <div 
          className={ "x space-blocks-children" }>

          <Stat
            className={ css.steps }
            value={(
              <>

                {
                  props.selectedStage === "estimate" && 
                    <TransactionStatusEstimate
                      label={ props.editor?.data.estimateState }
                      state={ props.editor?.data.estimateState } />
                }

                {
                  props.selectedStage === "invoice" && 
                    <TransactionStatusInvoice
                      label={ props.editor?.data.invoiceState }
                      state={ props.editor?.data.invoiceState } />
                }

                {
                  props.selectedStage === "payment" && 
                    <TransactionStatusPayment
                      label={ props.editor?.state.paymentState }
                      state={ props.editor?.state.paymentState } />
                }

              </>
            )}
            label={ "Current Status" } />

          <Stat 
            className={ css.steps }
            dontRender={(
              props.selectedStage === "payment" && !props.editor?.state.isTransactionComplete ||
              props.editor?.type === "expense"
            )}
            label={ props.selectedStage === "payment" ? "receipt" : props.selectedStage }
            value={(
              <div 
                className={ "x space-inlinez-children" }>
                <BlobProvider
                  document={ <Document /> }>
                  {
                    ({ url }) => {
                      return (
                        <>

                          <Button
                            variant={ "inline" }
                            icon={ <IconPreview /> }
                            onClick={ () => setShowPreview(true) } />
                        
                          <Button
                            variant={ "inline" }
                            icon={ <IconDownload /> }
                            onClick={ () => downloadDocument.current?.click() } />
                          
                          {
                            url && 
                              <a 
                                ref={ downloadDocument }
                                download={`docco.pdf`}
                                href={ url }>
                              </a>
                          }

                        </>
                      )
                    } 
                  }
                </BlobProvider>
              </div>   
            )} />

        </div>

        {
          props.selectedStage === "estimate" && 
            <Stat
              label={ !props.editor?.state.isEstimateComplete ? "Next Steps" : "Options" }
              align={ "right" }
              className={ css.steps }
              value={(
                <StageControlsEstimate
                  editor={ props.editor }
                  onUpdateStage={ props.onUpdateStage } />
              )} />
        }

        {
          props.selectedStage === "invoice" && 
            <Stat
              label={ (!props.editor?.state.isInvoiceComplete || !props.editor?.state.isTransactionComplete) ? "Next Steps" : "Options" }
              align={ "right" }
              className={ css.steps }
              value={(
                <StageControlsInvoice
                  editor={ props.editor }
                  onUpdateStage={ props.onUpdateStage } />
              )} />
        }

        {
          props.selectedStage === "payment" && !props.editor?.state.isTransactionComplete && 
            <Stat
              label={ "Next Steps" }
              align={ "right" }
              className={ css.steps }
              value={(
                <StageControlsPayment
                  editor={ props.editor } />
              )} />
        }
        
      </div>

      {
        showPreview && 
          <div 
            className={ cx(
              css.documentPreview,
              "surface-x apply-bg y space-lines-children"
            ) }>
            
            <div 
              className={ "x align-x-between" }>
              
              <Button 
                variant={ "inline" }
                icon={ <IconClose /> }
                iconSize={ 32 }
                onClick={ () => setShowPreview(false) } />
              
              <Button 
                variant={ "inline" }
                icon={ <IconDownload /> }
                iconSize={ 32 }
                onClick={ () => downloadDocument?.current?.click() } />

            </div> 

            <PDFViewer
              width={ "100%" }
              height={ "100%" }
              style={{ border: "none" }}
              showToolbar={ false }>
              <Document />
            </PDFViewer>

          </div>
      }

    </div>
  );
}

export interface ITransactionEditorStateControls {
  editor?: ITransactionEditorAPI
  userEditor?: IEntityEditorAPI
  orgTemplateEditor?: IOrgTemplateEditorAPI
  selectedStage?: TransactionStage
  onUpdateStage?: (s: TransactionStage) => void 
}