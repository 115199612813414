import React from "react";
import { useTaxSuites } from "../../use-tax-suites";
import { TaxCode } from "../../components/tax-code";
import { Button, IconRightAccordian, InputBase, Tooltip } from "@bookie/components";
import { OfferingTaxCode, TaxCodeScope, TransactionType } from "@bookie/glossary";

export const TaxCodeEditor: React.FC<IUseTaxCodeEditor> = (props) => {

  const { suite, filterRelevantTaxCodes } = useTaxSuites();

  const selectedTaxCodes = props.taxCodes?.map(tc => suite?.salesTax.codes?.find(_tc => _tc.id === tc.taxCodeId));
  const relevantTaxCodes = filterRelevantTaxCodes(props.transactionType, props.scope);

  return (
    <div 
      className={ "y surface" }>

      {
        !props.hideToolbar && 
          <div 
            style={{ borderRadius: "0", height: "55px" }}
            className={ "x surface border-b space-blocks-frame-lr align-x-between align-y-center" }>
            
              <div 
                className={ "x align-y-center space-lines-children" }>
                
                  <Button
                    label={ "Save" }
                    size={ "slim" }
                    onClick={ async () => {
                      props.onFinish?.()
                    } } />
                  
                  <Tooltip
                    base={
                      <Button
                        icon={ <IconRightAccordian /> }
                        className={ "hvr-icon-forward" }
                        variant={ "inline" }
                        onClick={ () => props.onFinish?.() } />
                    }
                    message={ "Close and cancel any changes" }
                    position={ "right" } />
                  
              </div>
          </div>
      }

      <div
        className={ "y space-blocks-frame space-lines-children" }>
        
        <InputBase
          label={ "Tax Codes" }
          description={ `You are seeing the relevant Tax Codes that apply to ${ props.scope } ${ props.transactionType } transactions, with the ones that have been selected for you. ` } />

          <div 
            className={ "y space-inlinez-children" }>
            {
              relevantTaxCodes?.map(rtc => {
                return (
                  <TaxCode
                    code={ rtc }
                    isSelected={ selectedTaxCodes?.find(stc => stc?.id === rtc.id)?.id ? true : false }
                    onSelect={ (isSelected) => {
                      if (isSelected) {
                        props.onDeSelectCode?.(rtc.id);
                      } else {
                        props.onSelectCode?.(rtc.id);
                      }
                    } } />
                );
              })
            }
          </div>

      </div>
      
    </div>
  );
}

export interface IUseTaxCodeEditor {
  hideToolbar?: boolean
  transactionType?: TransactionType
  scope?: TaxCodeScope
  taxCodes?: OfferingTaxCode[]
  onFinish?: (taxCodes?: string[]) => void
  onSelectCode?: (codeId: string) => void
  onDeSelectCode?: (codeId: string) => void
}