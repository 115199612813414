import { IconError, IconSuccess, IconWarning, ITableXColumn, Tooltip } from "@bookie/components"
import { OrgExpanded, TransactionExpanded, TransactionExpandedSegment } from "@bookie/glossary"
import { getLocaleFromCountryCode } from "@bookie/module-core";
import { CurrencyAPI } from "@hny_/currency";
import { TimezoneAPI } from "@hny_/timezone";
import cx from "classnames";

export const generateTransactionTable = (
  org: OrgExpanded,
  curr: CurrencyAPI,
  tz: TimezoneAPI
): ITableXColumn[] => [
  {
    header: "Description",
    renderCell: (tx: TransactionExpanded, segment?: TransactionExpandedSegment) => {
      return <p style={{ marginRight: "15px" }}>{ tx.description }</p>
    }
  },
  {
    header: "Audit",
    width: "100px",
    renderCell: (tx: TransactionExpanded, segment?: TransactionExpandedSegment) => {
      return (
        <div 
          className={ "x align-x-between align-y-center space-lines-children" }>
          {
            segment?.issues?.[tx.id] && 
              <Tooltip
                base={ (
                  tx.paymentState === "bad_debt"
                    ? <IconError size={ 20 } />
                    : <IconWarning size={ 20 } />
                ) }
                message={ segment.issues[tx.id] } />
          }
          {
            !segment?.issues?.[tx.id] && 
              <div className={ "surface-positive" }>
                <IconSuccess size={ 20 } />
              </div>
          }
        </div>
      )
    }
  },
  {
    header: "Date",
    renderCell: (tx: TransactionExpanded) => tz.toHumanReadable(tx.invoiceDate || "")
  },
  {
    header: "Expense",
    align: "right",
    renderCell: (tx: TransactionExpanded) => {
      return (
        <span 
          style={{ 
            padding: "2px 4px", 
            fontWeight: "bold",
            fontSize: "14px",
            backgroundColor: "transparent", 
            border: "none",
            color: tx.summary?.total === 0 ? "grey" : "" 
          }}
          className={ cx(
            "surface-negative apply-fg"
          ) }>
          { 
            curr.toHumanReadable(
              (tx.summary?.total || 0), // modified 
              getLocaleFromCountryCode(org?.entity?.countryCode), 
              org.entity?.currencyCode
            ) 
          }
        </span>
      );

    }
  },
  {
    header: "Gross",
    align: "right",
    width: "150px",
    renderCell: (tx: TransactionExpanded) => {
      return (
        <span 
          style={{ 
            padding: "2px 4px", 
            fontWeight: "bold",
            fontSize: "14px",
            backgroundColor: "transparent",
            border: "none",
            color: tx.summary?.localised?.total === 0 ? "grey" : "" 
          }}
          className={ cx(
            "surface-positive apply-fg",
          ) }>
          { curr.toHumanReadable(tx.summary?.localised?.total || 0, getLocaleFromCountryCode(org?.entity?.countryCode), org.entity?.currencyCode) }
        </span>
      );

    }
  }
]