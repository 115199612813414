import React from "react";
import { Route, Routes } from 'react-router-dom';
import css from "./index.module.css";
import cx from "classnames";
import { Menu } from "./menu";
import { useIdentity } from "@bookie/module-identity";
import { summariseTransactionOffering, TransactionDashboard, useBookkeeping } from "@bookie/module-bookkeeping";
import { EntitiesEditor, EntityEditor, OfferingsEditor, TemplateEditor } from "@bookie/module-core";
import { RemoteApp } from "./remote";
import { ProfitAndLoss } from "@bookie/module-reporting";
import { MyTaxSuite, useTaxSuites } from "@bookie/module-tax-suites";
import { TemplateDocument } from "./template/document";

export const App: React.FC = () => {

  const { ownership } = useIdentity();
  const { suite } = useTaxSuites();
  const { transactions } = useBookkeeping();

  const SalesTaxForm = suite?.salesTax.Form || (() => (null));

  return (
    <div
      className={ cx(
        css.app,
        "y fill surface-background"
      ) }>

      <Menu />

      <div
        className={ cx(
          css.app_content,
          "x fill"
        ) }>
        
        <div 
          className={ "x fill-x surface-aside" }>
          <Routes>

            <Route path="/" element={ <TransactionDashboard /> } />

            <Route path="/bookkeeping/income-&-expenses" element={ <TransactionDashboard /> } />
            
            <Route path="/business/profile" element={ ownership.current?.entity?.id ? <EntityEditor entityId={ ownership.current.entity.id } /> : null } />
            <Route path="/business/clients-&-merchants" element={ <EntitiesEditor /> } />
            <Route path="/business/products-&-services" element={ <OfferingsEditor /> } />
            <Route path="/business/document-templates" element={ <TemplateEditor /> } />

            <Route path="/financials/profit-&-loss" element={ <ProfitAndLoss /> } />

            <Route path="/tax/my-suite" element={ <MyTaxSuite /> } />

            <Route path="/tax/sales" element={ 
              <TemplateDocument>
                <SalesTaxForm 
                  transactions={ transactions.all }
                  summariseTransactionOffering={ summariseTransactionOffering } />
              </TemplateDocument> }
            />

            {/*
            <Route path="/tax/:setId" element={ <TaxCalculate /> } />
            <Route path="/accounts" element={ <Accounts /> } />
            <Route path="/tax-suite" element={ <TaxSuite /> } />
            <Route path="/settings" element={ <div>{"settings"}</div> } /> 
            */}

            <Route path="/remote" element={ <RemoteApp /> } /> 
          
          </Routes>
        </div>

      </div>
      
    </div>
  );

}