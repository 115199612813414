import { Status } from "@bookie/components";
import { InvoiceState } from "@bookie/glossary";
import React from "react";

const LABELS = {
  "draft": "Draft",
  "submitted": "Submitted",
  "rejected": "Rejected"
};

export const TransactionStatusInvoice: React.FC<ITransactionStatusInvoice> = (props) => {
  return (
    <Status
      label={ props.state && props.label ? LABELS[props.state] : undefined }
      isApproved={ props.state === "submitted" }
      isRejected={ props.state === "rejected" }
      isActive={ props.state === "draft" }
      isInactive={ !props.state } />
  );
} 

export interface ITransactionStatusInvoice {
  label?: string
  state?: InvoiceState
}