import React from "react";
import { OnboardingCard } from "../_onboarding-card";
import { IOrgEditorAPI } from "../../../use/use-org-editor";
import { InputText } from "@bookie/components";
import { IllustrationName } from "@bookie/images";

export const OnboardingName: React.FC<IOnboardingName> = (props) => {
  return (
    <OnboardingCard
      style={{ position: "relative", height: "400px", width: "800px" }}
      className={ "y" }>

      <div 
        style={{
          position: "absolute",
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          overflow: "hidden",
          zIndex: "1"
        }}>
        <img 
          src={ IllustrationName }
          alt={ "Name" } />
      </div>
    
      <div 
        style={{ paddingLeft: "50px", paddingRight: "80px", zIndex: "2" }}
        className={ "y space-cubes-frame space-cubes-children" }>
        
        {
          props.editor?.related.entityEditor.data.registrationType === "individual" && 
            <>
              
              <h2>{ "What is your name?" }</h2>

              <div
                className={ "y fill-x space-lines-children" }
                style={{
                  position: "absolute",
                  width: "60%", 
                  top: "200px",
                  left: "200px",
                  transform: "rotate(-7deg)"
                }}>

                <InputText
                  style={{
                    "--hny-input-w": "100%",
                    "--hny-input-fg": "#fff",
                    borderBottom: "5px solid white",
                    fontSize: "22px", 
                  } as React.CSSProperties}
                  backgroundType={ "none" }
                  placeholder={ "Bookie" }
                  value={ props.editor?.related.entityEditor.related.contactEditor.data.nameGiven }
                  onChange={ v => {
                    const lastName = props.editor?.related.entityEditor.related.contactEditor.data.nameFamily;
                    props.editor?.related.entityEditor.edit.name(`${ v as string } ${ lastName }`);
                    props.editor?.related.entityEditor.related.contactEditor.edit.nameGiven(v as string);
                  } }
                  error={ props.editor?.related.entityEditor.errors.validation?.name } />

                <InputText
                  style={{
                    "--hny-input-w": "100%",
                    "--hny-input-fg": "#fff",
                    borderBottom: "5px solid white",
                    fontSize: "22px",
                  } as React.CSSProperties}
                  backgroundType={ "none" }
                  placeholder={ "Bookerson" }
                  value={ props.editor?.related.entityEditor.related.contactEditor.data.nameFamily }
                  onChange={ v => {
                    const firstName = props.editor?.related.entityEditor.related.contactEditor.data.nameGiven;
                    props.editor?.related.entityEditor.edit.name(`${ firstName } ${ v as string }`);
                    props.editor?.related.entityEditor.related.contactEditor.edit.nameFamily(v as string);
                  } }
                  error={ props.editor?.related.entityEditor.errors.validation?.name } />

              </div>

            </>
        }

        {
          props.editor?.related.entityEditor.data.registrationType === "business" && 
            <>

              <h2>{ "What is your Business called?" }</h2>

              <InputText
                style={{
                  "--hny-input-w": "60%",
                  "--hny-input-h": "60px",
                  position: "absolute",
                  top: "200px",
                  left: "200px",
                  transform: "rotate(-7deg)",
                  fontSize: "22px",
                  "--hny-input-fg": "#fff",
                  borderBottom: "5px solid white"
                } as React.CSSProperties}
                backgroundType={ "none" }
                placeholder={ "My Very Successful Business LTD" }
                value={ props.editor?.related.entityEditor.data.name }
                onChange={ v => props.editor?.related.entityEditor.edit.name(v as string) }
                error={ props.editor?.related.entityEditor.errors.validation?.name } />

            </>
        }
      
      </div>
      
    </OnboardingCard>
  )
}

export interface IOnboardingName {
  editor?: IOrgEditorAPI
}