import { Button, IconPlus, IconRightAccordian } from "@bookie/components";
import { AddressOrphan } from "@bookie/glossary";
import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const DisplayAddress: React.FC<IDisplayAddress> = (props) => {

  const isDefined = (
    props.address?.line1 ||
    props.address?.line2 ||
    props.address?.town ||
    props.address?.city ||
    props.address?.postalCode
  );

  if (isDefined) {
    return (
      <div
        onClick={ props.onEditAddress }
        className={ cx(
          css.display,
          props.className,
          props.isEditing && !props.isEditingAddress ? "disabled" : ""
        ) }>
  
        {
          (props.address?.line2 || props.address?.line1) &&
            <p>
              { `${ props.address?.line2 ? props.address?.line2 + "," : "" } ${ props.address?.line1 }` }
            </p>
        }
  
        {
          (props.address?.town || props.address?.city) && 
            <p>
              { `${ props.address?.town ? props.address?.town + "," : "" } ${ props.address?.city }` }
            </p>
        }
  
        {
          (props.address?.postalCode || props.address?.country) && 
            <p>
              { `${ props.address?.postalCode ? props.address?.postalCode + "," : "" } ${ props.address?.country }` }
            </p>
        }
  
      </div>
    );
  }

  if (props.isEditingAddress) {
    return (
      <Button
        size={ "slim" }
        variant={ "inline" }
        style={{ padding: "0", height: "auto" }}
        label={ "Close Address" }
        icon={ <IconRightAccordian /> }
        iconSize={ 14 }
        surface={ "surface-button-inline" }
        onClick={ props.onFinishEditingAddress } />
    );
  } else {
    return (
      <Button
        size={ "slim" }
        variant={ "inline" }
        style={{ padding: "0", height: "auto" }}
        label={ "Add Address" }
        className={ props.isEditing && !props.isEditingAddress ? "disabled" : "" }
        icon={ <IconPlus weight={ "regular" } /> }
        iconSize={ 14 }
        surface={ "surface-button-inline" }
        onClick={ props.onEditAddress } />
    );
  }

}

export interface IDisplayAddress {
  className?: string
  address?: AddressOrphan
  isEditing?: boolean
  isEditingAddress?: boolean
  onFinishEditingAddress?: () => void
  onEditAddress?: () => void
}