import { EstimateState, InvoiceState, PaymentState, TransactionStage } from "@bookie/glossary";

export const getTransactionStage = (
  estimateState?: EstimateState,
  invoiceState?: InvoiceState,
  paymentState?: PaymentState
): TransactionStage => {
  
  if (
    !estimateState ||
    estimateState === "draft" ||
    estimateState === "submitted" ||
    estimateState === "rejected"
  ) {
    return "estimate";
  } else {
    return "invoice";
    
    // Trying this out, 
    // issue is when an invoice 
    // goes from draft => submitted,
    // it is automatically switching
    // to payment. 
    
    if (
      invoiceState === "draft" ||
      invoiceState === "rejected"
    ) {
      return "invoice";
    } else {
      return "payment";
    }

  }
}