import { Button, IconPlus } from "@bookie/components";
import React from "react";
import css from "./index.module.css";
import cx from "classnames";
import { IOrgTemplateEditorAPI } from "../../use/use-org-template-editor";
import { TransactionStage } from "@bookie/glossary";

export const DisplayFooter: React.FC<IDisplayFooter> = (props) => {

  const isDefined = (
    (props.transactionStage === "estimate" && props.templates?.data.estimateFooter) ||
    (props.transactionStage === "invoice" && props.templates?.data.invoiceFooter) ||
    (props.transactionStage === "payment" && props.templates?.data.receiptFooter)
  );

  if (isDefined) {
    return (
      <div
        onClick={ () => props.onEditTemplate?.(props.transactionStage) }
        className={ cx(
          css.display,
          props.className
        ) }>
  
        {
          props.transactionStage === "estimate" && 
            <p>{ props.templates?.data.estimateFooter }</p>
        }

        {
          props.transactionStage === "invoice" && 
            <p>{ props.templates?.data.invoiceFooter }</p>
        }

        {
          props.transactionStage === "payment" && 
            <p>{ props.templates?.data.receiptFooter }</p>
        }
  
      </div>
    );
  }

  return (
    <Button
      size={ "slim" }
      variant={ "inline" }
      icon={ <IconPlus weight={ "regular" } /> }
      iconSize={ 14 }
      label={ "Add Footer" }
      surface={ "surface-button-inline" }
      onClick={ () => props.onEditTemplate?.(props.transactionStage) } />
  );

}

export interface IDisplayFooter {
  className?: string
  transactionStage?: TransactionStage
  templates?: IOrgTemplateEditorAPI
  onEditTemplate?: (s?: TransactionStage) => void
}