import React from "react";
import { OnboardingCard } from "../_onboarding-card";
import { IOrgEditorAPI } from "../../../use/use-org-editor";
import { IllustrationBusiness, IllustrationIndividual } from "@bookie/images";
import css from "./index.module.css";
import cx from "classnames";
import { IconSuccessTick } from "@bookie/components";

export const OnboardingRegistrationType: React.FC<IOnboardingRegistrationType> = (props) => {

  const isIndividualSelected = props.editor?.related.entityEditor.data.registrationType === "individual";
  const isBusinessSelected = props.editor?.related.entityEditor.data.registrationType === "business";

  return (
    <OnboardingCard
      style={{ height: "400px", width: "800px" }}
      className={ "x relative" }>
      
      <div 
        className={ cx(
          css.question,
          "x fill-x align-x-center"
        ) }>
        <h2>
          { "What are you registered as?" }
        </h2>
      </div>

      <div 
        style={{ width: "50%", cursor: "pointer" }}
        className={ cx(
          "fill-y border-r hvr-trigger",
          { "surface-alt": !isIndividualSelected },
          { [css.option_isSelected]: isIndividualSelected }
        ) }
        onClick={ () => props.editor?.related.entityEditor.edit.registrationType("individual") }>
        <div 
          style={{ position: "relative" }}
          className={ "y align-x-center align-y-center fill-y" }>
          {
            isIndividualSelected &&
              <div 
                className={ css.option_isSelected_tick }> 
                <IconSuccessTick
                  color={ "#fff" }
                  weight={ "fill" } />
              </div>
          }
          <img 
            src={ IllustrationIndividual }
            alt={ "Individual" }
            className={ "hvr-grow" }
            style={{ height: "120px" }} />
          <p
            className={ css.option_label }>
            { "Individual" }
          </p>
        </div>
      </div>

      <div 
        style={{ width: "50%", cursor: "pointer" }}
        className={ cx(
          "fill-y hvr-trigger",
          { "surface": !isBusinessSelected },
          { [css.option_isSelected]: isBusinessSelected }
        ) }
        onClick={ () => props.editor?.related.entityEditor.edit.registrationType("business") }>
        <div 
          style={{ position: "relative" }}
          className={ "y align-x-center align-y-center fill-y" }>
          {
            isBusinessSelected &&
              <div 
                className={ css.option_isSelected_tick }> 
                <IconSuccessTick
                  color={ "#fff" }
                  weight={ "fill" } />
              </div>
          }
          <img 
            src={ IllustrationBusiness }
            alt={ "Business" }
            className={ "hvr-grow" }
            style={{ height: "120px" }} />
          <p
            className={ css.option_label }>
            { "Business" }
          </p>
        </div>
      </div>

      {/* <InputSelectTile
        options={[
          {
            id: "individual",
            label: "Individual",
            image: IllustrationIndividual
          },
          {
            id: "business",
            label: "Business",
            image: IllustrationBusiness
          }
        ]}
        value={ props.editor?.related.entityEditor.data.registrationType }
        onSelect={ (o) => props.editor?.related.entityEditor.edit.registrationType(o.id as EntityRegistrationType) } /> */}
      
    </OnboardingCard>
  )
}

export interface IOnboardingRegistrationType {
  editor?: IOrgEditorAPI
}