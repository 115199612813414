import React from "react";
import { ITransactionEditorAPI } from "../../../../use/use-transaction-editor";
import { Button, IconApprove, IconReject, IconSend, IconSuccessTick } from "@bookie/components";
import { TransactionStage } from "@bookie/glossary";
import { hydrateTransactionType } from "../../../../fns/hydrate-transaction-type";
import css from "../index.module.css";
import cx from "classnames";

export const StageControlsEstimate: React.FC<IStageControlsEstimate> = (props) => {

  const type = hydrateTransactionType(props.editor?.type);

  const hasAddedEntity = props.editor?.related.entityEditor.data.name;
  const hasAddedLineItem = (props.editor?.related.transactionOfferingsEditor.total || 0) > 0;
  const canSendToEntity = hasAddedEntity && hasAddedLineItem;

  return (
    <div
      className={ "y align-x-end space-inline-children" }>

      {
        props.editor?.data.estimateState === "draft" && 
          <div 
            style={{ gap: "2px" }}
            className={ "y align-x-end" }>

            <div 
              className={ cx(
                "x space-inline-children align-y-center",
                css.steps_actionItem,
                { [css.steps_actionItem_todo]: !hasAddedEntity },
                { [css.steps_actionItem_done]: hasAddedEntity }
              ) }>
              { hasAddedEntity && <IconSuccessTick weight={ "fill" } /> }
              <p>{ `Choose or create the ${ type.entity?.label }` }</p>
            </div>

            <div 
              className={ cx(
                "x space-inline-children align-y-center",
                css.steps_actionItem,
                { [css.steps_actionItem_todo]: !hasAddedLineItem },
                { [css.steps_actionItem_done]: hasAddedLineItem }
              ) }>
              { hasAddedLineItem && <IconSuccessTick weight={ "fill" } /> }
              <p>{ `Add at least one line item` }</p>
            </div>

            {
              !canSendToEntity && 
                <div 
                  className={ cx(
                    "x space-inline-children align-y-center",
                    css.steps_actionItem,
                    css.steps_actionItem_todo
                  ) }>
                  <p>{ `Send the Estimate to your Client` }</p>
                </div> 
            }
            
            {
              canSendToEntity && 
                <div 
                  className={ "y space-inline-frame-t align-x-end space-inline-children" }>
                  <Button
                    size={ "slim" }
                    icon={ <IconSend /> }
                    label={ "Send the Estimate to your Client" }
                    onClick={() => {
                      if (props.editor?.data && props.editor?.type) {

                        props.editor.errors.setDirty();
                        const isValid = props.editor.validate(true);

                        if (props.editor.related.transactionOfferingsEditor.all.length === 0) {
                          alert("You need to add at least one Product or Service first");
                          return;
                        }

                        if (isValid) {
                          props.editor?.edit.estimateState("submitted");
                        }
                      }
                    } } />
                  <Button
                    size={ "slim" }
                    variant={ "inline" }
                    label={ "Or mark as sent" }
                    onClick={ () => {
                      if (props.editor?.data && props.editor?.type) {

                        props.editor.errors.setDirty();
                        const isValid = props.editor.validate(true);

                        if (props.editor.related.transactionOfferingsEditor.all.length === 0) {
                          alert("You need to add at least one Product or Service first");
                          return;
                        }

                        if (isValid) {
                          props.editor?.edit.estimateState("submitted");
                        }
                      }
                    } } />
                </div>
            }
          </div>
        }

      {
        (
          props.editor?.data.estimateState === "submitted"
        ) &&
          <div
            className={"x fill-x space-inline-children"}>

            <Button
              size={"slim"}
              icon={<IconApprove />}
              surface={"surface-primary-positive"}
              label={"Approve"}
              onClick={() => {
                props.editor?.edit.estimateState("approved");
                props.editor?.edit.invoiceState("draft");
                props.onUpdateStage?.("invoice");
              } } />

            <Button
              size={"slim"}
              icon={<IconReject />}
              surface={"surface-primary-negative"}
              label={"Reject"}
              onClick={() => {
                props.editor?.edit.estimateState("rejected");
                props.editor?.edit.invoiceState(undefined);
              } } />

          </div>
      }

      {
        (
          props.editor?.data.estimateState === "submitted" ||
          props.editor?.data.estimateState === "approved" ||
          props.editor?.data.estimateState === "rejected"
        ) &&
          <Button
            size={"slim"}
            label={"Re-mark as draft"}
            variant={"inline"}
            onClick={() => {
              props.editor?.edit.estimateState("draft");
              props.editor?.edit.invoiceState(undefined);
              props.editor?.edit.paymentState(undefined);
            } } />
      }

    </div>
  );
  
}

export interface IStageControlsEstimate {
  editor?: ITransactionEditorAPI
  onUpdateStage?: (s: TransactionStage) => void 
}