import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Link } from '@react-pdf/renderer';
import { ITransactionEditorAPI } from "../../use/use-transaction-editor";
import { getLocaleFromCountryCode, getLogoUrl, IEntityEditorAPI, IOrgTemplateEditorAPI } from "@bookie/module-core";
import { ToHumanReadable } from "@hny_/currency";
import { toCustomFormat } from "@hny_/timezone";
import { OfferingTaxCode, TaxCode, TransactionDocumentType, uuid } from "@bookie/glossary";
import { getDocumentDates } from "./get-document-dates";
import { getDocumentAddressLabel } from "./get-document-address-label";
import { getDocumentFooter } from "./get-document-footer";
import { summariseTransactionOffering } from "../../fns/summarise-transaction-offering";
import { getTaxFromCodes } from "@bookie/module-tax-suites";

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    fontFamily: "Helvetica",
    flexDirection: "column"
  },
  header: {
    flexDirection: 'row',
    justifyContent: "space-between"
  },
  meta: {
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: "space-between"
  },
  logo: {
    height: 40
  },
  rightAligned: {
    textAlign: "right",
    width: "300px"
  },
  highlight: {
    fontSize: 14,
    fontFamily: "Helvetica-Bold"
  },
  address: {
    fontSize: 10
  }
});

export const TransactionDocument: React.FC<ITransactionDocument> = (props) => {

  const userEntity = props.userEditor?.data;
  const userAddress = props.userEditor?.related.addressEditor.data;

  const customerAddress = props.txEditor?.related.entityEditor.related.addressEditor.data;
  const shippingAddress = props.txEditor?.related.shippingAddressEditor.data;

  const dates = getDocumentDates(props.type, props.txEditor?.data);
  const addressLabel = getDocumentAddressLabel(props.type);

  const locale = getLocaleFromCountryCode(userEntity?.countryCode);
  const currency = props.txEditor?.data.currencyCode || "";

  return (
    <Document
      pageMode={ "fullScreen" }>

      <Page 
        size="A4"
        style={ styles.page }
        wrap={ true }>

        {/* User Details */}
        
        <View
          style={ styles.header }>
          <Image 
            style={ styles.logo }
            src={ getLogoUrl(props.orgId || "") } />
          <View>
            
            <Text 
              style={{
                ...styles.rightAligned,
                ...styles.highlight,
                marginBottom: 5
              }}>
              { props.userEditor?.data?.name }
            </Text>

            <Text style={{ ...styles.rightAligned, ...styles.address }}>
              { [userAddress?.line2, userAddress?.line1].filter(Boolean).join(', ') }
            </Text>

            <Text style={{ ...styles.rightAligned, ...styles.address }}>
              { [userAddress?.town, userAddress?.city].filter(Boolean).join(', ') }
            </Text>

            <Text style={{ ...styles.rightAligned, ...styles.address }}>
              { [userAddress?.country, userAddress?.postalCode].filter(Boolean).join(', ') }
            </Text>

            {
              userEntity?.email && 
                <Link style={{ ...styles.rightAligned, ...styles.address }}>
                  { `${ userEntity?.email }` }
                </Link>
            }

            {
              userEntity?.phoneNumber && userEntity?.phoneCountryCode && 
                <Text style={{ ...styles.rightAligned, ...styles.address }}>
                  { `(${ userEntity?.phoneCountryCode}) ${ userEntity?.phoneNumber}` }
                </Text>
            }
            
          </View>
        </View>
          
        <View
          style={{ flexDirection: "column", gap: 20, marginTop: 30,  width: "100%" }}>
          
          <View
            style={{ backgroundColor: "", flexDirection: "row", alignItems: "center", gap: 5, fontSize: 12, borderBottom: "1px solid #efefef", paddingBottom: 10 }}>

            <Text style={{ fontFamily: "Helvetica-Bold", textTransform: "capitalize" }}>
              { props.type }
            </Text>

            {/* <Text style={{ fontSize: 10, fontFamily: "Helvetica-Oblique" }}>
              { "for" }
            </Text>
            
            <Text>{ props.txEditor?.data.description }</Text> */}
          
          </View>

          <View
            style={{ flexDirection: "row", gap: 30 }}>

            <View>
              
              <Text style={{ fontStyle: "italic", fontSize: 7, letterSpacing: "1", marginBottom: 3, fontFamily: "Helvetica-Bold" }}>
                { addressLabel }
              </Text>
              
              <Text style={{ ...styles.address, fontFamily: "Helvetica-Bold", marginBottom: 3 }}>{ `${ props.txEditor?.related.entityEditor.data.name }` }</Text>

              <Text style={ styles.address }>
                { [customerAddress?.line2, customerAddress?.line1].filter(Boolean).join(', ') }
              </Text>

              <Text style={ styles.address }>
                { [customerAddress?.town, customerAddress?.city].filter(Boolean).join(', ') }
              </Text>

              <Text style={ styles.address }>
                { [customerAddress?.country, customerAddress?.postalCode].filter(Boolean).join(', ') }
              </Text>

            </View>

            {
              (
                (props.txEditor?.data.invoiceShippingAddressId || !props.txEditor?.related.shippingAddressEditor.state.isVirgin) && 
                (props.type === "invoice")
              ) &&
                <View>
                
                  <Text style={{ fontStyle: "italic", fontSize: 7, letterSpacing: "1", marginBottom: 3, fontFamily: "Helvetica-Bold" }}>{ "SHIPPING TO" }</Text>
                  
                  <Text style={{ ...styles.address, fontFamily: "Helvetica-Bold", marginBottom: 3 }}>
                    { `${ shippingAddress?.label }` }
                  </Text>

                  <Text style={ styles.address }>
                    { [shippingAddress?.line2, shippingAddress?.line1].filter(Boolean).join(', ') }
                  </Text>

                  <Text style={ styles.address }>
                    { [shippingAddress?.town, shippingAddress?.city].filter(Boolean).join(', ') }
                  </Text>

                  <Text style={ styles.address }>
                    { [shippingAddress?.country, shippingAddress?.postalCode].filter(Boolean).join(', ') }
                  </Text>
                  
                </View>
            }

            {
              dates.map(d => (
                <View>
                  <Text style={{ fontStyle: "italic", fontSize: 7, letterSpacing: "1", marginBottom: 1, fontFamily: "Helvetica-Bold", textTransform: "uppercase" }}>
                    { d.label }
                  </Text>
                  <Text>{ toCustomFormat(d.date || "", "DD MMM YYYY") }</Text>
                </View>
              ))
            }

          </View>

        </View>

        <View
          style={{ flexDirection: "column", gap: 0, marginTop: 50,  width: "100%" }}>
          
          <View
            style={{ flexDirection: "row", backgroundColor: "#efefef", padding: 5, fontSize: 8, fontFamily: "Helvetica-Bold" }}>
            {/* <Text style={{ width: "10%" }}>{ "SKU" }</Text> */}
            <Text style={{ flexGrow: 1 }}>{ "Name" }</Text>
            <Text style={{ width: "10%" }}>{ "QTY" }</Text>
            <Text style={{ width: "10%" }}>{ "Rate/Price" }</Text>
            {/* <Text style={{ width: "10%" }}>{ "Tax" }</Text> */}
            <Text style={{ width: "10%", textAlign: "right" }}>{ "Gross" }</Text>
          </View>

          {
            [
              ...props.txEditor?.related.transactionOfferingsEditor.new.data || [],
              ...props.txEditor?.related.transactionOfferingsEditor.existing.data || []
            ].map(o => {

              const taxCodes = props.getTaxCodes?.(o.taxCodes || []);
              const tax = getTaxFromCodes(taxCodes || []);

              const summary = summariseTransactionOffering(
                o.grossSale,
                o.quantity,
                tax,
                o.taxType
              );

              return (
                <View
                  style={{ flexDirection: "row", borderBottom: "1px solid #efefef", padding: 5 }}>
                  {/* <Text style={{ width: "10%" }}>{ o.sku }</Text> */}
                  <Text style={{ flexGrow: 1 }}>{ o.name }</Text>
                  <Text style={{ width: "10%" }}>{ o.quantity }</Text>
                  <Text style={{ width: "10%" }}>
                    { 
                      props.toHumanReadableCurrency(
                        o.grossSale || 0,
                        locale,
                        currency
                      ) 
                    }
                  </Text>
                  {/* <Text style={{ width: "10%" }}>{ tax + "%"}</Text> */}
                  <Text style={{ width: "10%", textAlign: "right" }}>{
                    props.toHumanReadableCurrency(
                      summary.total,
                      locale,
                      currency
                    )
                  }</Text>
                </View>
              );
            })
          }

        <View
          style={{ flexDirection: "row", borderBottom: "1px solid #efefef", padding: 5, marginTop: 20 }}>
          <Text style={{ width: "10%" }}></Text>
          <Text style={{ flexGrow: 1 }}></Text>
          <Text style={{ width: "10%" }}></Text>
          <Text style={{ width: "20%", textAlign: "right", paddingRight: 20 }}>{ "Subtotal"}</Text>
          <Text style={{ width: "10%", textAlign: "right" }}>{
            props.toHumanReadableCurrency(
              props.txEditor?.summary.subTotal || 0,
              locale,
              currency
            )
          }</Text>
        </View>

        <View
          style={{ flexDirection: "row", borderBottom: "1px solid #efefef", padding: 5 }}>
          <Text style={{ width: "10%" }}></Text>
          <Text style={{ flexGrow: 1 }}></Text>
          <Text style={{ width: "10%" }}></Text>
          <Text style={{ width: "20%", textAlign: "right", paddingRight: 20 }}>{ "Tax"}</Text>
          <Text style={{ width: "10%", textAlign: "right" }}>{
            props.toHumanReadableCurrency(
              props.txEditor?.summary.tax || 0,
              locale,
              currency
            )
          }</Text>
        </View>

        {/* <View
          style={{ flexDirection: "row", borderBottom: "1px solid #efefef", padding: 5, marginTop: 20 }}>
          <Text style={{ width: "10%" }}></Text>
          <Text style={{ flexGrow: 1 }}></Text>
          <Text style={{ width: "10%" }}></Text>
          <Text style={{ width: "20%", textAlign: "right", paddingRight: 20 }}>{ "Total"}</Text>
          <Text style={{ width: "10%", textAlign: "right" }}>{
            props.toHumanReadableCurrency(
              props.txEditor?.summary.localised?.total || 0,
              locale,
              currency
            )
          }</Text>
        </View> */}

          <View
            style={{ flexDirection: "row", borderBottom: "1px solid #efefef", padding: 5, fontFamily: "Helvetica-Bold" }}>
            <Text style={{ width: "10%" }}></Text>
            <Text style={{ flexGrow: 1 }}></Text>
            <Text style={{ width: "10%" }}></Text>
            <Text style={{ width: "20%", textAlign: "right", paddingRight: 20 }}>{ "Balance Due"}</Text>
            <Text style={{ width: "10%", textAlign: "right" }}>{
              props.toHumanReadableCurrency(
                props.txEditor?.summary.localised?.total || 0,
                locale,
                currency
              )
            }</Text>
          </View>

        </View>

        <View
          style={{ flexGrow: 1, flexDirection: "column", justifyContent: "flex-end" }}>
          {
            props.orgTemplateEditor && 
              <Text>{ getDocumentFooter(props.type, props.orgTemplateEditor.data) }</Text>
          }
        </View>

      </Page>
    </Document>
  );
};

export interface ITransactionDocument {
  type: TransactionDocumentType
  orgId?: uuid
  txEditor?: ITransactionEditorAPI
  userEditor?: IEntityEditorAPI
  orgTemplateEditor?: IOrgTemplateEditorAPI
  toHumanReadableCurrency: ToHumanReadable
  getTaxCodes: (taxCodes: OfferingTaxCode[]) => TaxCode[] | undefined
}