import React, { useState } from "react";
import css from "./index.module.css";
import cx from "classnames";

// Utility function to parse a simple markdown-like string
const parseMarkdown = (text: string): React.ReactNode[] => {
  // Simple bold and italic parsing
  const boldPattern = /\*\*(.*?)\*\*/g;
  const italicPattern = /\*(.*?)\*/g;

  // Split by new lines first
  const lines = text.split('\n');

  return lines.flatMap((line, lineIndex) => {
    // Parse bold and italic within each line
    let parts: React.ReactNode[] = line.split(boldPattern).map((part, index) =>
      index % 2 === 1 ? <strong key={index}>{part}</strong> : part
    );

    parts = parts.flatMap(part => {
      if (typeof part === "string") {
        return part.split(italicPattern).map((subPart, index) =>
          index % 2 === 1 ? <em key={index}>{subPart}</em> : subPart
        );
      }
      return part;
    });

    // Add a <br /> element after each line except the last one
    return lineIndex < lines.length - 1 ? [...parts, <br key={`br-${lineIndex}`} />] : parts;
  });
};

export const Tooltip: React.FC<ITooltip> = (props) => {
  const { base, message, position = "top", className } = props;
  const [isHovered, setIsHovered] = useState(false);

  const getTooltipPositionClass = () => {
    switch (position) {
      case 'top':
        return css.tooltip_top;
      case 'bottom':
        return css.tooltip_bottom;
      case 'left':
        return css.tooltip_left;
      case 'right':
        return css.tooltip_right;
      default:
        return "";
    }
  };

  const renderMessage = () => {
    if (typeof message === 'string') {
      // Parse string message to support basic markdown (optional)
      return parseMarkdown(message);
    }

    if (Array.isArray(message)) {
      return message.map((element, index) => <React.Fragment key={index}>{element}</React.Fragment>);
    }

    return message;
  };

  return (
    <div
      className={cx(css.tooltipContainer, className)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {base}

      {isHovered && message && (
        <div style={props.style} className={cx(css.tooltip, getTooltipPositionClass())}>
          {renderMessage()}
        </div>
      )}
    </div>
  );
};

// Update the message prop type to accept string, ReactNode, or an array of ReactNodes
export interface ITooltip {
  base: React.ReactElement;
  message?: string | React.ReactNode | React.ReactNode[];
  position?: "top" | "bottom" | "left" | "right";
  className?: string;
  style?: { [key: string]: string };
}
