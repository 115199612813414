import React from "react";
import css from "./index.module.css";
import cx from "classnames";

export const Status: React.FC<IStatus> = (props) => {
  return (
    <div
      className={ cx(
        css.status,
        { [css.status_pending]: props.isPendingApproval },
        { [css.status_approved]: props.isApproved },
        { [css.status_rejected]: props.isRejected },
        { [css.status_active]: props.isActive },
        { [css.status_inactive]: props.isInactive },
        "x align-y-center"
      ) }>

      <div
        className={ css.status_indicator }>
      </div>

      {
        props.label &&
          <p
            className={ css.status_label }>
            { props.label }
          </p> 
      }

    </div>
  );
}

export interface IStatus {
  label?: string
  isPendingApproval?: boolean
  isApproved?: boolean
  isRejected?: boolean
  isActive?: boolean 
  isInactive?: boolean
}