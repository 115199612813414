import { Logo } from "@bookie/components";
import { DisplayAddress, DisplayContact, getLogoUrl, IEntityEditorAPI } from "@bookie/module-core";
import { useNavigate } from "react-router-dom";
import { useIdentity } from "@bookie/module-identity";
import React from "react";
import { TransactionEntityEditor } from "../../transaction-entity-editor";
import { ITransactionEditorAPI } from "../../../use/use-transaction-editor";
import { ITransactionEditorWorkbenchStates } from "..";

export const TransactionEditorTransactionHeader: React.FC<ITransactionEditorTransactionHeader> = (props) => {

  const { ownership } = useIdentity();
  const navigate = useNavigate();

  return (
    <div 
      className={ "x align-x-between" }>
      
      <div>
        {
          props.editor?.type === "income" && 
            <Logo 
              logoUrl={ ownership.current?.entity?.logoUrl ? getLogoUrl(ownership.current.id) : undefined }
              onAddLogo={ () => navigate("/business/profile") } />
        }
      </div>
      
      {
        props.editor?.type === "income" && 
          <div
            className={ "y align-x-end space-inline-children" }>

            <h2>{ ownership.current?.entity?.name }</h2>
            
            <DisplayAddress
              className={ "y align-x-end" }
              isEditing={ props.isEditing }
              address={ props.userEditor?.related.addressEditor.data }
              isEditingAddress={ props.isEditingAddress }
              onEditAddress={ props.onEditAddress }
              onFinishEditingAddress={ props.onFinishEditingAddress } />
              
            <DisplayContact
              className={ "y align-x-end" }
              isEditing={ props.isEditing }
              entity={ props.userEditor?.data }
              isEditingContact={ props.isEditingContact }
              onFinishEditingContact={ props.onFinishEditingContact }
              onEditContact={ props.onEditContact } />

          </div>
      }

      {
        props.editor?.type === "expense" && 
          <TransactionEntityEditor 
  
            align={ "right" }

            editor={ props.editor }
            
            isEditing={ props.isEditing }

            isEditingEntity={ props.isEditingEntity }
            onEditEntity={ props.onEditEntity }
            onFinishEditingEntity={ props.onFinishEditingEntity }

            onEditEntityAddress={ props.onEditEntityAddress }
            isEditingEntityAddress={ props.isEditingEntityAddress }
            onFinishEditingEntityAddress={ props.onFinishEditingEntityAddress }

            onEditEntityContact={ props.onEditEntityContact }
            isEditingEntityContact={ props.isEditingEntityContact }
            onFinishEditingEntityContact={ props.onFinishEditingEntityContact } />
      }

    </div>
  );
}

export interface ITransactionEditorTransactionHeader extends ITransactionEditorWorkbenchStates {
  editor?: ITransactionEditorAPI
  userEditor?: IEntityEditorAPI
}