import { TransactionOrphan, TransactionType, ObjectValidation, ObjectFieldValidation } from "@bookie/glossary";

export const validateTransaction = 
  (
    transaction: TransactionOrphan,
    transactionType: TransactionType,
    opts?: {
      ignoreEntities?: boolean,
      ignoreDescription?: boolean
    }
  ): ObjectValidation => {
  
  let errors: ObjectFieldValidation = {};
  
  if (
    transactionType === "income" && 
    typeof transaction.sourceId === "undefined" && 
    !opts?.ignoreEntities
  ) {
    errors = {
      ...errors,
      sourceId: "Make sure to specify who is paying you"
    };
  }

  if (
    transactionType === "expense" && 
    typeof transaction.destinationId === "undefined" && 
    !opts?.ignoreEntities
  ) {
    errors = {
      ...errors,
      sourceId: "Make sure to specify who you are paying"
    };
  }

  // if (
  //   (
  //     typeof transaction.description === "undefined" ||
  //     transaction.description === ""
  //   ) && 
  //   !opts?.ignoreDescription
  // ) {
  //   errors = {
  //     ...errors,
  //     description: "You need to describe this transaction"
  //   }
  // }
  
  return {
    isValid: Object.keys(errors).length > 0 ? false : true,
    errors
  };

}