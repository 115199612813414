import React from "react";
import { ITransactionPaymentsEditorAPI } from "../../use/use-transaction-payments-editor";
import { TransactionPaymentEditor } from "../transaction-payment-editor";

export const TransactionPaymentsEditor: React.FC<ITransactionPaymentsEditor> = (props) => {

  const [ selected, setSelected ] = React.useState<number>(-1);

  // Refs to store previous lengths
  const prevNewLength = React.useRef<number>(props.editor?.new.data.length || 0);
  const prevExistingLength = React.useRef<number>(props.editor?.existing.data.length || 0);

  // Effect for handling additions to 'new' offerings
  React.useEffect(() => {
    const currentNewLength = props.editor?.new.data.length || 0;
    if (currentNewLength > prevNewLength.current) {
      // New offering added to 'new' array
      const newIndex = (props.editor?.existing.data.length || 0) + (currentNewLength - 1);
      setSelected(newIndex);
    }
    prevNewLength.current = currentNewLength;
  }, [props.editor?.existing.data.length, props.editor?.new.data]);

  // Effect for handling additions to 'existing' offerings
  React.useEffect(() => {
    const currentExistingLength = props.editor?.existing.data.length || 0;
    if (currentExistingLength > prevExistingLength.current) {
      // New offering added to 'existing' array
      const newIndex = currentExistingLength - 1;
      setSelected(newIndex);
    }
    prevExistingLength.current = currentExistingLength;
  }, [props.editor?.existing.data]);

  return (  
    <div
      className={ "surface y fill-x border-l border-b border-r radius" }>
      
      {
        props.editor?.existing.data.map((payment, i) => (
          <TransactionPaymentEditor
            key={ payment.id }
            payment={ payment }
            isSelected={ selected === i }
            onSelect={ () => setSelected(i) }
            onEdit={ (f, v) => props.editor?.existing.edit(i, f, v) }
            onDelete={ () => props.editor?.existing.remove(payment.id) }
            errors={ props.editor?.existing.errors.validation?.[i] }
            onMinimise={ () => setSelected(-1) } />
        ))
      }

      {
        props.editor?.new.data.map((payment, i) => (
          <TransactionPaymentEditor
            key={ i }
            payment={ payment }
            isSelected={ selected === (i + (props.editor?.existing.data.length || 0)) }
            onSelect={ () => setSelected(i + (props.editor?.existing.data.length || 0)) }
            onEdit={ (f, v) => props.editor?.new.edit(i, f, v) }
            onDelete={ () => props.editor?.new.remove(i) }
            errors={ props.editor?.new.errors.validation?.[i] }
            onMinimise={ () => setSelected(-1) } />
        ))
      }

    </div>
  );
}

export interface ITransactionPaymentsEditor {
  editor?: ITransactionPaymentsEditorAPI
}