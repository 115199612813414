import { TransactionStage } from "@bookie/glossary";
import { DisplayFooter, IOrgTemplateEditorAPI } from "@bookie/module-core";
import React from "react";

export const TransactionEditorTransactionFooter: React.FC<ITransactionEditorTransactionFooter> = (props) => {
  return (
    <div 
      style={{ marginTop: "40px" }}
      className={ "border-t fill-x space-lines-frame-tb" }>
      <DisplayFooter
        templates={ props.templateEditor }
        transactionStage={ props.transactionStage }
        onEditTemplate={ props.onEditFooter } />
    </div>
  );
}

export interface ITransactionEditorTransactionFooter {
  templateEditor?: IOrgTemplateEditorAPI
  transactionStage?: TransactionStage
  onEditFooter?: () => void
}