// import { SendInvoice } from "../../../../components/send-invoice";
import { ITransactionEditorAPI } from "../../../../use/use-transaction-editor";
import React from "react";
import { Button, IconPayment, IconSend, IconSuccessTick } from "@bookie/components";
import { TransactionStage } from "@bookie/glossary";
import { hydrateTransactionType } from "../../../../fns/hydrate-transaction-type";
import css from "../index.module.css";
import cx from "classnames";

export const StageControlsInvoice: React.FC<IStageControlsInvoice> = (props) => {

  const type = hydrateTransactionType(props.editor?.type);

  const hasAddedEntity = props.editor?.related.entityEditor.data.name;
  const hasAddedLineItem = (props.editor?.related.transactionOfferingsEditor.total || 0) > 0;
  const canSendToEntity = hasAddedEntity && hasAddedLineItem;

  return (
    <div
      className={ "y align-x-end space-inline-children" }>

      {
        props.editor?.data.invoiceState === "draft" && 
          <div 
            style={{ gap: "2px" }}
            className={ "y align-x-end" }>

            <div 
              className={ cx(
                "x space-inline-children align-y-center",
                css.steps_actionItem,
                { [css.steps_actionItem_todo]: !hasAddedEntity },
                { [css.steps_actionItem_done]: hasAddedEntity }
              ) }>
              { hasAddedEntity && <IconSuccessTick weight={ "fill" } /> }
              <p>{ `Choose or create the ${ type.entity?.label }` }</p>
            </div>

            <div 
              className={ cx(
                "x space-inline-children align-y-center",
                css.steps_actionItem,
                { [css.steps_actionItem_todo]: !hasAddedLineItem },
                { [css.steps_actionItem_done]: hasAddedLineItem }
              ) }>
              { hasAddedLineItem && <IconSuccessTick weight={ "fill" } /> }
              <p>{ `Add at least one line item` }</p>
            </div>

            {
              !canSendToEntity && 
                <div 
                  className={ cx(
                    "x space-inline-children align-y-center",
                    css.steps_actionItem,
                    css.steps_actionItem_todo
                  ) }>
                  <p>{ `Send the Invoice to your Client` }</p>
                </div> 
            }
            
            {
              canSendToEntity && 
                <div 
                  className={ "y space-inline-frame-t align-x-end space-inline-children" }>
                  <Button
                    size={ "slim" }
                    label={ "Send this Invoice to your Client" }
                    icon={ <IconSend /> }
                    onClick={ () => {
                      if (props.editor?.data && props.editor?.type) {
                        props.editor.errors.setDirty();
                        const isValid = props.editor.validate(true);

                        if (props.editor.related.transactionOfferingsEditor.all.length === 0) {
                          alert("You need to add at least one Product or Service first");
                          return;
                        }

                        if (isValid) {
                          props.editor?.edit.invoiceState("submitted");
                          props.editor?.edit.paymentState("due");
                        }
                      }
                    } } />
                  <Button
                    size={ "slim" }
                    variant={ "inline" }
                    label={ "Or mark as sent" }
                    onClick={ () => {
                      if (props.editor?.data && props.editor?.type) {
                        props.editor.errors.setDirty();
                        const isValid = props.editor.validate(true);

                        if (props.editor.related.transactionOfferingsEditor.all.length === 0) {
                          alert("You need to add at least one Product or Service first");
                          return;
                        }

                        if (isValid) {
                          props.editor?.edit.invoiceState("submitted");
                          props.editor?.edit.paymentState("due");
                        }
                      }
                    } } />
                </div>
            }
          </div>
      }

      {
        (
          props.editor?.data.invoiceState === "submitted" ||
          props.editor?.data.invoiceState === "rejected"
        ) && 
          <>
            
            {
              !props.editor?.state.isTransactionComplete && 
                <Button 
                  label={ "Log a payment" }
                  size={ "slim" }
                  icon={ <IconPayment /> }
                  onClick={ () => props.onUpdateStage?.("payment") } />
            }

            <Button
              size={ "slim" }
              label={ "Re-mark as draft" }
              variant={ "inline" }
              onClick={ () => {
                props.editor?.edit.invoiceState("draft");
                props.editor?.edit.paymentState(undefined);
              } } />
            
            <Button
              size={ "slim" }
              variant={ "inline" }
              label={ "Reject" }
              onClick={ () => {
                props.editor?.edit.invoiceState("rejected");
                props.editor?.edit.paymentState("bad_debt");
              } } />
          
          </>
      }

    </div>
  );
}

export interface IStageControlsInvoice {
  editor?: ITransactionEditorAPI
  onUpdateStage?: (s: TransactionStage) => void 
}