import React from "react";
import cx from "classnames";
import { Button, IconRightAccordian, InputTextArea, Paper, Workbench } from "@bookie/components";
import { TransactionStage, TransactionType, uuid } from "@bookie/glossary";
import { useTransactionEditor } from "../../use/use-transaction-editor";
import { useIdentity } from "@bookie/module-identity";
import { AddressEditor, EntityEditorContact, EntityEditorQuick, useEntityEditor, useOrgTemplateEditor } from "@bookie/module-core";
import { TransactionOfferingsEditor } from "../transaction-offerings-editor";
import { TransactionEditorActionBar } from "./action-bar";
import { TransactionEditorStateControls } from "./state-controls";
import { TransactionEditorTransactionHeader } from "./transaction-header";
import { TransactionEditorTransactionMeta } from "./transaction-meta";
import { TransactionEditorTransactionSummary } from "./transaction-summary";
import { TransactionEditorTransactionFooter } from "./transaction-footer";
import { TaxCodeEditor } from "@bookie/module-tax-suites";
import { TransactionPaymentsEditor } from "../transaction-payments-editor";

export const TransactionEditor: React.FC<ITransactionEditor> = (props) => {

  const { ownership } = useIdentity();

  const editor = useTransactionEditor(
    props.txId,
    undefined,
    undefined,
    {
      transactionType: props.transactionType,
      transactionStage: props.transactionStage
    }
  );

  const userEditor = useEntityEditor(
    ownership.current?.entity?.id,
    undefined,
    undefined,
    { editorType: "expanded" }
  );

  const orgTemplateEditor = useOrgTemplateEditor();

  const [ selectedStage, setSelectedStage ] = React.useState<TransactionStage>(props.transactionStage || "invoice");

  React.useEffect(() => {
    if (props.txId && props.txId !== "create" && editor?.stage) {
      setSelectedStage(editor.stage);
    }
  }, [ editor.stage, props.txId ]);

  const [ editUserAddress, setEditUserAddress ] = React.useState<boolean>(false);
  const [ editUserContact, setEditUserContact ] = React.useState<boolean>(false);
  const [ editEntity, setEditEntity ] = React.useState<boolean>(false);
  const [ editEntityAddress, setEditEntityAddress ] = React.useState<boolean>(false);
  const [ editEntityContact, setEditEntityContact ] = React.useState<boolean>(false);
  const [ editFooter, setEditFooter ] = React.useState<boolean>(false);
  const [ editTaxCodes, setEditTaxCodes ] = React.useState<{ index?: number, type?: "new" | "existing" }>();

  const isEditing = (
    editUserAddress ||
    editUserContact ||
    editEntity ||
    editEntityAddress ||
    editEntityContact ||
    editFooter ||
    typeof editTaxCodes !== "undefined"
  );

  const isEditingTaxCodes = typeof editTaxCodes?.index !== "undefined" && typeof editTaxCodes?.type !== "undefined";

  const workbenchStates = {
    isEditing,
    isEditingAddress: editUserAddress,
    onFinishEditingAddress: () => setEditUserAddress(false),
    onEditAddress: () => setEditUserAddress(true),
    isEditingContact: editUserContact,
    onFinishEditingContact: () => setEditUserContact(false),
    onEditContact: () => setEditUserContact(true),
    isEditingEntity: editEntity,
    onEditEntity: () => setEditEntity(true),
    onFinishEditingEntity: () => setEditEntity(false),
    isEditingEntityAddress: editEntityAddress,
    onEditEntityAddress: () => setEditEntityAddress(true),
    onFinishEditingEntityAddress: () => setEditEntityAddress(false),
    isEditingEntityContact: editEntityContact,
    onEditEntityContact: () => setEditEntityContact(true),
    onFinishEditingEntityContact: () => setEditEntityContact(false)
  };

  return (
    <div
      style={{ minHeight: "100%" }}
      className={ cx(
        "y surface-alt border-l space-cubes-frame-b"
      ) }>
      
      <TransactionEditorActionBar
        editor={ editor }
        onFinish={ props.onFinish } />      

      <div 
        style={{ maxWidth: "900px", alignSelf: "center" }}
        className={ "space-cubes-frame-lr fill-x space-blocks-children y" }>

        <TransactionEditorStateControls
          editor={ editor }
          userEditor={ userEditor }
          orgTemplateEditor={ orgTemplateEditor }
          selectedStage={ selectedStage }
          onUpdateStage={ (s) => setSelectedStage(s) } />
        
        {
          selectedStage === "payment" && 
            <TransactionPaymentsEditor
              editor={ editor.related.transactionPaymentsEditor } />
        }
        
        <Paper
          dontRender={ (selectedStage === "payment" && !editor.state.isTransactionComplete) || (editor.type === "expense" && selectedStage === "payment") }>
          
          {
            editor.type === "expense" && 
              <div
                style={{ marginBottom: "40px" }}
                className={ "x align-x-center" }>
                <p 
                  style={{ padding: "4px 10px", borderRadius: "12px", fontSize: "11px" }}
                  className={ "surface-aside apply-bg border" }>
                  { `This is not an official invoice issued by the Merchant, but a re-creation in Bookie.` }
                </p>
              </div>
          }

          <TransactionEditorTransactionHeader
            editor={ editor }
            userEditor={ userEditor }
            { ...workbenchStates }  />

          <div 
            className={"space-cubes-frame-t space-inlinez-frame-b border-b"}>
            { selectedStage === "estimate" && <h2>{ "Estimate" }</h2> }
            { selectedStage === "invoice" && <h2>{ "Invoice" }</h2> }
            { selectedStage === "payment" && <h2>{ "Receipt" }</h2> }
          </div>

          <TransactionEditorTransactionMeta
            editor={ editor }
            userEditor={ userEditor }
            transactionStage={ selectedStage }
            { ...workbenchStates } />
        
          <div 
            className={ "space-cubes-frame-t" }>
            <TransactionOfferingsEditor
              currencyCode={ editor.data.currencyCode || editor.related.entityEditor.data.currencyCode }
              editor={ editor }
              transactionType={ editor.type }
              onEditTaxCode={ (type: "new" | "existing", index: number) => {
                !isEditing && setEditTaxCodes({ type, index })
              } } />
          </div>

          <TransactionEditorTransactionSummary
            editor={ editor } />

          {
            editor.type === "income" && 
              <TransactionEditorTransactionFooter
                templateEditor={ orgTemplateEditor }
                transactionStage={ selectedStage }
                onEditFooter={ () => setEditFooter(true) } />
          }
          
        </Paper>

      </div>

      <Workbench
        isOpen={ editUserAddress }
        onClose={ () => setEditUserAddress(false) }
        width={ "75vw" }>
        <div 
          className={ "x fill-x align-x-start fill-y" }>
          <div 
            style={{ width: "25vw", height: "100%" }}>
            <AddressEditor
              editor={ userEditor.related.addressEditor }
              allowDelete={ false }
              onFinish={ (a) => {
                
                if (a) {
                  (async () => {
                    await userEditor.commit();
                  })();
                }

                setEditUserAddress(false)
              
              } } />
          </div>
        </div>
      </Workbench>

      <Workbench
        isOpen={ editUserContact }
        onClose={ () => setEditUserContact(false) }
        width={ "75vw" }>
        <div 
          className={ "x fill-x align-x-start fill-y" }>
          <div 
            style={{ width: "25vw", height: "100%" }}>
            <EntityEditorContact
              editor={ userEditor }
              allowDelete={ false }
              onFinish={ () => setEditUserContact(false) } />
          </div>
        </div>
      </Workbench>

      <Workbench
        isOpen={ editEntity }
        onClose={ () => setEditEntity(false) }
        width={ "75vw" }>
        <div 
          className={ "x fill-x align-x-start fill-y" }>
          <div
            style={{ width: "25vw", height: "100%" }}>
            <EntityEditorQuick
              editor={ editor.related.entityEditor }
              onFinish={ () => setEditEntity(false) } />
          </div>
        </div>
      </Workbench>

      <Workbench
        isOpen={ editEntityAddress }
        onClose={ () => setEditEntityAddress(false) }
        width={ "75vw" }>
        <div 
          className={ "x fill-x align-x-start fill-y" }>
          <div 
            style={{ width: "25vw", height: "100%" }}>
            <AddressEditor
              allowCreate={ false }
              allowDelete={ false }
              allowUpdate={ false }
              editor={ editor.related.entityEditor.related.addressEditor }
              onFinish={ () => setEditEntityAddress(false) } />
          </div>
        </div>
      </Workbench>

      <Workbench
        isOpen={ editEntityContact }
        onClose={ () => setEditEntityContact(false) }
        width={ "75vw" }>
        <div 
          className={ "x fill-x align-x-start fill-y" }>
          <div 
            style={{ width: "25vw", height: "100%" }}>
            <EntityEditorContact
              allowCreate={ false }
              allowDelete={ false }
              allowUpdate={ false }
              editor={ editor.related.entityEditor }
              onFinish={ () => setEditEntityContact(false) } />
          </div>
        </div>
      </Workbench>

      <Workbench
        isOpen={ editFooter }
        onClose={ () => setEditFooter(false) }
        width={ "75vw" }>
        <div 
          className={ "y fill-x align-x-start fill-y" }>
          <div 
            style={{ width: "25vw", height: "100%" }}
            className={ "fill-inputs y" }>
              
              <div 
                style={{ height: "55px" }}
                className={ "x space-lines-frame-lr border-b surface fill-x align-y-center" }>
                <Button
                  icon={ <IconRightAccordian /> }
                  variant={ "inline" }
                  className={ "hvr-icon-forward" }
                  onClick={ () => setEditFooter(false) } />              
              </div>

              <div 
                className={ "space-lines-frame" }>
              
                {
                  selectedStage === "estimate" && 
                    <InputTextArea
                      label={ "Estimate Footer" }
                      backgroundType={ "fill" }
                      value={ orgTemplateEditor.data.estimateFooter }
                      onChange={ v => orgTemplateEditor.edit.estimateFooter(v as string) } />
                }

                {
                  selectedStage === "invoice" && 
                    <InputTextArea
                      backgroundType={ "fill" }
                      label={ "Invoice Footer" }
                      value={ orgTemplateEditor.data.invoiceFooter }
                      onChange={ v => orgTemplateEditor.edit.invoiceFooter(v as string) } />
                }

                {
                  selectedStage === "payment" && 
                    <InputTextArea
                      label={ "Receipt Footer" }
                      backgroundType={ "fill" }
                      value={ orgTemplateEditor.data.receiptFooter }
                      onChange={ v => orgTemplateEditor.edit.receiptFooter(v as string) } />
                }

              </div>

          </div>
        </div>
      </Workbench>

      <Workbench 
        isOpen={ isEditingTaxCodes }
        onClose={ () => setEditTaxCodes(undefined) }
        width={ "75vw" }>
          <div 
          className={ "x fill-x align-x-start fill-y" }>
            <div 
              style={{ width: "25vw", height: "100%" }}>
                {
                  (typeof editTaxCodes?.index !== "undefined" && typeof editTaxCodes?.type !== "undefined") &&
                    <TaxCodeEditor
                      taxCodes={ editor.related.transactionOfferingsEditor[editTaxCodes.type].data[editTaxCodes.index]?.taxCodes }
                      transactionType={ editor.type }
                      scope={ editor.scope }
                      onFinish={ () => setEditTaxCodes(undefined) }
                      onSelectCode={ (codeId: string) => {
                        if (editTaxCodes.type && typeof editTaxCodes.index !== "undefined") {
                          const taxCodes = editor.related.transactionOfferingsEditor[editTaxCodes.type].data[editTaxCodes.index].taxCodes || []
                          editor.related.transactionOfferingsEditor[editTaxCodes.type].edit(editTaxCodes.index, "taxCodes", [
                            ...taxCodes,
                            { taxCodeId: codeId, by: "user" }
                          ]);
                        }
                      } }
                      onDeSelectCode={ (codeId: string) => {
                        if (editTaxCodes.type && typeof editTaxCodes.index !== "undefined") {
                          const taxCodes = editor.related.transactionOfferingsEditor[editTaxCodes.type].data[editTaxCodes.index].taxCodes || []
                          editor.related.transactionOfferingsEditor[editTaxCodes.type].edit(editTaxCodes.index, "taxCodes", [
                            ...taxCodes.filter(tc => tc.taxCodeId !== codeId),
                          ]);
                        }
                      } } />
                }
            </div>
          </div>
      </Workbench>

    </div>
  )
}

export type ITransactionEditor = ITransactionEditorConfig & ITransactionEditorLifecycle

export interface ITransactionEditorLifecycle {
  onEdit?: (
    id: uuid | "create", 
    stage?: TransactionStage,
    useAssist?: boolean
  ) => void
  onAnother?: () => void
  onFinish?: () => void
  onFinishAssist?: () => void
  onUseAssist?: () => void
  onCompleteStep?: () => void
}

export interface ITransactionEditorConfig {
  transactionType?: TransactionType
  transactionStage?: TransactionStage
  txId?: uuid | "create"
  useAssist?: boolean
}

export interface ITransactionEditorWorkbenchStates {

  isEditing?: boolean

  isEditingAddress?: boolean 
  onFinishEditingAddress?: () => void
  onEditAddress?: () => void
  
  isEditingContact?: boolean
  onFinishEditingContact?: () => void
  onEditContact?: () => void

  isEditingEntity?: boolean
  onFinishEditingEntity?: () => void
  onEditEntity?: () => void
  
  isEditingEntityAddress?: boolean 
  onFinishEditingEntityAddress?: () => void
  onEditEntityAddress?: () => void

  isEditingEntityContact?: boolean
  onFinishEditingEntityContact?: () => void
  onEditEntityContact?: () => void  

}