import { TaxCode, TaxCodeScope, TransactionType } from "@bookie/glossary";

export const filterRelevantTaxCodes = (
  taxCodes: TaxCode[],
  filters: {
    transactionType?: TransactionType,
    scope?: TaxCodeScope
  }
): TaxCode[] => {
  return (
    taxCodes
      ?.filter(c => filters.transactionType ? c.type === filters.transactionType || c.type === "unspecified" : true)
      ?.filter(c => filters.scope ? c.scope === filters.scope : true)
  );
}