import React from "react";
import { OnboardingCard } from "../_onboarding-card";
import { IOrgEditorAPI } from "../../../use/use-org-editor";
import { InputSelect, InputTextArea } from "@bookie/components";
import { TaxSuiteDataCollection } from "@bookie/glossary";
import css from "./index.module.css";
import cx from "classnames";
import { IllustrationCollection } from "@bookie/images";

export const OnboardingCollection: React.FC<IOnboardingCollection> = (props) => {
  return (
    <OnboardingCard
      className={ cx(
        css.onboardingCard,
        "x"
      ) }>
      
      <div 
        className={ cx(
          css.collection_illustration,
          "x align-y-center align-x-center surface-alt"
        ) }>
        <img 
          src={ IllustrationCollection }
          alt={ "Collection" } />
      </div>
    
      <div 
        className={ cx(
          css.collection_form,
          "y space-cubes-frame space-cubes-children align-x-center"
        ) }>
        
        {
          props.collectionRequests?.map(c => {
            
            if (c.propertyOptions && c.propertyOptions?.length > 0) {
              return (
                <InputSelect
                  style={{
                    alignSelf: "flex-start" 
                  } as React.CSSProperties}
                  label={ c.question }
                  description={ c.questionDescription }
                  formatLabel={ (o) => o.value }
                  matchKey={ "id" }
                  options={ c.propertyOptions }
                  value={ c.propertyOptions?.find(o => o.id === props.editor?.related.entityEditor.data.collectionOnboarding?.[c.propertyName]) }
                  onSelect={ (v) => props.editor?.related.entityEditor.edit.collectionOnboarding({ [c.propertyName]: v.id as string }) } />
              )
            } else {
              return (
                <InputTextArea
                  style={{
                    "--hny-input-w": "100%" 
                  } as React.CSSProperties}
                  label={ c.question }
                  description={ c.questionDescription }
                  backgroundType={ "fill" }
                  value={ props.editor?.related.entityEditor.data.collectionOnboarding?.[c.propertyName] }
                  onChange={ (v) => props.editor?.related.entityEditor.edit.collectionOnboarding({ [c.propertyName]: v as string }) } /> 
              )
            }

          })
        }
      
      </div>
      
    </OnboardingCard>
  )
}

export interface IOnboardingCollection {
  editor?: IOrgEditorAPI
  collectionRequests: TaxSuiteDataCollection[]
}