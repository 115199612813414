import React from "react";
import css from "./index.module.css"
import cx from "classnames";
import { Tooltip } from "../tooltip";
import { IconLogo } from "../icon";

export const Logo: React.FC<ILogo> = (props) => {
  return (
    <>

      {
        props.logoUrl &&
          <img 
            className={ css.logo_logo }
            src={ props.logoUrl }
            alt={ "Company Logo" } /> 
      }

      {
        !props.logoUrl &&
          <div
            onClick={ (e) => {
              e.stopPropagation();
              props.onAddLogo?.();
            } }
            className={ cx(
              css.logo_noLogo,
              "x align-x-center align-y-center surface-primary"
            ) }>
              <Tooltip
                message={ "Upload your own logo" }
                position={ "right" }
                base={ <IconLogo size={ 32 } /> } />
          </div>  
      }
    
    </>
  );
}

export interface ILogo {
  logoUrl?: string
  onAddLogo?: () => void
}