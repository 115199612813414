import { uuid } from "@bookie/glossary";
import { InputText, InputBase, InputNumber, Button, IconRightAccordian, Tooltip } from "@bookie/components";
import React from "react";
import cx from "classnames";
import { IEntityEditorAPI, useEntityEditor } from "../../use/use-entity-editor";
import { SelectCountryPhone } from "../../components/select-country-phone";

export const EntityEditorContact: React.FC<IEntityEditorContact> = (props) => {

  const allowCreate = typeof props.allowCreate === "boolean" ? props.allowCreate : true;
  const allowUpdate = typeof props.allowUpdate === "boolean" ? props.allowUpdate : true;
  const allowDelete = typeof props.allowDelete === "boolean" ? props.allowDelete : true;

  const editor = useEntityEditor(
    props.entityId,
    props.editor
  );

  const alreadyExists = editor.data.id && editor.data.id !== "create";

  return (
    <div 
      className={ "y fill" }>
        
      {
        !props.renderBare && 
          <div 
            style={{ borderRadius: "0", height: "55px" }}
            className={ "x surface border-b space-lines-frame-lr align-x-between align-y-center" }>
              <div 
                className={ "x align-y-center space-lines-children" }>
                  
                  {
                    (!alreadyExists && allowCreate) && 
                      <Button
                        label={ "Create" }
                        size={ "slim" }
                        onClick={ async () => {
                          const result = await editor.commit();
                          result && props.onFinish?.();
                        } } />
                  }

                  {
                    (alreadyExists && allowUpdate) && 
                      <Button
                        label={ "Update" }
                        size={ "slim" }
                        onClick={ async () => {
                          const result = await editor.commit();
                          result && props.onFinish?.();
                        } } />
                  }
                  
                  <Tooltip
                    base={
                      <Button
                        icon={ <IconRightAccordian /> }
                        className={ "hvr-icon-forward" }
                        variant={ "inline" }
                        onClick={ () => props.onFinish?.() } />
                    }
                    message={ "Close and cancel any changes" }
                    position={ "right" } />
                  
              </div>
              
              {
                (allowDelete && alreadyExists) && 
                  <Button
                    label={ "Delete" }
                    size={ "slim" }
                    variant={ "dangerous" }
                    surface={ "surface-negative" }
                    isDangerous={ true }
                    onClick={ async () => {
                      const result = await editor.delete();
                      result && props.onFinish?.();
                    } } /> 
              }  

          </div>
      }
    
      <div
        className={ cx(
          "surface y fill-x space-lines-children",
          { "space-lines-frame": !props.renderBare }
        ) }>

          <InputText
            type={ "text" }
            label={ "Email" }
            value={ editor.data.email }
            className={ "fill-inputs" }
            onChange={ v => editor.edit.email(v as string) }
            error={ editor.errors.validation?.["email"] } />
          
          <div 
            className={ "y" }>
            
            <InputBase
              type={ "text" }
              label={ "Phone Number" } />
          
            <div 
              className={ "x align-y-baseline space-inline-children" }>
              
              <SelectCountryPhone
                selectedPhoneCountryCode={ editor.data.phoneCountryCode }
                onSelect={ (c) => editor.edit.phoneCountryCode(c as string) }
                error={ editor.errors.validation?.phoneCountryCode } />
              
              <InputNumber
                type={ "number" }
                value={ editor.data.phoneNumber }
                placeholder={ "Your phone number" }
                onChange={ v => editor.edit.phoneNumber(v as number)}
                error={ editor.errors.validation?.phoneNumber } />

            </div>
        
          </div>
      
      </div>

    </div>
  );
}

export interface IEntityEditorContact {
  entityId?: uuid | "create"
  editor?: IEntityEditorAPI
  renderBare?: boolean
  allowCreate?: boolean
  allowUpdate?: boolean
  allowDelete?: boolean
  onFinish?: () => void
}