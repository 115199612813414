import React from "react";

export const TemplateDocument: React.FC<ITemplateDocument> = (props) => {
  return (
    <div 
      style={{ height: "fit-content" }}
      className={ "surface-alt apply-bg x align-x-center space-blocks-frame fill-x" }>
      { props.children }
    </div>
  );
}

export interface ITemplateDocument {
  children?: React.ReactNode
}