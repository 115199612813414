import React from "react";
import { EntityRole, ErrorAPI, ObjectFieldValidation, OfferingOrphan, TransactionOffering, TransactionOfferingOrphan, TransactionType, uuid } from "@bookie/glossary";
import { useIdentity } from "@bookie/module-identity";
import { useValidator } from "@bookie/utils";
import { validateTransactionOfferings } from "../fns/validate-transaction-offerings";
import { useBookkeepingAPI } from "../use-bookkeeping-api";
import { useNotification } from "@bookie/components";
import { useCore, useCoreAPI } from "@bookie/module-core";
import { InferTaxCodes, useInferTaxCodes } from "@bookie/module-tax-suites";

export const useTransactionOfferingsEditor = (
  offerings?: TransactionOffering[],
  _newOfferings?: TransactionOfferingOrphan[],
  config?: {
    targetCountry?: string,
    targetRole?: EntityRole,
    transactionType?: TransactionType
    currencyCode?: string
  }
): ITransactionOfferingsEditorAPI => {

  const n = useNotification();
  const api = useBookkeepingAPI();
  const coreApi = useCoreAPI();
  const core = useCore();
  const { ownership } = useIdentity();

  const [ existingOfferings, setExistingOfferings ] = React.useState<TransactionOffering[]>([]);
  const [ newOfferings, setNewOfferings ] = React.useState<TransactionOfferingOrphan[]>([]);
  const [ offeringsToDelete, setOfferingsToDelete ] = React.useState<TransactionOffering[]>([]);

  const [ inventory, setInventory ] = React.useState<number[]>([]);

  const [ isNewReady, setIsNewReady ] = React.useState<boolean>(false);
  const [ isExistingReady, setIsExistingReady ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (offerings && offerings.length > 0) {
      setExistingOfferings(offerings);
    }
    setIsExistingReady(true);
  }, [ offerings ]);

  React.useEffect(() => {
    if (_newOfferings && _newOfferings.length > 0) {
      setNewOfferings(_newOfferings.map(no => ({
        ...no,
        type: "physical_product"
      })));
    }
    setIsNewReady(true);
  }, [ _newOfferings, ownership ]);

  const editNew = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (index: number, field: keyof TransactionOffering, value: any) => {
      setNewOfferings((prevOfferings) => {
        const newOfferings = [...prevOfferings];
        newOfferings[index] = {
          ...newOfferings[index],
          [field]: value,
        };
        return newOfferings;
      });
    },
    []
  );

  const editExisting = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (index: number, field: keyof TransactionOffering, value: any) => {
      setExistingOfferings((prevOfferings) => {
        const newOfferings = [...prevOfferings];
        newOfferings[index] = {
          ...newOfferings[index],
          [field]: value,
        };
        return newOfferings;
      });
    },
    []
  );

  const add = React.useCallback(
    (o?: OfferingOrphan) => {
      setNewOfferings((prevOfferings) => {
        
        // TODO 
        // We need to figure out a way to ensure 
        // that ownership object is always defined
        // from the context provider. 
        // You can't really do anything without one anyway
        // and it will greatly simply the logic 
        // of everything else. 

        if (ownership.current?.entity?.id) { 
          return (
            [
              ...prevOfferings,
              {
                name: o?.name || "",
                description: o?.description || "",
                sourceOfferingId: o?.id || undefined,
                sku: o?.sku || "",
                type: o?.type || "physical_product",
                quantity: 1,
                grossCost: o?.grossCost || 0,
                grossSale: o?.grossSale || 0,
                taxType: o?.taxType || "inclusive",
                taxCodes: o?.taxCodes,
                rateInterval: o?.rateInterval || "hourly"
              }
            ]
          )
        } else {
          return prevOfferings
        }
      });
    },
    [ownership]
  );

  const removeNew = React.useCallback(
    (index: number) => {
      setNewOfferings((prevOfferings) => prevOfferings.filter((_, i) => i !== index));
    },
    []
  );

  const removeExisting = React.useCallback(
    (id: uuid) => {
      const _to = existingOfferings.find(eo => eo.id === id);
      setExistingOfferings((prevOfferings) => prevOfferings.filter(po => po.id !== _to?.id));
      _to && setOfferingsToDelete(prevOfferings => ([ ...prevOfferings, _to ]));
    },
    [existingOfferings]
  );

  const addToInventory = React.useCallback(
    (index: number) => {
      setInventory(prev => [ ...prev, index ]);
    },
    []
  );

  const removeFromInventory = React.useCallback(
    (index: number) => {
      setInventory(prev => prev.filter(i => i !== index));
    },
    []
  );

  const {
    isInferring,
    hasAttemptedToInfer,
    inferTaxCodes
  } = useInferTaxCodes(
    config?.targetCountry,
    config?.currencyCode,
    config?.targetRole,
    config?.transactionType,
    { 
      new: newOfferings,
      existing: existingOfferings 
    },
    (i, codes) => editNew(i, "taxCodes", codes),
    (i, codes) => editExisting(i, "taxCodes", codes)
  );

  // Re-validate the forms 
  // for both the new Transaction Offerings
  // as well as the existing ones. 

  const {
    isVirgin: isNewVirgin,
    validate: validateNew,
    setDirty: setDirtyNew,
    validationErrorsArray: validationNew,
    setValidationErrorsArray: setValidationNew
  } = useValidator(
    (_to) => {
      const _validation = validateTransactionOfferings(_to as TransactionOfferingOrphan[]);
      setValidationNew(_validation.errors);
      return _validation.isValid;
    },
    newOfferings,
    undefined,
    isNewReady
  );

  const {
    isVirgin: isExistingVirgin,
    validate: validateExisting,
    setDirty: setDirtyExisting,
    validationErrorsArray: validationExisting,
    setValidationErrorsArray: setValidationExisting
  } = useValidator(
    (_to) => {
      const _validation = validateTransactionOfferings(_to as TransactionOfferingOrphan[]);
      setValidationExisting(_validation.errors);
      return _validation.isValid;
    },
    existingOfferings,
    undefined,
    isExistingReady
  );

  const validate = () => {
    const areNewValid = validateNew();
    const areExistingValid = validateExisting();
    return areNewValid && areExistingValid;
  }

  return {

    state: {
      isInferringTaxCodes: isInferring,
      hasAttemptedToInfer
    },   

    all: [
      ...newOfferings,
      ...existingOfferings
    ],

    new: {
      data: newOfferings,
      add,
      edit: editNew,
      remove: removeNew,
      errors: {
        setDirty: setDirtyNew,
        validation: validationNew,
        setValidation: setValidationNew 
      },
      clear: () => setNewOfferings([])
    },

    existing: {
      data: existingOfferings,
      edit: editExisting,
      remove: removeExisting,
      errors: {
        setDirty: setDirtyExisting,
        validation: validationExisting,
        setValidation: setValidationExisting 
      },
      clear: () => setExistingOfferings([])
    },

    inventory: {
      inventory,
      addToInventory,
      removeFromInventory
    },

    delete: {
      data: offeringsToDelete
    },

    validate,

    commit: async (transactionId) => {

      setDirtyNew();
      setDirtyExisting();

      const canCommit = validate();
      if (!canCommit) return false;

      let _offerings: TransactionOffering[] | false = false;

      try {
        if (newOfferings && newOfferings.length > 0 && !isNewVirgin) {

          const _new = newOfferings;

          if (inventory.length > 0) {
            for (const idx of inventory) {
              const _offering = newOfferings[idx];
              const { offering } = await coreApi.createOffering(
                { 
                  ..._offering, 
                  quantity: undefined,
                  currencyCode: config?.currencyCode 
                } as OfferingOrphan
              );
              core.offerings.api?.sync(offering);
              _new[idx] = { ..._offering, sourceOfferingId: offering.id };
            }
          }
          
          const { transactionOfferings: created } = await api.createTransactionOfferings(transactionId, _new);
          if (Array.isArray(_offerings)) {
            _offerings.push(...created);
          } else {
            _offerings = created;
          }
          setNewOfferings([]);
        }
      } catch (e) {
        if (e instanceof ErrorAPI){
          n.notify({ type: "error", message: e.error });
        }
      }

      try {
        if (existingOfferings && existingOfferings.length > 0 && !isExistingVirgin) {
          const { transactionOfferings: updated } = await api.updateTransactionOfferings(existingOfferings);
          if (Array.isArray(_offerings)) {
            _offerings.push(...updated);
          } else {
            _offerings = updated;
          }
          setExistingOfferings([]);
        }
      } catch (e) {
        if (e instanceof ErrorAPI){
          n.notify({ type: "error", message: e.error });
        }
      }

      try {
        if (offeringsToDelete && offeringsToDelete.length > 0) {
          await api.deleteTransactionOfferings(offeringsToDelete);
          if (!_offerings) {
            _offerings = [];
          }
          setOfferingsToDelete([]);
        }
      } catch (e) {
        if (e instanceof ErrorAPI){
          n.notify({ type: "error", message: e.error });
        }
      }

      return _offerings;

    },

    total: (
      (newOfferings.length || 0) + 
      (existingOfferings.length || 0)
    ),

    inferTaxCodes

  };
};

export default useTransactionOfferingsEditor;

export interface ITransactionOfferingsEditorAPI {
  all: TransactionOfferingOrphan[],
  new: {
    data: TransactionOfferingOrphan[]
    add: (offering?: OfferingOrphan) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    edit: (index: number, field: keyof TransactionOffering, value: any) => void
    remove: (i: number) => void
    errors: {
      setDirty: () => void
      validation?: ObjectFieldValidation[]
      setValidation: (v: ObjectFieldValidation[]) => void
    },
    clear: () => void
  },
  existing: {
    data: TransactionOffering[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    edit: (index: number, field: keyof TransactionOffering, value: any) => void
    remove: (id: uuid) => void
    errors: {
      setDirty: () => void
      validation?: ObjectFieldValidation[]
      setValidation: (v: ObjectFieldValidation[]) => void
    },
    clear: () => void
  },
  delete: {
    data: TransactionOffering[]
  },
  state: {
    isInferringTaxCodes: boolean
    hasAttemptedToInfer: boolean
  },
  inventory: ITransactionOfferingInventory,
  validate: () => boolean,
  commit: (transactionId: uuid) => Promise<TransactionOffering[] | false>,
  total: number,
  inferTaxCodes: InferTaxCodes
}

export interface ITransactionOfferingInventory {
  inventory: number[],
  addToInventory: (i: number) => void,
  removeFromInventory: (i: number) => void
}

export interface ITransactionOfferingsEditorState {}