import React from "react";
import { IconService, InputSelect } from "@bookie/components";
import { RateInterval, RATE_INTERVALS } from "@bookie/glossary";

export const SelectOfferingRateInterval: React.FC<ISelectOfferingRateInterval> = (props) => {
  return (
    <InputSelect
      style={ props.style }
      className={ "fill-x" }
      icon={ <IconService /> }
      name={ "rate-interval" }
      description={ props.description || "Choose how often the rate applies" }
      label={ props.label }
      type={ "text" }
      size={ props.size }
      options={ RATE_INTERVALS }
      value={ RATE_INTERVALS.find(ri => ri.id === props.selectedOfferingRateInterval) }
      actions={ props.actions }
      formatLabel={ (ri) => ri.label }
      matchKey={ "id" }
      onSelect={ (ri) => props.onSelect?.(ri.id) }
      error={ props.error }
      placeholder={ "Set an interval" } />
  );
}

export interface ISelectOfferingRateInterval {
  label?: string
  description?: string
  selectedOfferingRateInterval?: RateInterval
  onSelect?: (interval: RateInterval) => void
  actions?: { label: string, onClick: () => void }[]
  size?: "small"
  error?: string
  style: { [key: string]: string }
}