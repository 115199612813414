import { TaxType } from "@bookie/glossary";

export const summariseTransactionOffering = (
  
  grossSale: number = 0,
  quantity: number = 0,
  taxPercent: number = 0,
  taxType: TaxType = "inclusive",

  // If an exchange rate is given to us,
  // then we want the localised value 
  // in the summary. If none is defined, 
  // then it will be handled a layer 
  // up at the summariseTransaction fn. 
  exchangeRate: number = 1

  // costSale?: number // Optional parameter for cost of sale
): SummarisedTransactionOffering => {

  const _exchangeRate = typeof exchangeRate !== "number" ? 1 : exchangeRate;

  // Calculate the total sale (before tax adjustments)
  const totalSale = grossSale * quantity;

  let subTotal = totalSale; // Initial subTotal is the total sale
  let totalTax = 0;

  if (taxType === "inclusive") {
    // For inclusive tax, the grossSale already includes tax.
    subTotal = totalSale / (1 + taxPercent / 100); // Remove tax portion from totalSale
    totalTax = totalSale - subTotal; // Tax is the difference between total and subTotal
  } else if (taxType === "additive") {
    // For additive tax, calculate tax based on totalSale
    totalTax = (totalSale * taxPercent) / 100;
  }

  // Calculate the net value if costSale is provided
  // const net = costSale !== undefined ? total - costSale : undefined;

  subTotal *= _exchangeRate;
  totalTax *= _exchangeRate;

  return {
    subTotal,
    tax: totalTax,
    total: subTotal + totalTax,
    // net, // Include the net value
  };
};

export interface SummarisedTransactionOffering {
  subTotal: number;
  tax: number;
  total: number;
  // net?: number; // Optional net value
}
