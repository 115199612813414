import React from "react";
import ReactDOM from "react-dom";
import { Notification as NotificationComponent } from ".";

const NotificationCtx = React.createContext<INotificationAPI | undefined>(undefined);

export const NotificationCtxProvider: React.FC<{
  children?: React.ReactNode;
  variants: NotificationVariant[];
}> = (props) => {

  const [notifications, setNotifications] = React.useState<Notification[]>([
    // {
    //   id: Date.now(),
    //   hasTimer: false,
    //   message: "Your transaction was successfully updated",
    //   type: "success"
    // },
    // {
    //   id: Date.now(),
    //   hasTimer: false,
    //   message: "Your transaction was successfully updated",
    //   type: "error"
    // }
  ]);

  const notify = React.useCallback((notification: Omit<Notification, "id">) => {
    const newNotification: Notification = {
      ...notification,
      id: Date.now(),
      duration: notification.duration ?? 7000, // Default to 7 seconds
      hasTimer: notification.hasTimer ?? true, // Default to true
    };
    setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
  }, []); 

  const dismiss = React.useCallback((id: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  }, []);

  const contextValue = React.useMemo(
    () => ({
      notifications,
      notify,
      dismiss,
      variants: props.variants || []
    }),
    [notifications, props.variants, notify, dismiss]
  );

  return (
    <NotificationCtx.Provider
      value={ contextValue }
    >
      {
        ReactDOM.createPortal(
          notifications.length > 0 && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                padding: "25px 100px 100px 25px",
                zIndex: 999999999999,
                background: "linear-gradient(157deg, rgb(0 0 0 / 42%) 0%, rgba(0, 0, 0, 0) 54%)"
              }}
              className={"y space-inline-children"}
            >
              {notifications.map((n) => (
                <NotificationComponent {...n} dismiss={dismiss} key={n.id} />
              ))}
            </div>
          ),
          document.getElementById("root-notifications") || document.body
        )
      }

      { props.children }

    </NotificationCtx.Provider>
  );
};

export interface Notification {
  id: number;
  message: string;
  type: "success" | "error" | "info";
  duration?: number; // Duration in milliseconds
  hasTimer?: boolean; // Whether the notification auto-dismisses
}

export const useNotification = () => {
  const context = React.useContext(NotificationCtx);
  if (!context) {
    throw new Error("useNotification must be used within a NotificationCtxProvider");
  }
  return context;
};

export interface INotificationAPI {
  notifications: Notification[];
  notify: (n: Omit<Notification, "id">) => void;
  dismiss: (id: number) => void;
  variants: NotificationVariant[];
}

export interface NotificationVariant {
  type: string;
  class: string;
  icon?: React.ReactElement;
}
