import { ErrorAPI, Payment, TransactionExpanded } from "@bookie/glossary";
import { objectToCamel } from "@bookie/utils";
import { SupabaseClient } from "@supabase/supabase-js";

export const _getTransactionsExpanded = (sb: SupabaseClient): GetTransactionsExpanded => 
  async () => {

    // TODO 
    // we might need to remove the entity fetching 
    // here for efficiency. 
    // We already have this information because 
    // we pre-fetch all entities.

    const { data, error } = (
      await sb  
        .from("transaction")
        .select(`
          *,
          source_entity:entity!transaction_source_id_fkey(*),
          destination_entity:entity!transaction_destination_id_fkey(*),
          offerings:transaction_offerings!transaction_offerings_transaction_id_fkey(*),
          payments:transaction_payments (
            payment:payment_id(*)
          )
        `)
    );

    if (error) {
      throw new ErrorAPI({});
    }

    if (data === null) {
      throw new ErrorAPI({});
    }


    const _transactions = data.map(
      tx => {
        tx.payments = tx.payments.map((tp: { payment: Payment }) => tp.payment);    
        return tx;
      }
    );

    return {
      transactions: objectToCamel<TransactionExpanded[]>(_transactions)
    }

  }

export type GetTransactionsExpanded = () => Promise<GetTransactionsExpandedResponse>

export interface GetTransactionsExpandedResponse {
  transactions: TransactionExpanded[]
}